import rtlPlugin from 'stylis-plugin-rtl'
import createCache from '@emotion/cache'
import { prefixer } from 'stylis'

export const emotionCacheRtl = createCache({
  key: 'bgi-rtl',
  prepend: true,
  stylisPlugins: [prefixer, rtlPlugin],
  
})

export const emotionCacheLtr = createCache({
  key: 'bgi-ltr',
  prepend: true,
})
