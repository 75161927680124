const MAX_SAFE_INTEGER = 9007199254740991;

let lastLocalId = 0;
let uniqueGenerationStr = 'l';

const genId = (): string => {
	if (lastLocalId === MAX_SAFE_INTEGER) {
		lastLocalId = 0;
		uniqueGenerationStr += 'l';
	}

	lastLocalId++;

	return uniqueGenerationStr + lastLocalId;
};

export default genId;



// let lastId = 0

// const genId = () => String(++lastId)
// export default genId

