import { Button, Container } from '@mui/material'
import { Link } from 'react-router-dom'
import PageHeader from '../../UI/PageHeader'
import CollectionsGrid from './CollectionsGrid'
import { useGetCollectionsQuery } from '../../store/collectionsApi'
import { useAppSelector } from '../../store/hooks'
import { TopLinearProgress } from '../TopLinearProgress'
import Layout from '../page/Layout'
import { useTranslation } from 'react-i18next'



export const CollectionsListPage = () => {
  const userMe = useAppSelector((state) => state.auth.userMe)
  const { isFetching, data: collections, error } = useGetCollectionsQuery()
  const { t } = useTranslation(['Collections', 'common'])

  if (isFetching || !collections || error) {
    return <TopLinearProgress />
  }

  return (
<Layout>
<Container maxWidth="md">
      <Button component={Link} to={'/'}>{t('Back_to_the_home_page')}</Button>
      <div style={{ width: '100%', height: 30 }} />

      <PageHeader
        style={{ marginBottom: 24 }}
        title={t('Collections')}
        actions={
          userMe?.role === 'admin' ? (
            <Button component={Link} variant="contained" to={`/data-entry/collections/create`}>
              {t('Create_collection')}
            </Button>
          ) : undefined
        }
      />

      <CollectionsGrid
        collections={collections}
        to={(collectionId) => `/data-entry/collections/${collectionId}/update`}
      />

      <div style={{ width: '100%', height: 30 }} />
    </Container>

    <div  style={{height: '10rem'}}/>
</Layout>
    )
}
