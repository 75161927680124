import { FC, FormEventHandler } from 'react'
import { Button, Paper, IconButton } from '@mui/material'
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { Add as AddIcon } from '@mui/icons-material'
import { axiosInstance } from '../../../utils/axiosInstance'
import { Unit } from '../../../types/unit'
import { useFetch } from '../../../hooks/useFetch'
import { ChapterUnit } from '../../../types/chapterUnit'
import { BgiCheckbox } from '../../../UI/BgiCheckbox'
import FieldValue from '../../FieldValue'
import { BgiLink } from '../../../UI/BgiLink'
import { UnitsSearch } from '../../UnitsSearch'
import { css } from '@emotion/css'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'
import { useSnackbar } from 'notistack'
import PinIcon from '@mui/icons-material/PushPin'
import PinOutlinedIcon from '@mui/icons-material/PushPinOutlined'
import { Slide } from '../../../types/slide'
import TextWithMath from '../../TextWithMath'

// I add this to try give the Auth
// import { useAppSelector } from '../../../store/hooks'
// import { Course } from '../../../types/course'
// import { useGetCoursesQuery } from '../../../store/coursesApi'
// import { useParams } from 'react-router-dom'


// TODO: fix
type SelectOption = any

interface ChapterUnitsFormProps {
  chapterId: string
}

interface ChapterUnitFormData {
  unit: Unit
  slides: (Slide & {
    isIncluded: boolean
  })[]
  isPinned: boolean
  actionMetadata: {
    isUpdating: boolean
    isRemoving: boolean
  }
}

interface ChapterUnitsForm {
  chapterUnits: ChapterUnitFormData[]
}

const DISPLAY_TYPES = {
  image: 'صورة',
  'youtube-video': 'فيديو يوتيوب',
  'question-input': 'سؤال حقل',
  'question-checkboxes': 'سؤال خيارات',
  'question-cards': 'سؤال خيارات',
  'question-multi-inputs': 'سؤال حقول',
  'only-text': 'شريحة نص مع صورة',
  'flashcard': 'flashcard'
}

const getErrMsg = buildGetErrMsg({
  CHAPTER_UNIT_EXISTS: 'The unit is already added to the chapter',
})

const formatChapterUnit = (chapterUnit: ChapterUnit) => {
  const { slides, ...unit } = chapterUnit.unit

  const chapterUnitData: ChapterUnitFormData = {
    unit,
    actionMetadata: {
      isUpdating: false,
      isRemoving: false,
    },
    isPinned: chapterUnit.isPinned,
    slides: [],
  }

  slides.forEach((slide) => {
    chapterUnitData.slides.push({
      ...slide,
      isIncluded: !chapterUnit.excludedSlidesIds.includes(slide.id),
    })
  })

  return chapterUnitData
}

// ---comp----------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

export const ChapterUnitsForm: FC<ChapterUnitsFormProps> = ({ chapterId }) => {
  
  // I add this to try give the Auth
  // const userMe = useAppSelector((state) => state.auth.userMe)

  // const params = useParams()
  // const subcollectionId = params.subcollectionId as string
  // const { data: courses } = useGetCoursesQuery(subcollectionId)

  // const courseNames = courses?.map(course => {
  //   const courseId = course.id; // فرض أن لدينا خاصية name
  //   return courseId; // إرجاع اسم الدورة كنص
  // });
  



  const chapterUnitsForm = useForm<ChapterUnitsForm>()
  const addChapterUnitForm = useForm<{ unit?: Unit }>()
  const { control, setValue, getValues } = chapterUnitsForm
  const {
    fields: chapterUnits,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'chapterUnits',
  })

  const [loading] = useFetch<ChapterUnit[]>(
    `/chapters/${chapterId}/units`,
    (data) => {
      if (data) {
        setValue('chapterUnits', data.map(formatChapterUnit))
      }
    }
  )

  const { enqueueSnackbar } = useSnackbar()

  const snackErr = (e: any) => {
    const errMsg = getErrMsg(
      e.response
        ? { status: e.response.status, data: e.response.data }
        : new Error('Unknown')
    )

    enqueueSnackbar({ message: errMsg, variant: 'error' })
  }

  const toggleIsPinned = (chapterUnitIdx: number) => {
    const prevIsPinned = getValues().chapterUnits[chapterUnitIdx].isPinned
    setValue(`chapterUnits.${chapterUnitIdx}.isPinned`, !prevIsPinned)
  }

  const handleAdd: FormEventHandler<HTMLFormElement> =
    addChapterUnitForm.handleSubmit(async ({ unit }) => {
      if (!unit) return alert('chose unit')

      try {
        const res = await axiosInstance.post(`/chapters/${chapterId}/units`, {
          unitId: unit.id,
          excludedSlidesIds: [],
        })

        append(formatChapterUnit(res.data))

        enqueueSnackbar({
          message: 'The unit has been added to chapter successfully',
          variant: 'success',
        })
      } catch (e) {
        snackErr(e)
      }
    })

  const handleUpdate = async (unitIdx: number) => {
    setValue(`chapterUnits.${unitIdx}.actionMetadata.isUpdating`, true)

    const chapterUnit = getValues().chapterUnits[unitIdx]
    const { id: unitId } = chapterUnit.unit

    const excludedSlidesIds: string[] = []

    chapterUnit.slides.forEach((slide) => {
      if (!slide.isIncluded) excludedSlidesIds.push(slide.id)
    })

    try {
      await axiosInstance.patch(`/chapters/${chapterId}/units/${unitId}`, {
        excludedSlidesIds,
        isPinned: chapterUnit.isPinned,
      })

      enqueueSnackbar({
        message: "The chapter's unit has been updated successfully",
        variant: 'success',
      })
    } catch (e) {
      snackErr(e)
    } finally {
      setValue(`chapterUnits.${unitIdx}.actionMetadata.isUpdating`, false)
    }
  }

  const handleRemove = async (unitIdx: number) => {
    const confirmationMsg =
      'Are you sure you want to remove this unit from this chapter'

    if (!window.confirm(confirmationMsg)) return

    setValue(`chapterUnits.${unitIdx}.actionMetadata.isRemoving`, true)

    const chapterUnit = getValues().chapterUnits[unitIdx]
    const { id: unitId } = chapterUnit.unit

    try {
      await axiosInstance.delete(`/chapters/${chapterId}/units/${unitId}`)
      remove(unitIdx)

      enqueueSnackbar({
        message: 'The unit has been removed from chapter successfully',
        variant: 'success',
      })
    } catch (e) {
      snackErr(e)
      setValue(`chapterUnits.${unitIdx}.actionMetadata.isRemoving`, false)
    }
  }

  const getSlideDisplayTitle = (slide: Slide) => {
    if (slide.type === 'banner-ad') return ''

    return (
      (slide.slideNum ? `#${slide.slideNum} - ` : '') +
      `${DISPLAY_TYPES[slide.type]}: ` +
      (('question' in slide ? slide.question : slide.title) || 'بلا عنوان')
    )
  }

  // ----------------------------------------------------------------------
  // --return--------------------------------------------------------------

  return (
    <div>
      {loading && <p>Loading...</p>}

      <FormProvider {...chapterUnitsForm}>
        {chapterUnits.map((chapterUnit, chapterUnitIdx) => (
          <Paper
            elevation={5}
            className={styles.chapterUnit}
            key={chapterUnit.id}
            style={{ backgroundColor: '#f8f8f8' }}
          >
            <div className={styles.heading}>
              <div>
                <span className={styles.unitNum}>
                  unit Number {chapterUnit.unit.unitNum}
                </span>

                {/* {(userMe?.role === 'admin' || (userMe?.role === 'worker' && userMe.privateCoursesIds.includes(courseId))) && ( */}
                  <h3>
                    <BgiLink
                      to={`/data-entry/units/${chapterUnit.unit.id}/slides`}
                    >
                      {chapterUnit.unit.detailedTitle}  ( {chapterUnit.unit.id} )
                    </BgiLink>
                  </h3>

                {/* )} */}

              </div>

              <div>
                <FieldValue name={`chapterUnits.${chapterUnitIdx}.isPinned`}>
                  {(isPinned: boolean) => (
                    <IconButton
                      color={isPinned ? 'primary' : 'default'}
                      onClick={() => toggleIsPinned(chapterUnitIdx)}
                    >
                      {isPinned ? <PinIcon /> : <PinOutlinedIcon />}
                    </IconButton>
                  )}
                </FieldValue>
              </div>
            </div>

            {chapterUnit.slides.map((slide, slideIdx) => (
              <BgiCheckbox
                key={slideIdx}
                name={`chapterUnits.${chapterUnitIdx}.slides.${slideIdx}.isIncluded`}
                label={
                  <BgiLink to={`/data-entry/slides/${slide.id}/update`}>
                    <TextWithMath value={getSlideDisplayTitle(slide)} />
                  </BgiLink>
                }
              />
            ))}

            {/* // TODO: explain */}
            <FieldValue name={`chapterUnits.${chapterUnitIdx}.actionMetadata`}>
              {(actionMetadata: ChapterUnitFormData['actionMetadata']) => (
                <>
                  {(actionMetadata.isUpdating || actionMetadata.isRemoving) && (
                    <div className={styles.chapterUnitCover} />
                  )}

                  <div className={styles.chapterUnitActions}>
                    <Button
                      variant="contained"
                      disabled={actionMetadata.isUpdating}
                      onClick={() => handleUpdate(chapterUnitIdx)}
                    >
                      Update
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      disabled={actionMetadata.isRemoving}
                      onClick={() => handleRemove(chapterUnitIdx)}
                    >
                      Remove
                    </Button>
                  </div>
                </>
              )}
            </FieldValue>
          </Paper>
        ))}
      </FormProvider>

      <form style={{ marginBottom: 24 }} onSubmit={handleAdd}>
        <FormProvider {...addChapterUnitForm}>
          <UnitsSearch name="unit" />
        </FormProvider>

        <Button type="submit" startIcon={<AddIcon />} variant="outlined">
          Add Unit
        </Button>
      </form>
    </div>
  )
}

const styles = {
  chapterUnit: css({
    position: 'relative',
    padding: 16,
    marginBottom: 32,
  }),
  chapterUnitCover: css({
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'transparent',
    zIndex: 10,
    top: 0,
    left: 0,
    cursor: 'not-allowed',
  }),
  chapterUnitActions: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  unitNum: css({
    fontSize: 12,
  }),
  heading: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
}
