/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const UseDynamicLanguageStyle = (): { dynamicLanguageStyle: SerializedStyles }  => {
  const currentLanguage = useSelector((state: RootState) => state.language.direction);

  const dynamicLanguageStyle = css`
    direction: ${currentLanguage};
  `;

  return { dynamicLanguageStyle };
}

export default UseDynamicLanguageStyle;

// Amjad
// /** @jsxImportSource @emotion/react */
// import { css, SerializedStyles } from '@emotion/react';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../store';

// const UseDynamicLanguageStyle = (): { dynamicLanguageStyle: SerializedStyles }  => {
//   const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);

//   const dynamicLanguageStyle = css`
//     direction: ${currentLanguage === 'ar' ? 'rtl' : 'ltr'};
//   `;

//   return { dynamicLanguageStyle };
// }

// export default UseDynamicLanguageStyle;


