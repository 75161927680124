/** @jsxImportSource @emotion/react */
import { FC, Dispatch, SetStateAction, FormEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import { FeedbackFv } from '../../types/feedback'
import { BgiTextField } from '../../UI/BgiTextField'
import { Button } from '@mui/material'
import { useCreateFeedbackMutation } from '../../store/feedbacksApi'
import { buildGetErrMsg } from '../../utils/buildGetErrMsg'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'


interface SlideFeedbackFormProps {
  slideId: string
  isSending: boolean
  setIsSending: Dispatch<SetStateAction<boolean>>
  onClose: () => void
}

const getErrMsg = buildGetErrMsg()

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const SlideFeedbackForm: FC<SlideFeedbackFormProps> = ({ slideId, isSending, setIsSending, onClose }) => {
  const [createFeedback] = useCreateFeedbackMutation()

  const { t } = useTranslation(['Feedback', 'common'])


  const { enqueueSnackbar } = useSnackbar()

  const feedbackForm = useFormContext<FeedbackFv>()

  const onSend: FormEventHandler = async (e) => {
    e.preventDefault()

    const payload = {
      ...feedbackForm.getValues(),
      slideId,
    }
    setIsSending(true)

    const result = await createFeedback(payload)

    if ('data' in result) {
      feedbackForm.reset()
      enqueueSnackbar({ message: t('Sent_successfully'), variant: 'success' })
      onClose()
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }

    setIsSending(false)
  }

  return (
    <form onSubmit={onSend} data-name="form fuad">
      <h4 style={{ marginBottom: 16 }}>{t('Report/suggestions')}</h4>

      <BgiTextField
        style={{ marginBottom: 24 }}
        label={t('Title')}
        name="title"
        fullWidth
        size="small"
        required
        maxLength={255}
        labelDirection='right'
      />

      <BgiTextField
        style={{ marginBottom: 24 }}
        label={t('Message_text')}
        name="message"
        fullWidth
        size="small"
        multiline
        minRows={4}
        maxRows={6}
        labelDirection='right'
      />

      <div>
        <Button
          style={{ margin: 24 }}
          variant="contained"
          type="submit"
          disabled={isSending}
        >
          {t('Send')}
        </Button>

        <Button
          variant="outlined"
          color="error"
          onClick={onClose}
          disabled={isSending}
        >
          {t('Cancel')}
        </Button>
      </div>
    </form>
  )
}

export default SlideFeedbackForm
