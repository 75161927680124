import { ReactNode } from 'react'
import { ResErr, parseResErr } from './parseResErr'

export type GetErrMsg = (error?: ResErr) => string | ReactNode | undefined

export type BuildGetErrMsg = (
  messages?: Record<string, string | ReactNode>,
  defaultMsg?: string
) => GetErrMsg

const BASE_ERROR_MESSAGES: Record<string, string> = {
  UNAUTHORIZED: 'يرجى تسجيل الدخول',
  FORBIDDEN: 'ليس لديك صلاحية الوصول',
  UNPROCESSABLE_ENTITY: 'أحد الحقول لم يتم إدخال بياناته بطريقة صحيحة',
}

const DEFAULT_MESSAGE = 'حدث خطأ ما'

export const buildGetErrMsg: BuildGetErrMsg =
  (messages = {}, defaultMsg) =>
  (error) => {
    const errCode = parseResErr(error)

    if (!errCode) return undefined

    return (
      { ...BASE_ERROR_MESSAGES, ...messages }[errCode] ||
      defaultMsg ||
      DEFAULT_MESSAGE
    )
  }
