/**
 * 
 * The maximum is exclusive and the minimum is inclusive
 */
const randomInt = (min: number, max: number): number => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}

export default randomInt
