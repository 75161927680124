import { useEffect, useState } from 'react';
import { axiosInstance } from '../utils/axiosInstance';

export const useFetch = <T extends any>(
  endpoint: string,
  onSuccess?: (data: T | null) => void
): [boolean, T | null, string | null] => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await axiosInstance(endpoint);
        if (onSuccess) onSuccess(res.data)
        setData(res.data);
         // TODO: fix handle 400 | 500 error
      } catch (e: any) {
        // TODO: fix error setter
        setError(e.message);
      } finally {
        setLoading(false)
      }
    })();
  }, []);


  return [loading, data, error];
};
