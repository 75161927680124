/** @jsxImportSource @emotion/react */

import { useEffect } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { ExamFv, ExamI } from '../../../types/exam'
import { BgiTextField } from '../../../UI/BgiTextField'
import FieldValue from '../../FieldValue'
// import { css } from '@emotion/css'
import { UnitsSearch } from '../../UnitsSearch'
import { CoursesAdd } from '../../CoursesAdd'
import { useSnackbar } from 'notistack'
import {
  useCreateExamMutation,
  useDeleteExamMutation,
  useUpdateExamMutation,
} from '../../../store/examsApi'
import { BgiLink } from '../../../UI/BgiLink'
import { FC, useMemo } from 'react'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'
import { useNavigate } from 'react-router-dom'
import { Button, Stack } from '@mui/material'
import { useLazyGetSlidesPointsQuery } from '../../../store/slidesApi'
import BgiRadioGroup from '../../../UI/BgiRadioGroup'
import { BgiCheckbox } from '../../../UI/BgiCheckbox'
import { useTranslation } from 'react-i18next'
import Layout from '../../page/Layout'
import UseDynamicLanguageStyle from '../../i18n/UseDynamicLanguageStyle'
import { css } from '@emotion/react'



interface ExamFormProps {
  isStandard?: true
  token?: string
  sendResult?: boolean
  startDate?: string
  startHour?: string
  endDate?: string
  endHour?: string

}

// ---Comp-------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------

export const ExamForm: FC<ExamFormProps> = ({ isStandard, token }) => {
  const { control, handleSubmit, reset, getValues } = useFormContext<ExamFv>()

  const coursesField = useFieldArray({
    control,
    name: 'courses',
    keyName: 'localId',
  })

  const { t } = useTranslation(['Exams', 'common'])
  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()



  const [
    fetchSlidesPoints,
    {
      isFetching: isSlidesPointsFetching,
      data: slidesPoints,
      isError: isSlidesPointsError,
    },
  ] = useLazyGetSlidesPointsQuery()

  useEffect(() => {
    fetchSlidesPoints(coursesField.fields.map((c) => c.id))
  }, [coursesField.fields])

  const defaultValues = useMemo(() => getValues(), [])

  const { enqueueSnackbar } = useSnackbar()

  const questionTypesField = useFieldArray({
    control,
    name: 'questionTypes',
    keyName: 'localId',
  })

  const examId = useWatch({ control, name: 'id' })

  const [createExam, { isLoading: isCreating }] = useCreateExamMutation()

  const [updateExam, { isLoading: isUpdating }] = useUpdateExamMutation()

  const [deleteExam, { isLoading: isDeleting }] = useDeleteExamMutation()

  const navigate = useNavigate()


  // ---Message--------------------------------------------------------
  // -----------------------------------------------------------
  const SUCCESS_MSGS = {
    create: (examId: string) => (
      <p>
        {t('Exam_has_been_created_successfully_with')}{' '}
        <BgiLink color="#fff" to={`/data-entry/exams/${examId}/update`}>
          #{examId}
        </BgiLink>
      </p>
    ),
    update: t('Exam_has_been_updated_successfully'),
  }

  const getErrMsg = buildGetErrMsg({
    DUP_EXAM_CODE: t('Exam_title_is_duplicated_input_another_title'),
  })
  // -----------------------------------------------------------
  // -----------------------------------------------------------

  const onSubmit = handleSubmit(async ({ courses, unit, ...restValues }) => {
    if (!isStandard && courses.length === 0 && !unit) {
      return enqueueSnackbar({
        message: 'You must input at least one course or unit',
        variant: 'error',
      })
    }

    const examPayload: ExamI = {
      ...restValues,
      coursesIds: courses.map((course) => course.id),
      unitId: unit?.id ?? null,
      questionTypes: restValues.questionTypes.map((questionType) => {
        const countNum = Number(questionType.count)

        return {
          ...questionType,
          count: Number.isNaN(countNum) ? 0 : countNum,
        }
      }),
    }

    if (!examId) {
      const result = await createExam(examPayload)
      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['create'](result.data.id),
          variant: 'success',
        })
        reset({ ...defaultValues })
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    } else {
      const result = await updateExam({ id: examId, ...examPayload })

      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['update'],
          variant: 'success',
        })
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    }
  })

  const onDelete = async () => {
    if (!examId) return

    const confirmationMsg = t('Are_you_sure_you_want_to_delete_this_exam')

    if (!window.confirm(confirmationMsg)) return

    const result = await deleteExam(examId)

    if ('data' in result) {
      enqueueSnackbar({
        message: t('Exam_has_been_deleted_successfully'),
        variant: 'success',
      })
      navigate(`/data-entry/exams`)
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  }

  const countQuestions = (questionTypes: ExamFv['questionTypes']) =>
    questionTypes.reduce((acc, { count }) => {
      const countNum = Number(count)
      return acc + (Number.isNaN(countNum) ? 0 : countNum)
    }, 0)

  // ---return----------------------------------------------------------------
  // -------------------------------------------------------------------------
  return (
    <div css={dynamicLanguageStyle}>
      <Layout>
        <h2>{examId ? t('common:Update') : t('common:Create')} {t('Exam')}</h2>

        {examId && (
          <div style={{ marginTop: 8 }}>
            (
            {isStandard ? (
              t('Standard')
            ) : (
              <BgiLink to={`/exams/${token}`}>open</BgiLink>
            )}
            )
          </div>
        )}

        <form onSubmit={onSubmit}>
          <Stack style={{ marginTop: 24 }} spacing={3}>
            <BgiTextField
              label={t('TItle')}
              name="title"
              fullWidth
              size="small"
              required
            />

            {!isStandard && (
              <>
                <CoursesAdd coursesField={coursesField} />

                <div>
                  <h6 css={styles.subtitle}>{t('Must_slides_from_unit')}</h6>
                  <UnitsSearch name="unit" />
                </div>
              </>
            )}

            <div>
              <h6 css={styles.subtitle}>{t('common:Questions')}</h6>

              <div css={styles.questionsGrid} style={{ marginBottom: 16 }}>
                <span>{t('Point')}</span>
                <span>{t('Count')}</span>
                <span>{t('Percentage')}</span>
              </div>

              {questionTypesField.fields.map((questionType, idx) => (
                <div
                  key={questionType.localId}
                  css={styles.questionsGrid}
                  style={{ marginBottom: 8 }}
                >
                  <span style={{ padding: '8px 0' }}>
                    <FieldValue name={`questionTypes.${idx}.point`}>
                      {(point) => (
                        <>
                          {point}

                          {!isSlidesPointsFetching &&
                            !isSlidesPointsError &&
                            slidesPoints
                            ? ` (${slidesPoints[point] ?? 0})`
                            : ' (...)'}
                        </>
                      )}
                    </FieldValue>
                  </span>

                  <BgiTextField
                    name={`questionTypes.${idx}.count`}
                    fullWidth
                    size="small"
                    inputRule="natural-number"
                  />

                  <span style={{ padding: '8px 0' }}>
                    <FieldValue name={`questionTypes`}>
                      {(questionTypes: ExamFv['questionTypes']) => {
                        const countNum = Number(questionTypes[idx].count)
                        const questionsCount = countQuestions(questionTypes)

                        const percentage =
                          questionsCount === 0
                            ? 0
                            : ((Number.isNaN(countNum) ? 0 : countNum) /
                              questionsCount) *
                            100

                        return percentage.toFixed(2) + '%'
                      }}
                    </FieldValue>
                  </span>
                </div>
              ))}
            </div>

            <div>
              {t('Total_questions')}:{' '}
              <FieldValue name="questionTypes">{countQuestions}</FieldValue>
              <FieldValue name="unit.slidesCount">
                {(slidesCount: number | undefined) =>
                  typeof slidesCount === 'number' ? ` + ${slidesCount}` : ''
                }
              </FieldValue>
            </div>

            <div>

              <Stack spacing={3}>
                <BgiCheckbox name="sendResult" label={t('Active_send_result')} />
                <div css={styles.sendResult}>

                  <BgiTextField
                    label={t('Start_date')}
                    name="startDate"
                    fullWidth
                    size='small'
                    placeholder='yyyy-mm-dd'
                    pattern={/^(\d{4})-(\d{2})-(\d{2})$/}
                  />
                  <BgiTextField
                    label={t('Start_hour')}
                    name="startHour"
                    fullWidth
                    size='small'
                    placeholder='hh:mm'
                    pattern={/^([01]?[0-9]|2[0-3]):([0-5]?[0-9])$/}

                  />
                  <BgiTextField
                    label={t('End_date')}
                    name="endDate"
                    fullWidth
                    size='small'
                    placeholder='yyyy-mm-dd'
                    pattern={/^(\d{4})-(\d{2})-(\d{2})$/}
                  />
                  <BgiTextField
                    label={t('End_hour')}
                    name="endHour"
                    fullWidth
                    size='small'
                    placeholder='hh:mm'
                    pattern={/^([01]?[0-9]|2[0-3]):([0-5]?[0-9])$/}
                  />
                </div>
              </Stack>

            </div>


            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                disabled={isCreating || isUpdating}
              >
                {examId ? 'Update' : 'Create'}
              </Button>

              {examId && !isStandard && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={onDelete}
                  disabled={isDeleting}
                >
                  Delete
                </Button>
              )}
            </div>
          </Stack>
        </form>
      </Layout>
    </div>
  )
}

const styles = {
  subtitle: css({
    fontWeight: 700,
    fontSize: 14,
    margin: '8px 0',
  }),
  questionsGrid: css({
    display: 'grid',
    gridTemplateColumns: '80px minmax(0, 1fr) 96px',
    alignItems: 'start',
    gap: 32,
  }),
  sendResult: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    width: '50%',
  })
}
