import { FC, useState, ChangeEventHandler, KeyboardEventHandler } from 'react'
import { TextField, TextFieldProps } from '@mui/material'

interface EnterTextFieldProps
  extends Omit<TextFieldProps, 'onChange' | 'defaultValue'> {
  value: string
  regex?: RegExp
  onEnter: (newValue: string) => void
}

const EnterTextField: FC<EnterTextFieldProps> = ({
  onEnter,
  value: stableValue,
  regex,
  ...restProps
}) => {
  const [inputVal, setInputVal] = useState<string | null>(null)
  const value = typeof inputVal === 'string' ? inputVal : stableValue

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (regex && !regex.test(e.target.value)) return
    setInputVal(e.target.value)
  }

  const handleKeydown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter' && value.length > 0) {
      onEnter(value)
      setInputVal(null)
    }
  }

  const handleBlur = () => {
    setInputVal(null)
  }

  return (
    <TextField
      {...restProps}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeydown}
      onBlur={handleBlur}
    />
  )
}

export default EnterTextField
