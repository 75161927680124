
/** @jsxImportSource @emotion/react */

import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { FC, useMemo } from "react";
import { FileHash } from "../../../types/slide";
import { SlideFormValues } from "../../../utils/getSlidePayload";
import { slideDefaultValues, slideViews } from "../../slides/slide-view";
import { determineTextDirection } from "../../../utils/determineTextDirection";
import { themeOptions } from '../../../themeOptions'
import { emotionCacheLtr, emotionCacheRtl } from "../../../utils/emotionCaches";
import { ThemeProvider, createTheme } from "@mui/material";
import { CacheProvider } from "@emotion/react";

interface SlidePreviewProps {
  dir: 'rtl' | 'ltr';
}

export const SlidePreview: FC<SlidePreviewProps> = ({ dir }) => {
  const mainFormContext = useFormContext<{ slide: SlideFormValues }>()

  const slide = useWatch({ control: mainFormContext.control, name: 'slide' });

  const slidePreviewFormContext = useForm<{ userAnswer: any }>({
    defaultValues: {
      userAnswer: slide.type in slideDefaultValues ? (slideDefaultValues as any)[slide.type] : undefined,
    },
  })

  const SlideView = slideViews[slide.type];

  const fileHashes = useMemo(() => {
    const result: FileHash[] = [];

    if (slide.imageFile) result.push(slide.imageFile)
    if (slide.audioFile) result.push(slide.audioFile)

    if (slide.type === 'question-cards') {
      slide.answers.map(answer => {
        if (answer.imageFile) result.push(answer.imageFile)
        if (answer.audioFile) result.push(answer.audioFile)
      })
    }

    return result
  }, [slide]);

  const theme = useMemo(
    () =>
      createTheme({
        ...themeOptions,
        direction: dir,
      }),
    [dir]
  )

  const emotionCache = dir === 'rtl' ? emotionCacheRtl : emotionCacheLtr

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <FormProvider {...slidePreviewFormContext}>
          <div>
            <SlideView
              // @ts-ignore
              slide={slide}
              filesHashes={fileHashes}
              isCurExam={false}
              nextSlide={() => { }}
              prevSlide={() => { }}
              dir={determineTextDirection(slide)}
            />
          </div>
        </FormProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}
