/** @jsxImportSource @emotion/react */

import { useMemo } from 'react'
import { SlideView } from '../../../types/SlideView'
import { QuestionInputSlide } from '../../../types/slide'
import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL'
import { TextField } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useAppSelector } from '../../../store/hooks'
import TextWithMath from '../../TextWithMath'
import { useLocation } from 'react-router-dom'
import makeStyles from '../../../utils/makeStyles'
import { css } from '@emotion/react'
import CodeView from '../../CodeView'

export const questionInputDefaultAnswer = ''

export const checkSlideQuestionInputAnswer = (
  { inputCorrectAnswer }: QuestionInputSlide,
  userAnswer: string
) => {
  return inputCorrectAnswer === userAnswer
}

// --------------------------------------------------------------------
// --------------------------------------------------------------------
const SlideViewQuestionInput: SlideView<QuestionInputSlide> = ({
  slide,
  filesHashes,
  isCurExam
}) => {
  const imageUrl = useMemo(() => {
    const imageFileHash = filesHashes.find(
      (fileHash) => fileHash.id === slide.imageFileId
    )?.fileHash

    return imageFileHash ? `${FILES_BASE_URL}/${imageFileHash}` : null
  }, [])

  // --------------------------------------------------------------------

  const { control, setValue } = useFormContext<{ userAnswer: string }>()

  const userAnswer = useWatch({ control, name: 'userAnswer' })

  const submittedAnswer = useAppSelector(
    (state) => state.slides.userAnswers[slide.type][slide.id]
  )

  const isIncorrect = submittedAnswer && !submittedAnswer.isCorrect

  return (
    <>
      <h2 css={styles.questionTitle} data-for-screen="desktop">
        <TextWithMath value={slide.question} />
      </h2>

      <div css={styles.grid(!!imageUrl)}>
        <div>
          <p css={styles.answerBeginning}>{slide.answerBeginning}</p>

          <TextField
            css={css(styles.textField, {
              '&[data-incorrect-value="true"]': {
                '& fieldset': {
                  borderColor: '#d32f2f !important',
                  // borderColor: isCurExam ? '#000000 !important' : '#d32f2f !important',
                },
              },
            })}
            label={slide.inputLabel}
            autoFocus
            size="small"
            autoComplete="off"
            value={userAnswer}
            onChange={(e) => setValue('userAnswer', e.target.value)}
            data-incorrect-value={isIncorrect}
            disabled={!!submittedAnswer}
            error={isIncorrect}
            helperText={(isIncorrect ? 'الاجابة الصحيحة:' + ' ' + slide.inputCorrectAnswer : undefined)}
            InputLabelProps={{ style: { color: isIncorrect ? '#000000' : undefined } }}
          // helperText={isCurExam ? "" : (isIncorrect ? 'الاجابة الصحيحة:' + ' ' + slide.inputCorrectAnswer : undefined)}
          // InputLabelProps={{ style: { color: isIncorrect ? (isCurExam ? '#000000' : undefined) : undefined } }}
          />


          {slide.codeInput && (
            <div style={{ height: '50rem' }}>
              <CodeView code={slide.codeInput} />
            </div>
          )}
        </div>

        {imageUrl && <img css={styles.img} src={imageUrl} alt="" />}
      </div>
    </>
  )
}

const styles = makeStyles({
  grid: (hasImage: boolean) =>
  ({
    display: 'grid',
    width: '100%',
    gridTemplateColumns: hasImage ? '1fr 1fr' : '1fr',
    gap: '1rem',
    '@media screen and (max-width: 676px)': {
      gridTemplateColumns: '1fr',
    },
  }),
  questionTitle: {
    fontSize: 22,
    fontWeight: 700,
    marginBottom: '1.5rem',
    display: 'block',

  },
  answerBeginning: {
    fontSize: 14,
    marginBottom: '1rem',
  },
  textField: {
    width: 300,
    maxWidth: '100%',
  },
  img: {
    maxWidth: '100%',
    margin: '0 auto',
    display: 'block',
    '@media screen and (max-width: 676px)': {
      gridRow: '1 / 2',
    },
  },
})

export default SlideViewQuestionInput
