import { useParams } from 'react-router-dom'
import { useGetChapterSlidesQuery } from '../../store/chaptersApi'
import LoadingPage from '../LoadingPage'
import NotFound from '../NotFound'
import { useEffect, useRef } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { resetSlidesState } from '../../store/slides'
import PageSlidesView from '../slides/PageSlidesView'
import { TopLinearProgress } from '../TopLinearProgress'

const ChapterViewPage = () => {
  const { chapterId } = useParams() as { chapterId: string }
  const initiatedChapterId = useRef(chapterId)

  const {
    isFetching: isSlidesFetching,
    data,
    error,
  } = useGetChapterSlidesQuery(chapterId)

  const dispatch = useAppDispatch()

  useEffect(() => {
    initiatedChapterId.current = chapterId
    dispatch(resetSlidesState())
  }, [chapterId])

  if (isSlidesFetching || initiatedChapterId.current !== chapterId) {
    return <TopLinearProgress />
  }

  if (error) {
    return (
      <p style={{ textAlign: 'center' }}>
        Please check your internet connection
      </p>
    )
  }

  if (!data) return <NotFound />

  const {
    data: slides,
    relations: { units, filesHashes, course, courseChapters, stickyAds, bannerAd },
  } = data

  if (slides.length === 0) {
    return <p style={{ textAlign: 'center' }}>No Slides yet</p>
  }

  return (
    <PageSlidesView
      dir={course.language === 'ar' ? 'rtl' : 'ltr'}
      slides={slides}
      units={units}
      filesHashes={filesHashes}
      course={course}
      courseChapters={courseChapters}
      curChapterId={chapterId}
      stickyAds={stickyAds}
      isThereBannerAd={!!bannerAd}
    />
  )
}

export default ChapterViewPage
