import { Controller, useFormContext } from 'react-hook-form'
import { useFieldRules } from '../hooks/useFieldRules'
import parseFieldErrorText from '../utils/parseFieldErrorText'
import { AsyncSelectSearch, AsyncSelectSearchProps } from './AsyncSelectSearch'

type AsyncSelectSearchRhfProps<
  V extends Record<string | number | symbol, any>
> = Omit<AsyncSelectSearchProps<V>, 'value' | 'onChange'> & {
  name: string
  required?: boolean
}

export const AsyncSelectSearchRhf = <
  V extends Record<string | number | symbol, any>
>({
  name,
  required,
  placeholder,
  ...restProps
}: AsyncSelectSearchRhfProps<V>) => {
  const rules = useFieldRules({ required })

  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange, ref }, fieldState: { error } }) => {
        const errorText = parseFieldErrorText(error)

        return (
          <AsyncSelectSearch
            {...restProps}
            inputRef={ref}
            name={name}
            placeholder={placeholder + (required ? ' *' : '')}
            value={value}
            onChange={onChange}
            error={!!errorText}
            helperText={errorText}
          />
        )
      }}
    />
  )
}
