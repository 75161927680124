import { FC, useId, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  FormControl,
  ListItemText,
  OutlinedInput,
  InputLabel,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { Select, Checkbox, MenuItem, SelectProps } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type BgiCheckboxProps = SelectProps<any> & {
  name: string
  label?: string
  options: { label: string; value: string }[]
  labelDirection?: 'left' | 'right'
  notched?: any
}

const BgiCheckboxSelect: FC<BgiCheckboxProps> = ({
  name,
  options,
  label,
  fullWidth,
  labelDirection = "left",
  size = 'small',
  ...selectProps
}) => {
  const { control } = useFormContext()
  const uniqueId = useId()
  const labelId = `${uniqueId}-label`
  const [personName, setPersonName] = useState<string[]>([])

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const labelTitleDir: React.CSSProperties = {
    textAlign: labelDirection === 'right' ? 'right' : 'left',
    right: labelDirection === 'right' ? '3rem' : 'auto',
    // left: labelDirection === 'right' ? 'auto' : '0',
    position: 'absolute',
  }
  const labelBoxDir: React.CSSProperties = {
    textAlign: labelDirection === 'right' ? 'right' : 'left',
    right: labelDirection === 'right' ? '1rem' : 'auto',
    // left: labelDirection === 'right' ? 'auto' : '0',
    position: 'absolute',
  }
  const labelTextBoxDir: React.CSSProperties = {
    textAlign: labelDirection === 'right' ? 'right' : 'left',
    marginBottom: labelDirection === 'right' ? '0.1rem' : 'auto',
    marginRight: labelDirection === 'right' ? '2.5rem' : 'auto',
    // left: labelDirection === 'right' ? 'auto' : '0',
    // position: 'absolute',
  }

  return (
    <FormControl>
      <Controller
        control={control}
        name={name}
        // defaultValue={[]}  // If I turn on the default value here, I can't get the error when I forget to put the default value in the component
        render={({ field: { ref, value, onChange } }) => (
          <>
            {label && (
              <InputLabel
                id={labelId}
                size={size === 'small' ? 'small' : undefined}
                shrink={!!value?.length}
                style={labelTitleDir} 
              >
                {label}
              </InputLabel>
            )}

            <Select
              // style={{ width: '100%' }}
              multiple
              value={value}
              ref={ref}
              size={size}
              onChange={onChange}
              input={<OutlinedInput label={label} />}
              notched={!!value?.length}
              renderValue={(selected: string[]) =>
                selected
                  .map(
                    (optionValue) =>
                      options.find((option) => option.value === optionValue)
                        ?.label
                  )
                  .join(', ')
              }

              MenuProps={MenuProps}
              {...selectProps}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}  >
                  <Checkbox checked={value.indexOf(option.value) > -1}style={labelBoxDir} />
                  <ListItemText primary={option.label} style={labelTextBoxDir}/>
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      />
    </FormControl>
  )
}

export default BgiCheckboxSelect
