import { css } from '@emotion/css'
import { CircularProgress } from '@mui/material'

const LoadingPage = () => {
  return (
    <div className={styles.root}>
      <CircularProgress size={48} />
    </div>
  )
}

const styles = {
  root: css({
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }),
}

export default LoadingPage
