import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';



i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'ar', 'gr'],
    fallbackLng: "en",
    defaultNS: 'common',
    fallbackNS: 'fallback',
    ns: ['common', 'Courses', 'fallback',
      'Header', 'SlideHeader', ' PageC_C_V',
      'ContactUs', 'SlideForm', 'Slide',
      'Collections', 'Exams', 'Ads', 'Users', 'Feedback', 'Units',
      'HomePage', 'SearchSlide', 'Signup_login',
    ],
    detection: {
      order: [
        'cookie',
        'htmlTag',
        'localStorage',
        'sessionStorage',
        'navigator',
        'path',
        'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  });

export default i18n;


//src\types\@types\resources.d.ts