import randomInt from './randomInt'

export interface RandomArrOptions<T extends any> {
  avoidRandomize?: (item: T) => boolean
}

const randomArr = <T extends any>(
  arr: T[],
  { avoidRandomize }: RandomArrOptions<T> = {}
): T[] => {
  const sortedArr: T[] = []

  const cloned = [...arr]

  if (avoidRandomize) {
    for (let i = 0; i < cloned.length; i++) {
      const item = cloned[i]

      if (avoidRandomize(item)) {
        sortedArr.push(item)
        cloned.splice(i, 1)
        i--
      }
    }
  }

  while (cloned.length > 0) {
    const randomIndex = randomInt(0, cloned.length)
    const [item] = cloned.splice(randomIndex, 1)
    sortedArr.push(item)
  }

  return sortedArr
}

export default randomArr
