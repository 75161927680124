import { Button, Paper, Stack } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { BgiTextField } from '../../../UI/BgiTextField'
import { CollectionI } from '../../../types/collection'
import { useNavigate } from 'react-router-dom'
import { BgiLink } from '../../../UI/BgiLink'
import {
  useCreateCollectionMutation,
  useDeleteCollectionMutation,
  useUpdateCollectionMutation,
} from '../../../store/collectionsApi'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'
import { useSnackbar } from 'notistack'
import { AssetField } from '../../gallery/AssetField'
import { FileData } from '../../gallery/FileForm'
import { useTranslation } from 'react-i18next'
import Layout from '../../page/Layout'


type CollectionFormData = Omit<CollectionI, 'imageFileId'> & {
  id?: string
  imageFile?: FileData
}


// ---Comp------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------
export const CollectionForm = () => {
  const { control, handleSubmit, reset } = useFormContext<CollectionFormData>()
  const navigate = useNavigate()

  const { t } = useTranslation(['Collections', 'common'])

  const collectionId = useWatch({ control, name: 'id' })
  const isCreateForm = !collectionId
  const { enqueueSnackbar } = useSnackbar()

  const [createCollection, { isLoading: isCreating }] = useCreateCollectionMutation()

  const [updateCollection, { isLoading: isUpdating }] = useUpdateCollectionMutation()

  const [deleteCollection, { isLoading: isDeleting }] = useDeleteCollectionMutation()


  // ---Mass----------------------------------------------------------------------
  // -------------------------------------------------------------------------
  const SUCCESS_MSGS = {
    create: (collectionId: string) => (
      <p>
        {t('Collection_has_been_created_successfully_with')}{' '}
        <BgiLink color="#fff" to={`/data-entry/collections/${collectionId}/update`}>
          #{collectionId}
        </BgiLink>
      </p>
    ),
    update: t('The_group_has_been_updated_successfully'),
  }

  const getErrMsg = buildGetErrMsg({
    DUP_COLLECTION_CODE: t('Duplicate_group_code_Enter_another_code'),
    COLLECTION_IS_REFERENCED: t('Please_delete_collection\'s_chapters_first'),
  })
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------



  const onSubmit = async (collection: CollectionFormData) => {
    const { id, imageFile, ...restPayload } = collection

    const payload = {
      ...restPayload,
      imageFileId: imageFile?.id ?? null,
    }

    if (!id) {
      const result = await createCollection(payload)

      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['create'](result.data.id),
          variant: 'success',
        })
        reset()
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    } else {
      const result = await updateCollection({ id, ...payload })

      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['update'],
          variant: 'success',
        })
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    }
  }

  const onDelete = async () => {
    if (!collectionId) return

    const confirmationMsg = 'Are you sure you want to delete this collection'

    if (!window.confirm(confirmationMsg)) return

    const result = await deleteCollection(collectionId)

    if ('data' in result) {
      enqueueSnackbar({
        message: t('Collection_has_been_deleted_successfully'),
        variant: 'success',
      })

      navigate(`/collections`)
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  }

  return (
    <Layout>
      {/* <BgiLink to="/collections">Back to Collections page</BgiLink> */}

      <div style={{ width: '100%', height: 30 }} />

      <Paper
        style={{
          padding: '1rem',
          marginBottom: '2rem',
        }}
      >
        <h2 style={{ marginBottom: 16 }}>{isCreateForm ? t('common:Create') : 'Update'} {t('Collections')}</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <BgiTextField label={t('Code')} name="code" fullWidth size="small" required maxLength={64} />

            <BgiTextField label={t('Title')} name="title" fullWidth size="small" required maxLength={255} />

            <AssetField name="imageFile" fileKind="image" />

            <BgiTextField
              label={t('Description')}
              name="description"
              fullWidth
              size="small"
              multiline
              minRows={2}
              maxRows={4}
            />

            <Stack direction="row" justifyContent="space-between">
              <Button variant="contained" type="submit" disabled={isCreating || isUpdating}>
                {isCreateForm ? t('common:Create') : t('common:Update')}
              </Button>

              {!isCreateForm && (
                <Button color="error" variant="outlined" onClick={onDelete} disabled={isDeleting}>
                  Delete
                </Button>
              )}
            </Stack>
          </Stack>
        </form>
      </Paper>

      <div style={{ width: '100%', height: 30 }} />
    </Layout>
  )
}
