import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import slidesData, { Slide, Topic, topics } from '../data/questionsdata'
import randomArr from "../utils/randomArr";

type SlidesState = {
    slides: Slide[];
    topics: Topic[];
    curSlideIdx: number;
    markedIndexes: number[]
    isSlideFinished: boolean;
    isWaiting: boolean;
    wrongAnswers: { [payloadSlideIdx: string]: string[] };
    userAnswers: { [payloadSlideIdx: string]: string[] };
    lastReachedIdx: number;
    totalPoint: number;
}

const initSlidesObj = (slidesCount: number) => {
    const slidesObj: { [payloadSlideIdx: string]: string[] } = {}

    for (let i = 0; i < slidesCount; i++) {
        slidesObj[i] = []                      //
    }

    return slidesObj
}
// --------------------------------------------------------------------------
const initialSlidesState: SlidesState = {
    slides: slidesData.map(slide => ({
        ...slide,
        answers: randomArr(slide.answers)     // This library that was created to change the locations of the answers
        // answers: slide.answers    
    })),
    topics,
    curSlideIdx: 0,
    markedIndexes: [],
    isSlideFinished: false,
    isWaiting: false,
    wrongAnswers: initSlidesObj(slidesData.length),
    userAnswers: initSlidesObj(slidesData.length),
    lastReachedIdx: 0,
    totalPoint : 0,
}

const slides = createSlice({
    name: 'slides',
    initialState: initialSlidesState,
    reducers: {
        evaluateAnswer(state) {
            state.isWaiting = true

            if (state.lastReachedIdx != state.curSlideIdx) return;

            // set wrongAnswer
            const userAnswer = state.userAnswers[state.curSlideIdx]
            const curSlide = state.slides[state.curSlideIdx]
            const correctAnswer = curSlide.correctAnswer

            if (curSlide.question && userAnswer.sort().join() !== correctAnswer.sort().join()) {
                const wrongAnswer: string[] = []

                curSlide.answers.forEach(answer => {
                    if (!correctAnswer.includes(answer.id)) wrongAnswer.push(answer.id)
                })

                state.wrongAnswers[state.curSlideIdx] = wrongAnswer
            } else{
                state.totalPoint+= curSlide.point
            }
        },
        nextSlide(state) {
            state.isWaiting = false
            state.curSlideIdx++
            if (state.curSlideIdx >= state.lastReachedIdx) state.lastReachedIdx = state.curSlideIdx
        },
        previousSlide(state) {
            state.curSlideIdx--
        },
        finished(state) {
            state.isSlideFinished = true
        },
        toggleMark(state, { payload: { payloadSlideIdx } }: PayloadAction<{ payloadSlideIdx: number }>) {
            const isMarked = state.markedIndexes.some(markedIndex => markedIndex === payloadSlideIdx)

            if (isMarked) {
                // un-mark
                state.markedIndexes = state.markedIndexes.filter(markedIndex => markedIndex !== payloadSlideIdx)
            } else {
                // mark
                state.markedIndexes.push(payloadSlideIdx)
            }
        },
        toggleAnswer(state, { payload: { answerId } }: PayloadAction<{ answerId: string }>) {
            const { curSlideIdx } = state;

            const slideUserAnswers = state.userAnswers[curSlideIdx]
            const isChecked = slideUserAnswers.some(userAnswer => userAnswer === answerId)

            if (isChecked) {
                // un-check
                state.userAnswers[curSlideIdx] = slideUserAnswers.filter(userAnswer => userAnswer !== answerId)
            } else {
                // check
                state.userAnswers[curSlideIdx].push(answerId)
            }

        }

    }
})

export const slidesActions = slides.actions
export default slides;