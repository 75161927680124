import { Button, Paper, Stack } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { BgiTextField } from '../../../UI/BgiTextField'
import { SubcollectionI } from '../../../types/subcollection'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BgiLink } from '../../../UI/BgiLink'
import {
  useCreateSubcollectionMutation,
  useDeleteSubcollectionMutation,
  useUpdateSubcollectionMutation,
} from '../../../store/subcollectionsApi'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'
import { useSnackbar } from 'notistack'
import { AssetField } from '../../gallery/AssetField'
import { FileData } from '../../gallery/FileForm'
import { useTranslation } from 'react-i18next'

type SubcollectionFormData = Omit<SubcollectionI, 'imageFileId' | 'collectionId'> & {
  id?: string
  imageFile?: FileData
}

// ---------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------
export const SubcollectionForm = () => {
  const [searchParams] = useSearchParams()
  const collectionId = searchParams.get('collectionId')

  const { t } = useTranslation(['Collections', 'common'])

  const { control, handleSubmit, reset } = useFormContext<SubcollectionFormData>()
  const navigate = useNavigate()

  const subcollectionId = useWatch({ control, name: 'id' })
  const isCreateForm = !subcollectionId
  const { enqueueSnackbar } = useSnackbar()

  const [createSubcollection, { isLoading: isCreating }] = useCreateSubcollectionMutation()

  const [updateSubcollection, { isLoading: isUpdating }] = useUpdateSubcollectionMutation()

  const [deleteSubcollection, { isLoading: isDeleting }] = useDeleteSubcollectionMutation()


  // ---Mes------------------------------------------------------------------------------
  const SUCCESS_MSGS = {
    create: (subcollectionId: string, collectionId: string) => (
      <p>
        {t('Subcollection_has_been_created_successfully_with')}{' '}
        <BgiLink color="#fff" to={`/data-entry/subcollections/${subcollectionId}/update?collectionId=${collectionId}`}>
          #{subcollectionId}
        </BgiLink>
      </p>
    ),
    update: t('The_subcollection_has_been_updated_successfully'),
  }
  
  const getErrMsg = buildGetErrMsg({
    DUP_SUBCOLLECTION_CODE: t('Subcollection_code_is_duplicate._Enter another_code'),
    SUBCOLLECTION_IS_REFERENCED: "Please delete subcollection's chapters first",
  })
  // ---------------------------------------------------------------------------------

  if (typeof collectionId !== 'string') {
    return <p>Page Not Found 404</p>
  }

  const onSubmit = async (subcollection: SubcollectionFormData) => {
    const { id, imageFile, ...restPayload } = subcollection

    const payload = {
      ...restPayload,
      imageFileId: imageFile?.id ?? null,
    }

    if (!id) {
      const result = await createSubcollection({
        ...payload,
        collectionId,
      })

      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['create'](result.data.id, collectionId),
          variant: 'success',
        })
        reset()
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    } else {
      const result = await updateSubcollection({ id, ...payload })

      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['update'],
          variant: 'success',
        })
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    }
  }

  const onDelete = async () => {
    if (!subcollectionId) return

    const confirmationMsg = 'Are you sure you want to delete this subcollection'

    if (!window.confirm(confirmationMsg)) return

    const result = await deleteSubcollection(subcollectionId)

    if ('data' in result) {
      enqueueSnackbar({
        message: 'Subcollection has been deleted successfully',
        variant: 'success',
      })

      navigate(`/collections/${collectionId}/subcollections`)
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  }

  return (
    <>
      <BgiLink to={`/collections/${collectionId}/subcollections`}>{t('Back_to_Subcollections_page')}</BgiLink>

      <div style={{ width: '100%', height: 30 }} />

      <Paper
        style={{
          padding: '1rem',
          marginBottom: '2rem',
        }}
      >
        <h2 style={{ marginBottom: 16 }}>{isCreateForm ? 'Create' : 'Update'} {t('Subcollection')}</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <BgiTextField label={t('Code')} name="code" fullWidth size="small" required maxLength={64} />

            <BgiTextField label={t('Title')} name="title" fullWidth size="small" required maxLength={255} />

            <AssetField name="imageFile" fileKind="image" />

            <BgiTextField
              label={t('Description')}
              name="description"
              fullWidth
              size="small"
              multiline
              minRows={2}
              maxRows={4}
            />

            <Stack direction="row" justifyContent="space-between">
              <Button variant="contained" type="submit" disabled={isCreating || isUpdating}>
                {isCreateForm ? t('common:Create') : t('common:Update')}
              </Button>

              {!isCreateForm && (
                <Button color="error" variant="outlined" onClick={onDelete} disabled={isDeleting}>
                  {t('common:Delete')}
                </Button>
              )}
            </Stack>
          </Stack>
        </form>
      </Paper>

      <div style={{ width: '100%', height: 30 }} />
    </>
  )
}
