import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { User, UserI } from '../types/user'
import { apiConfig } from './api'
import countries from '../data/countries'

type RawUser = Omit<User, 'country'> & {
  countryCode?: string
}

const transformUser = (user: RawUser) => ({
  ...user,
  country: countries.find((c) => c.code === user.countryCode)!,
})

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/users`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getUsers: builder.query<User[], void>({
      query: () => '',
      transformResponse: (data: RawUser[]) => data.map(transformUser),
    }),
    getUser: builder.query<User | null, string>({
      query: (userId) => `/${userId}`,
      transformResponse: transformUser,
    }),
    createUser: builder.mutation<User, Omit<UserI, 'id'>>({
      query: ({ country, repeatPassword, ...body }) => ({
        url: `/`,
        method: 'POST',
        body: {
          ...body,
          countryCode: country?.code,
          role:
            body.role && ['student', 'teacher'].includes(body.role)
              ? body.role
              : undefined,
        },
      }),
      transformResponse: transformUser,
    }),
    updateUser: builder.mutation<User, Partial<UserI> & Pick<User, 'id'>>({
      query: ({ id, country, repeatPassword, password, role, ...body }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: {
          ...body,
          countryCode: country ? country.code : undefined,
          password: password && password.length > 0 ? password : undefined,
        },
      }),
      transformResponse: transformUser,
    }),
    deleteUser: builder.mutation<void, string>({
      query: (userId) => ({ url: `/${userId}`, method: 'DELETE' }),
    }),
  }),
})

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApi
