import { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  TextField,
} from '@mui/material'
import { axiosInstance } from '../../utils/axiosInstance'
import { FileData, FileForm } from './FileForm'
import { useDebouncedCallback } from 'use-debounce'
import { Asset, AssetProps } from '../../UI/Asset'
import { css } from '@emotion/css'
import { buildGetErrMsg } from '../../utils/buildGetErrMsg'
import useSnackResErr from '../../hooks/useSnackResErr'
import { useSnackbar } from 'notistack'

export interface FilesLib {
  selectedFileId?: string
  onSelect?: (file: FileData) => void
  disabledAssetUpdate?: boolean
  pageImagesLimit: number
  fileKind: AssetProps['fileKind']
}

const getErrMsg = buildGetErrMsg()

export const FilesLib: FC<FilesLib> = ({
  selectedFileId,
  onSelect,
  disabledAssetUpdate,
  pageImagesLimit,
  fileKind
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const [files, setFiles] = useState<FileData[]>([])
  const [openedFile, setOpenedFile] = useState<FileData | null>(null)
  const snackResErr = useSnackResErr(getErrMsg)

  const [filesCount, setFilesCount] = useState(1)
  const [curPage, setCurPage] = useState(1)
  const [disabledPaginating, setDisabledPaginating] = useState(false)
  const searchLabel = useRef('')

  const pagesNumber = useMemo(
    () => (filesCount > 0 ? Math.ceil(filesCount / pageImagesLimit) : 1),
    [filesCount]
  )

  const debounced = useDebouncedCallback(
    // function
    (value: string) => {
      searchLabel.current = value
      fetchFiles(1)
    },
    // delay in ms
    300
  )

  const fetchFiles = async (fetchingPage: number) => {
    setIsFetching(true)

    const skip = (fetchingPage - 1) * pageImagesLimit

    const query = new URLSearchParams()

    query.set('searchLabel', searchLabel.current)
    query.set('fileKind', fileKind)
    query.set('skip', String(skip))
    query.set('take', String(pageImagesLimit))

    try {
      const res = await axiosInstance.get(`/files?${query}`)

      const newPagesNumber =
        res.data.metadata.count > 0
          ? Math.ceil(res.data.metadata.count / pageImagesLimit)
          : 1

      if (fetchingPage > newPagesNumber) {
        fetchFiles(newPagesNumber)
      } else {
        setFiles(res.data.data)
        setFilesCount(res.data.metadata.count)
        setCurPage(fetchingPage)
        setIsFetching(false)
      }
    } catch (e) {
      snackResErr(e)
    }
  }

  useEffect(() => {
    fetchFiles(1)
  }, [fileKind])

  const handleUpload = (newFile: FileData) => {
    if (curPage === 1) {
      setFiles((prevFiles) => [newFile, ...prevFiles].slice(0, pageImagesLimit))
      setFilesCount((prevFilesCount) => prevFilesCount + 1)
    } else {
      fetchFiles(1)
    }

    handleSelect(newFile)
  }

  const handleSelect = (newFile: FileData) => {
    if (onSelect) onSelect(newFile)
  }

  const onPageChanged = async (newPage: number) => {
    setDisabledPaginating(true)

    await fetchFiles(newPage)

    setCurPage(newPage)
    setDisabledPaginating(false)
  }

  const updateOpenedFile = (newFile: FileData) => {
    setFiles((files) =>
      files.map((file) => (file.id !== newFile.id ? file : newFile))
    )

    setOpenedFile(null)
  }

  const deleteOpenedFile = async () => {
    if (!openedFile) return
    setFiles((files) => files.filter((file) => file.id !== openedFile.id))
    fetchFiles(curPage)
    setOpenedFile(null)
  }

  return (
    <>
      <TextField
        style={{ marginBottom: '0.5rem' }}
        defaultValue={searchLabel.current}
        onChange={(e) => debounced(e.target.value)}
        size="small"
        label="Search Label"
      />

      <div style={{ height: 4, marginBottom: '0.5rem' }}>
        {isFetching && <LinearProgress />}
      </div>

      <Stack direction="row" alignItems="stretch" flexWrap="wrap" gap={2}>
        <Asset
          createButton={{
            type: 'input',
            onChange: { action: 'upload', onUploaded: handleUpload },
          }}
          fileKind={fileKind}
          // assetWrapperStyle={{ height: '100%' }}
        />

        {files.map((file, fileIdx) => (
          <div
            style={{
              cursor: onSelect ? 'pointer' : undefined,
              borderRadius: '0.25rem',
              border:
                '1px solid ' +
                (selectedFileId === file.id ? '#2196F3' : '#64748b'),
              background: selectedFileId === file.id ? '#E3F2FD' : undefined,
              width: 200,
              overflow: 'hidden',
            }}
            key={file.id}
            onClick={() => handleSelect(file)}
          >
            <Asset
              value={{
                fileSrc: `${file.fileHash}?updatedAt=${file.updatedAt}`,
                fileExt: file.fileExt,
              }}
              updateButton={
                disabledAssetUpdate
                  ? undefined
                  : {
                      type: 'button',
                      onClick: () => setOpenedFile(file),
                    }
              }
              assetWrapperStyle={{
                width: 198,
                height: 198,
                border: 'none',
                borderRadius: 0,
              }}
              fileKind={fileKind}
            />

            <div style={{ padding: '0.5rem', borderTop: '1px solid #64748b' }}>
              <p style={{ fontSize: 15, wordWrap: 'break-word' }}>
                {file.label}
              </p>
              <p style={{ fontSize: 13, color: '#616161' }}>{file.fileExt}</p>
            </div>
          </div>
        ))}
      </Stack>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '1.5rem',
        }}
      >
        <Pagination
          count={pagesNumber}
          color="primary"
          page={curPage}
          onChange={(e, newPage) => onPageChanged(newPage)}
          disabled={disabledPaginating}
        />
      </div>

      {openedFile && (
        <FileForm
          fileData={openedFile}
          onCancel={() => setOpenedFile(null)}
          onUpdated={updateOpenedFile}
          onDeleted={deleteOpenedFile}
          fileKind={fileKind}
        />
      )}
    </>
  )
}
