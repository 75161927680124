import { Alert, Button, Container } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { useFetch } from '../../hooks/useFetch'
import { Course } from '../../types/course'
import { BgiLink } from '../../UI/BgiLink'
import PageHeader from '../../UI/PageHeader'
import LoadingPage from '../LoadingPage'
import CoursesGrid from './CoursesGrid'
import { useGetSubcollectionQuery } from '../../store/subcollectionsApi'
import { useGetCoursesQuery, useLazyGetCoursesQuery, useUpdateCourseIndexMutation } from '../../store/coursesApi'
import { useAppSelector } from '../../store/hooks'
import { TopLinearProgress } from '../TopLinearProgress'
import Layout from '../page/Layout'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { enqueueSnackbar, useSnackbar } from 'notistack'
import { buildGetErrMsg } from '../../utils/buildGetErrMsg'


// ---Comp----------------------------------------------------------
// -----------------------------------------------------------------
// -----------------------------------------------------------------
export const CoursesListPage = () => {
  const getErrMsg = buildGetErrMsg()
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams()
  const subcollectionId = params.subcollectionId as string
  
  const [courses, setCourses] = useState<Course[]>([])  //---Order
  const [getCourses, { isSuccess: isCoursesFetched, error: coursesErr }] = useLazyGetCoursesQuery()   //---Order
  const userMe = useAppSelector((state) => state.auth.userMe)
  
  //---Order--
  useEffect(() => {
    (async () => {
      const result = await getCourses(subcollectionId)
      if ('data' in result && result.data) {
        setCourses(result.data)
      }
    })()
  }, [subcollectionId])
  
  
  
  const { t } = useTranslation(['Courses', 'common'])
  

  //---Order-------------------------------------------------------------------
  //---------------------------------------------------------------------------
  const { data: subcollection } = useGetSubcollectionQuery(subcollectionId)
  
    const [updateCourseIndex] = useUpdateCourseIndexMutation()  //---Order--

  const [reorderItem, setReorderItem] = useState<{
    courseId: string
    newIndex: number
  } | null>(null)
  

  const updateCourseOrder = async (courseId: string, newIndex: number) => {
    if (!courses) return;

    setReorderItem({ courseId, newIndex })
    
    const result = await updateCourseIndex({
      id: courseId,
      index: newIndex,
    })

    if ('data' in result) {
      const courseIndexes: Record<string, number> = {}
      
      courses.forEach(
        (course, index) => (courseIndexes[course.id] = index))

      const newCourses = result.data.map((courseId) => courses[courseIndexes[courseId]])
      
      setCourses(newCourses)
      enqueueSnackbar({ message: 'تم إعادة الترتيب بنجاح', variant: 'success' })
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
    setReorderItem(null)
  }

  const handleReorderEnter = (courseId: string) => (newValue: string) => {
    const newIndex = Number(newValue) - 1
    if (!courses || newIndex + 1 > courses.length) return
    updateCourseOrder(courseId, newIndex)
  }
  //-------------------------------------------------------------------Order---
  //---------------------------------------------------------------------------

  if (!subcollection || !courses) {
    return <TopLinearProgress />
  }

  return (
    <Layout>
      <br />
      <Container maxWidth="md">
        <Button
          component={Link}
          to={`/collections/${subcollection.collectionId}/subcollections`}
          variant="contained"
        >
          {t('Back_to_the_list_of_subjects')}
        </Button>
        <div style={{ width: '100%', height: 30 }} />

        <PageHeader
          style={{ marginBottom: 24 }}
          title={subcollection.title}
          actions={
            userMe?.role === 'admin' ? (
              <Button
                component={Link}
                variant="contained"
                to={`/data-entry/courses/create?subcollectionId=${subcollectionId}`}
              >
                {t('Courses:Create_course')}
              </Button>
            ) : undefined
          }
        />

        <CoursesGrid
          courses={courses}
          to={(courseId) => `/data-entry/courses/${courseId}/update?subcollectionId=${subcollectionId}`}
          reorderItem={reorderItem}
          handleReorderEnter={handleReorderEnter}
        />

        <div style={{ width: '100%', height: '20rem' }} />
      </Container>
    </Layout>
  )
}
