/** @jsxImportSource @emotion/react */

import React from 'react'
import { Button, Container } from '@mui/material'
import { Link } from 'react-router-dom'
import Layout from '../page/Layout'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'


const SelectAccountType = () => {

  const { t } = useTranslation('Signup_login')

  return (
    <Layout>
      <Container maxWidth="lg">
        <h3 css={mcss.title}>{t('Please_choose_the_account_type')}</h3>
        <div css={mcss.buttonRegister}>

          <Button
            component={Link}
            to="/signUp"
            variant="contained" color="error"
          >{t('Student')} / {t('Teacher')}
          </Button>

          <Button
            component={Link}
            to="/signUp-for-other"
            variant="contained" color="error"
          >{t('Organization')} 
          </Button>
        </div>
      </Container>

    </Layout>
  )
}


const mcss = {
  buttonRegister: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '2rem',
    margin: '5rem auto',
    width: '100%',
    maxWidth: 400,
    '@media (max-width: 600px)': {
      gridTemplateColumns: '1fr',
      maxWidth: 200,
    },
  }),
  title: css({
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#333',
    margin: '2rem 0',
  }),

}
export default SelectAccountType
