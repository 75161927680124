import { display } from "html2canvas/dist/types/css/property-descriptors/display"

export const QuickAccessHighSchoolCoursesData = [
  {
    title: 'الفيزياء للصف الثالث الثانوي',
    description: `https://www.bgi-edu.com/subcollections/1/courses`,
    disabled: false
  },
  {
    title: 'الفيزياء للصف الثاني الثانوي',
    description: `https://www.bgi-edu.com/subcollections/3/courses`,
    disabled: false
  },
  {
    title: ' - تحت الإعداد ــ الفيزياء للصف الأول الثانوي',
    description: `https://www.bgi-edu.com/subcollections/5/courses`,
    disabled: false
  },
]

// --------------------------------------------------------------------------------
export const QuickAccessMiddleSchoolCoursesData = [
  {
    title: 'العلوم للصف التاسع ',
    description: `https://www.bgi-edu.com/subcollections/10/courses`,
    disabled: false
  },
]

// --------------------------------------------------------------------------------

export const QuickAccessHighSchoolEnglishCoursesData = [
  {
    title: 'اللغة الإنجليزية للصف الثالث الثانوي',
    description: `https://www.bgi-edu.com/subcollections/13/courses`,
    disabled: false
  },
]

// --------------------------------------------------------------------------------