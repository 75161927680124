/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Container, Typography } from '@mui/material'
import Layout from './Layout'
import { useTranslation } from 'react-i18next'
import UseDynamicLanguageStyle from '../i18n/UseDynamicLanguageStyle'


const StudentHomePage = () => {
  const { t } = useTranslation("HomePage")
  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()


  return (
    <Layout>
      <div css={[styles.allPage,]}>
        <Container>
          <div css={styles.classStudent}>
            <Typography variant='body2' style={{fontSize: "2rem"}}>
              {t('Student_home_page_10')}
            </Typography>

            <div>
              <img style={{ width: '100%' }} src="student1.jpg" />
            </div>
          </div>

          <div css={styles.student}>
            <div css={styles.imageSecond}>
              <img src="student.jpg" />
            </div>
            <div css={dynamicLanguageStyle}>
              <div>
                <p>{t('Student_home_page_20')}</p>
              </div>
            </div>
          </div>

          <div css={styles.student}>
            <div css={styles.imageSecond}>
              <div css={dynamicLanguageStyle}>
                <p>{t('Student_home_page_30')}</p>
              </div>
            </div>
            <div css={styles.imageSecond}>
              <img src="student2.jpg" />
            </div>
          </div>

          <div css={styles.choose}>
            <div>
              <div><p>{t('Student_home_page_40')}</p></div>

            </div>
            <div><img src="chooseYourLearn.jpg" /></div>
          </div>
        </Container>
      </div>


    </Layout>
  )
}

export default StudentHomePage

const styles = {
  allPage: css({
    backgroundColor: 'whitesmoke',
    'img': {
      borderRadius: '0.8rem',
      width: '100%',
      '@media (max-width: 700px)': {
        borderRadius: '0.4rem',
        width: '100%',
      },
    },
    'p': {
      // direction: 'rtl',
      fontSize: '2rem',
      placeItem: 'center',
      margin: '1rem',
      '@media (max-width: 700px)': {
        fontSize: '1.2rem',
      },
    },
    // 'h3': { direction: 'rtl', },
  }),
  classStudent: css({
    textAlign: 'center',
    marginTop: '2rem',
    width: '100%',
    '@media (max-width: 700px)': {
      'p': {
        textAlign: 'center',
        margin: '1rem 0'
      },
    },
  }),
  student: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    placeItems: 'center',
    // -------------------------------------------
    '@media (max-width: 700px)': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      margin: '1rem 0.5rem',
      placeItems: 'end',
      'p': {
        marginBottom: '0.2rem',
        paddingRight: '1.5rem',
      },
    },
  }),

  choose: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    'p': {
      marginLeft: '0.5rem',
    },
    '@media (max-width: 700px)': {
    },
  }),

  textFirst: css({
    '@media (max-width: 700px)': {
      order: 1,
    },
  }),
  imageSecond: css({
    '@media (max-width: 700px)': {
      order: 2,
      paddingRight: '1rem',
    },
  }),


}