import { FormProvider, useForm } from 'react-hook-form';
import  AnnouncementForm  from './AnnouncementForm';
import { AnnouncementI } from '../../../types/announcement';
import { Container } from '@mui/material';

const CreateAnnouncement = () => {

  const formContext = useForm<AnnouncementI>({
    defaultValues: {
      title: '',
      description: '',
    },
  });

  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <AnnouncementForm /> 
      </FormProvider>
    </Container>
  )
}

export default CreateAnnouncement
