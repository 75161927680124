import { FC } from 'react'
import { AsyncSelectSearchRhf } from '../UI/AsyncSelectSearchRhf'
import { TextField } from '@mui/material'

interface UnitsSearchProps {
  name: string
  required?: boolean
}

export const UnitsSearch: FC<UnitsSearchProps> = ({ name, required }) => {
  return (
    <AsyncSelectSearchRhf
      name={name}
      renderPreInput={({ value, onChange, onFocus }) => (
        <TextField
          label="Search Tags"
          size="small"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={onFocus}
          fullWidth
        />
      )}
      requestPathname="/units"
      preInputParam="searchTags"
      mainInputParam="detailedTitle"
      noOptionsMessage="No units match the search"
      processOption={(unit) => ({
        label: unit.detailedTitle,
        value: unit.id,
      })}
      required={required}
      placeholder="Unit"
      retainUnmatchedValueWhenClose
    />
  )
}
