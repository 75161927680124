import { FC, useMemo } from 'react'
import Slides, { SlidesProps } from './Slides'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider, createTheme } from '@mui/material'
import { themeOptions } from '../../themeOptions'
import { emotionCacheLtr, emotionCacheRtl } from '../../utils/emotionCaches'

const PageSlidesView: FC<SlidesProps> = ({ dir, ...slidesProps }) => {
  const theme = useMemo(
    () =>
      createTheme({
        ...themeOptions,
        direction: dir,
      }),
    [dir]
  )

  const emotionCache = dir === 'rtl' ? emotionCacheRtl : emotionCacheLtr

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <Slides {...slidesProps} dir={dir} />
      </ThemeProvider>
    </CacheProvider>
  )
}

export default PageSlidesView
