import React, { FC, ReactNode, useState, useEffect } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';





export const extractLastAutoGeneratedSlideNumber = (inputString: string, incrementValue: number) => {
  const match = inputString.match(/(\d+)$/);

  if (match) {
    const currentNumber = parseInt(match[0], 10);
    const incrementedNumber = currentNumber + incrementValue;
    return inputString.replace(/(\d+)$/, incrementedNumber.toString());
  } else {
    return inputString;
  }
};



// ---comp--------------------------------------------
// ---------------------------------------------------
// ---------------------------------------------------

type UiCheckboxProps = CheckboxProps & { name: string; label?: ReactNode };

export const UiCheckboxOutHook: FC<UiCheckboxProps> = ({
  name,
  label,
  onChange,
  ...checkboxProps
}) => {
  const [checked, setChecked] = useState<boolean>(checkboxProps.checked || false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
    setChecked(newValue);
    if (onChange) {
      onChange(event, newValue);
    }
  };

  return label ? (
    <FormControlLabel
      control={
        <Checkbox
          {...checkboxProps}
          name={name}
          checked={checked}
          onChange={handleChange}
        />
      }
      label={label}
    />
  ) : (
    <Checkbox
      {...checkboxProps}
      name={name}
      checked={checked}
      onChange={handleChange}
    />
  );
};

