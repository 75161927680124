/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { AppBar, Button, Container, Toolbar, Box } from '@mui/material';
import { Login, Email, NearMe, Apartment, ContactEmergency, Flare } from '@mui/icons-material';

const Footer = () => {
    return (
        <AppBar position="static" component="footer">
            <Toolbar css={styles.toolbar}>
                <Container maxWidth="lg">
                    <Box css={styles.flexContainer}>
                        <Box css={styles.logo}>
                            <img src="/bgiIcon512.jpg" alt="Logo" />
                            <span>BGi-Photon</span>
                        </Box>
                        <Box>
                            <span>Contact Us</span>
                            <Box css={styles.pngLine}>
                                <NearMe css={styles.icon} />
                                <span>Hamburg</span>
                            </Box>
                            <Box css={styles.pngLine}>
                                <Email css={styles.icon} />
                                <a href="mailto:bgi-photon@outlook.com">info@bgi-photon.com</a>
                            </Box>
                            <Box css={styles.pngLine}>
                                <NearMe css={styles.icon} />
                                <span>Taiz - Yemen</span>
                            </Box>
                            <Box css={styles.pngLine}>
                                <Email css={styles.icon} />
                                <a href="mailto:bgi-photon@outlook.com">bgi-photon@outlook.com</a>
                            </Box>
                        </Box>
                        <Box css={css(styles.detail, styles.noneMobile)}>
                            <span>Account</span>
                            <Box css={styles.pngLine}>
                                <Login css={styles.icon} />
                                <Button component={Link} to="/signup" variant="contained" color="primary">
                                    Login
                                </Button>
                            </Box>
                        </Box>
                        <Box css={css(styles.detail, styles.noneMobile)}>
                            <span>Company</span>
                            <Box css={styles.pngLine}>
                                <Apartment css={styles.icon} />
                                <a href="/">
                                    <p>BGi-Photon</p>
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Toolbar>
            <Box css={styles.copyRight}>
                <span>Copyright ©2024 by BGi-photon.</span>
                <span>All rights reserved.</span>
            </Box>
        </AppBar>
    );
};

export default Footer;

const styles = {
    toolbar: css({
       
        backgroundColor: 'primary.main',
        fontSize: '1rem',
        'a': {
            color: 'white',
            textDecoration: 'none',
            ':hover': {
                textDecoration: 'underline'
            }
        },
        '@media(max-width: 700px)': {
            '.MuiBox-root': {
                display: 'gird',
                gridTemplateColumns: '1fr 3fr',
                backgroundColor: 'secondary.main',
                gap: '1rem',
            }
        }
    }),
    flexContainer: css({
        display: 'grid',
        gridTemplateColumns: '0.5fr 1fr 1fr 1fr',
        gap: '1rem',
        '@media(max-width: 700px)': {
            flexDirection: 'row',
            gap: '1rem',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    }),
    logo: css({
        display: 'block',
        paddingRight: '2rem',

        'img': {
            display: 'block',
            marginTop: '5px',
            width: '3rem',
            height: "3rem",
            borderRadius: '50%',
            border: '2px solid white',
        },
        '@media(max-width: 700px)': {
            paddingRight: '2rem',
            textAlign: 'center',
        }
    }),
    icon: css({
        maxWidth: '25px'
    }),
    pngLine: css({
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        fontSize: '1rem',
        ':hover': {
            cursor: 'pointer'
        },
        // '@media(max-width: 700px)': {
        //     fontSize: '0.8rem',
        //     gap: '0.5rem',


        // }

    }),
    detail: css({
        display: 'flex',
        flexDirection: 'column',
        width: 'inherit',
        fontSize: '1rem',
        gap: '0.2rem',
        fontStyle: 'italic',
        'span:nth-of-type(1)': {
            fontStyle: 'normal',
            fontSize: '1.2rem'
        }
    }),
    copyRight: css({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
        fontSize: '0.65rem',
        padding: '1rem 0',
        backgroundColor: 'primary.main',
        color: '#fff'
    }),
    noneMobile: css({
        '@media (max-width: 700px)': {
            display: 'none'
        }
    })
};
