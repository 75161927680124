import { FC, useState, FormEventHandler } from 'react'
import { Course } from '../../../types/course'
import { AsyncSelectSearch } from '../../../UI/AsyncSelectSearch'
import { User } from '../../../types/user'
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import {
  useAddPrivateCourseUserMutation,
  useGetPrivateCourseUsersQuery,
  useRemovePrivateCourseUserMutation,
} from '../../../store/coursesApi'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'

const getErrMsg = buildGetErrMsg({
  ER_DUP_ENTRY: 'This user already has private access to this course'
})

interface PrivateCourseUsersProps {
  course: Course
}
// --comp------------------------------------------------------------------------
// --------------------------------------------------------------------------
const PrivateCourseUsers: FC<PrivateCourseUsersProps> = ({ course }) => {
  const [newUser, setNewUser] = useState<User | null>(null)
  const [removingIds, setRemovingIds] = useState<string[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const { data: users } = useGetPrivateCourseUsersQuery(course.id)

  const [addPrivateCourseUser, { isLoading: isAdding }] = useAddPrivateCourseUserMutation()
  const [removePrivateCourseUser] = useRemovePrivateCourseUserMutation()

  const addUser: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    if (!newUser) {
      enqueueSnackbar({ message: 'Please select a user', variant: 'error' })
      return
    }

    const result = await addPrivateCourseUser({ courseId: course.id, userId: newUser.id })

    if ('data' in result) {
      setNewUser(null)
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  }

  const removeUser = async (userId: string) => {
    setRemovingIds((prev) => [...prev, userId])

    const result = await removePrivateCourseUser({ courseId: course.id, userId })

    if ('data' in result) {
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
    
    setRemovingIds((prev) => prev.filter((prevId) => prevId !== userId))
  }

  if (!users) return <p>Loading...</p>

  return (
    <div>
      <h2 style={{ marginBottom: 16 }}>Private Course Users</h2>

      <form onSubmit={addUser} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
        <AsyncSelectSearch
          style={{ flexGrow: 1, marginRight: 16 }}
          value={newUser}
          onChange={(newValue) => {
            if (isAdding) return
            setNewUser(newValue)
          }}
          requestPathname="/users"
          mainInputParam="email"
          noOptionsMessage="No users match the search"
          processOption={(user: User) => ({
            label: `${user.email} - ${user.name} ${user.lastName}`,
            value: user.id,
          })}
          placeholder="Search User"
        />

        <Button type="submit" variant="contained" style={{ flexShrink: 0 }} disabled={isAdding}>
          <AddIcon />
        </Button>
      </form>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="center" style={{ width: 80 }}>
                Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {user.name} {user.lastName}
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell align="center" style={{ width: 80 }}>
                  <IconButton
                    color="error"
                    disabled={removingIds.includes(user.id)}
                    onClick={() => removeUser(user.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ width: '100%', height: 230 }} />
    </div>
  )
}

export default PrivateCourseUsers
