import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { axiosInstance } from '../../utils/axiosInstance'
import { useTranslation } from 'react-i18next'

const AuthActivate = () => {
  const { activationToken } = useParams()
  const [error, setError] = useState<'TOKEN_EXPIRED' | 'NETWORK_ERROR'>()

  const { t } = useTranslation('Signup_login')

  useEffect(() => {
    ;(async () => {
      try {
        const {
          data: { token },
        } = await axiosInstance.post('/auth/activate', { activationToken })

        window.localStorage.setItem('token', token)
        window.location.href = '/'
      } catch (e: any) {
        if (e?.response?.data?.errCode === 'TOKEN_EXPIRED') {
          setError('TOKEN_EXPIRED')
        } else {
          setError('NETWORK_ERROR')
        }
      }
    })()
  }, [])

  const errors = {
    TOKEN_EXPIRED: (
      <>
      {t('The_token_is_expired_you_can_create_a_new_one')}{' '}
        <Link to="/signup">{t('Here')}</Link>
      </>
    ),
    NETWORK_ERROR: <>{t('Please_check_your_internet_connection')}</>,
  }

  return (
    <div style={{ textAlign: 'center', paddingTop: 24 }}>
      {error ? <div>{errors[error]}</div> : <p>{t('Activating_your_account...')}</p>}
    </div>
  )
}

export default AuthActivate
