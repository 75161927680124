import { FC, Fragment } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Container } from '@mui/material';

interface AccordionPageProps {
  allData: { title: string; description: string, disabled: boolean }[];
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
  disabled?: boolean;
}

const AccordionPage: FC<AccordionPageProps> = ({ allData, color, disabled }) => {
  return (
    <Container style={{textAlign: 'center'}}>
      {allData.map((data, index) => (
        <Accordion key={index} disabled={data.disabled}>
          <AccordionSummary style={{direction:'rtl'}}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{data.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {data.description.split('\n').map((line, lineIndex) => {
                if (line.includes('http')) {
                  return (
                    <Fragment key={lineIndex}>
                      <Button
                        href={line}
                        variant='contained'
                        color={color || 'primary'}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        أفتح الوحدات الدراسية
                      </Button>
                      <br />
                    </Fragment>
                  );
                }
                return (
                  <Fragment key={lineIndex}>
                    {line}
                    <br />
                  </Fragment>
                );
              })}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default AccordionPage;
