import React from 'react';
import html2canvas from 'html2canvas';
import { useSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

interface ScreenshotButtonProps {
  screenshotRef: React.RefObject<HTMLDivElement>;
}

const ScreenshotButton: React.FC<ScreenshotButtonProps> = ({ screenshotRef }) => {
  const { enqueueSnackbar } = useSnackbar();

  const takeScreenshot = async () => {
    if (screenshotRef.current) {
      // Create a temporary flash effect
      const flash = document.createElement('div');
      flash.style.position = 'fixed';
      flash.style.top = '0';
      flash.style.left = '0';
      flash.style.width = '100%';
      flash.style.height = '100%';
      flash.style.zIndex = '9999';
      flash.style.pointerEvents = 'none';
      flash.style.transition = 'opacity 0.3s ease';
      flash.style.backgroundColor = 'rgba(245, 245, 220, 1)';

      document.body.appendChild(flash);

      // Trigger the flash effect
      setTimeout(() => {
        flash.style.opacity = '0';
      }, 100);

      // Remove the flash effect after animation
      setTimeout(() => {
        document.body.removeChild(flash);
      }, 400);

      // Create a temporary container for the text
      const container = document.createElement('div');
      container.style.position = 'absolute';
      container.style.width = '70%';
      container.style.height = '70px'; 
      container.style.backgroundColor = 'rgba(67, 160, 71, 0.5)'; 
      container.style.color = 'whitesmoke';
      container.style.padding = '10px';
      container.style.boxSizing = 'border-box';
      container.style.borderRadius = '0.5rem';
      container.style.textAlign = 'left';
      container.style.bottom = '0';
      container.style.left = '0';

      // Add text to the container
      const text = document.createElement('div');
      text.innerText = 'www.bgi-edu.com منصة فوتون';
      container.appendChild(text);

      // Adjust the height of the screenshotRef to accommodate the container
      screenshotRef.current.style.paddingBottom = '80px'; // Increase the padding bottom to make space

      // Temporarily clone the element to ensure it captures all content
      const clone = screenshotRef.current.cloneNode(true) as HTMLDivElement;
      clone.style.position = 'absolute';
      clone.style.top = '0';
      clone.style.left = '0';
      clone.style.width = `${screenshotRef.current.scrollWidth}px`;
      clone.style.height = `${screenshotRef.current.scrollHeight + 70}px`; // Increased height for the text container
      clone.style.overflow = 'visible';
      clone.appendChild(container); // Append the container to the clone

      document.body.appendChild(clone);

      // Capture the screenshot
      const canvas = await html2canvas(clone, {
        useCORS: true,
        allowTaint: false,
        logging: true,
        scale: 1
      });

      // Remove the clone and reset padding
      document.body.removeChild(clone);
      screenshotRef.current.style.paddingBottom = '0'; 

      return canvas;
    }
  };

  const handleCopyScreenshot = async () => {
    const canvas = await takeScreenshot();
    if (canvas) {
      canvas.toBlob((blob) => {
        if (blob) {
          const item = new ClipboardItem({ 'image/png': blob });
          navigator.clipboard.write([item]).then(
            () => {
              enqueueSnackbar('You got a copy of the slide', { variant: 'success' });
            },
            (error) => {
              console.error('Could not copy screenshot to clipboard', error);
            }
          );
        }
      });
    }
  };

  const handleShareScreenshot = async () => {
    const canvas = await takeScreenshot();
    if (canvas) {
      canvas.toBlob(async (blob) => {
        if (blob) {
          const item = new ClipboardItem({ 'image/png': blob });
          await navigator.clipboard.write([item]);
          enqueueSnackbar('You got a copy of the slide', { variant: 'success' });

          // Use the Web Share API to share the screenshot
          const file = new File([blob], 'screenshot.png', { type: 'image/png' });
          if (navigator.share) {
            navigator.share({
              title: 'Screenshot',
              text: 'Check out this screenshot!',
              files: [file],
            }).then(() => {
              enqueueSnackbar('Screenshot shared successfully', { variant: 'success' });
            }).catch((error) => {
              console.error('Could not share screenshot', error);
              enqueueSnackbar('Could not share screenshot', { variant: 'error' });
            });
          } else {
            enqueueSnackbar('Sharing not supported on this browser', { variant: 'warning' });
          }
        }
      });
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      <IconButton onClick={handleCopyScreenshot} style={{color: 'whitesmoke'}}>
        <ContentCopyIcon />
      </IconButton>
      <IconButton onClick={handleShareScreenshot} style={{color: 'whitesmoke'}}>
        <ShareIcon />
      </IconButton>
    </Stack>
  );
};

export default ScreenshotButton;































// import React from 'react';
// import html2canvas from 'html2canvas';
// import { useSnackbar } from 'notistack';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import IconButton from '@mui/material/IconButton';

// interface ScreenshotButtonProps {
//   screenshotRef: React.RefObject<HTMLDivElement>;
// }

// const ScreenshotButton: React.FC<ScreenshotButtonProps> = ({ screenshotRef }) => {
//   const { enqueueSnackbar } = useSnackbar();

//   const handleTakeScreenshot = async () => {
//     if (screenshotRef.current) {
//       // Create a temporary container for the text
//       const container = document.createElement('div');
//       container.style.position = 'absolute';
//       container.style.width = '100%';
//       container.style.height = '70px'; // Increased height for more space
//       container.style.backgroundColor = 'rgba(67, 160, 71, 0.7)';
//       container.style.color = 'white';
//       container.style.padding = '10px';
//       container.style.boxSizing = 'border-box';
//       container.style.textAlign = 'left';
//       container.style.bottom = '0';
//       container.style.left = '0';

//       // Add text to the container
//       const text = document.createElement('div');
//       text.innerText = 'www.bgi-edu.com منصة فوتون';
//       container.appendChild(text);

//       // Adjust the height of the screenshotRef to accommodate the container
//       screenshotRef.current.style.paddingBottom = '80px'; // Increased the padding bottom to make space

//       // Temporarily clone the element to ensure it captures all content
//       const clone = screenshotRef.current.cloneNode(true) as HTMLDivElement;
//       clone.style.position = 'absolute';
//       clone.style.top = '0';
//       clone.style.left = '0';
//       clone.style.width = `${screenshotRef.current.scrollWidth}px`;
//       clone.style.height = `${screenshotRef.current.scrollHeight + 70}px`; // Increased height for the text container
//       clone.style.overflow = 'visible';
//       clone.appendChild(container); // Append the container to the clone

//       document.body.appendChild(clone);

//       // Capture the screenshot
//       const canvas = await html2canvas(clone, {
//         useCORS: true,
//         allowTaint: false,
//         logging: true,
//         scale: 1
//       });

//       // Remove the clone and reset padding
//       document.body.removeChild(clone);
//       screenshotRef.current.style.paddingBottom = '0'; // Reset padding

//       canvas.toBlob((blob) => {
//         if (blob) {
//           const item = new ClipboardItem({ 'image/png': blob });
//           navigator.clipboard.write([item]).then(
//             () => {
//               enqueueSnackbar('You got a copy of the slide', { variant: 'success' });
//             },
//             (error) => {
//               console.error('Could not copy screenshot to clipboard', error);
//             }
//           );
//         }
//       });
//     }
//   };

//   return (
//     <IconButton onClick={handleTakeScreenshot} style={{color: 'whitesmoke'}}>
//       <ContentCopyIcon />
//     </IconButton>
//   );
// };

// export default ScreenshotButton;





































// import React from 'react';
// import html2canvas from 'html2canvas';
// import { useSnackbar } from 'notistack';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import IconButton from '@mui/material/IconButton';

// interface ScreenshotButtonProps {
//   screenshotRef: React.RefObject<HTMLDivElement>;
// }

// const ScreenshotButton: React.FC<ScreenshotButtonProps> = ({ screenshotRef }) => {
//   const { enqueueSnackbar } = useSnackbar();

//   const handleTakeScreenshot = async () => {
//     if (screenshotRef.current) {
//       screenshotRef.current.style.position = 'relative';

//       const text = document.createElement('div');
//       text.innerText = 'Photon';
//       text.style.position = 'absolute';
//       text.style.bottom = '10px';
//       text.style.right = '10px';
//       // text.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
//       // text.style.color = 'white';
//       text.style.padding = '5px';
//       text.style.borderRadius = '5px';
//       text.style.zIndex = '1000';  // Ensure the text is on top

//       screenshotRef.current.appendChild(text);

//       setTimeout(() => {
//         if (screenshotRef.current) {
//           screenshotRef.current.removeChild(text);
//         }
//       }, 1000);



//       const canvas = await html2canvas(screenshotRef.current, {
//         useCORS: true,
//         allowTaint: false,
//         scrollX: -window.scrollX,  // Ensure the entire element is captured
//         scrollY: -window.scrollY  
//       });


//       const context = canvas.getContext('2d');
//       const imgData = context?.getImageData(0, 0, screenshotRef.current.scrollWidth, screenshotRef.current.scrollHeight);
//       const newCanvas = document.createElement('canvas');
//       newCanvas.width = screenshotRef.current.scrollWidth;
//       newCanvas.height = screenshotRef.current.scrollHeight;
//       newCanvas.getContext('2d')?.putImageData(imgData!, 0, 0);


//       canvas.toBlob((blob) => {
//         if (blob) {
//           const item = new ClipboardItem({ 'image/png': blob });
//           navigator.clipboard.write([item]).then(
//             () => {
//               enqueueSnackbar('You got a copy of the slide', { variant: 'success' });
//             },
//             (error) => {
//               console.error('Could not copy screenshot to clipboard', error);
//             }
//           );
//         }
//       });
//     }
//   };

//   return (
//     <IconButton onClick={handleTakeScreenshot} style={{color: 'whitesmoke'}}>
//       <ContentCopyIcon />
//     </IconButton>
//   );
// };

// export default ScreenshotButton;
