import { useSnackbar } from 'notistack'
import { GetErrMsg } from '../utils/buildGetErrMsg'
import { useCallback } from 'react'

const useSnackResErr = (getErrMsg: GetErrMsg) => {
  const { enqueueSnackbar } = useSnackbar()

  const snackResErr = useCallback((e: any) => {
    const errMsg = getErrMsg(
      e.response
        ? { status: e.response.status, data: e.response.data }
        : new Error('Unknown')
    )

    enqueueSnackbar({ message: errMsg, variant: 'error' })
  }, [])

  return snackResErr
}

export default useSnackResErr
