import { FormProvider, useForm } from 'react-hook-form';
import { AdFv } from '../../../types/ad';
import { Container } from '@mui/material';
import { AdForm } from './AdForm';

export const CreateAd = () => {
  const formContext = useForm<AdFv>({
    defaultValues: {
      isDeactivated: false,
      courses: []
    },
  });

  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <AdForm />
      </FormProvider>
    </Container>
  );
};
