
import { Link } from 'react-router-dom'
import { BgiLink } from '../UI/BgiLink'

export const smld = [
  {
    title: 'Youtube',
    description:
      `    للتعرف على منصة فوتون وكيفية التعامل معها يمكنكم زيارة قناتنا على اليوتيوب
https://www.youtube.com/watch?v=-U1zFBa772w
...`,
    link: [
      { url: 'https://www.youtube.com/watch?v=-U1zFBa772w', text: 'رابط اليوتيوب' }
    ]
  },

  {
    title: 'Telegram & Whatsapp   & ورابط المسابقة الرمضانية للفيزياء',
    description: `
    تجدون على المجموعات والواتس والتلجرام لطلاب الصف الثالث الثانوي ـ  كل خميس في رمضان في تمام الساعة العاشرة مساءً روابط مسابقة فوتون الرمضانية مع 3 جوائز نقدية 

    ترسل صورة من النتيجة على مجموعة الواتس الخاصة بالصف الثالث الثانوي

    
    للحصول على التحديثات والصور التعليمية،
    
    يمكنكم الإنظام إلينا في عبر مجموعة التلجرام والواتس  ...
 ــ لطلاب الصف التاسع
https://t.me/+f3vhNb5X9fcxNGY0
https://chat.whatsapp.com/B5TeLUyIlYlI0QD3UQbf2c


 ــ لطلاب الصف الثالث الثانوي
 https://t.me/photonCls12Ye
https://chat.whatsapp.com/FHIJHjOsbvjIJc8aahyMzg

...

   يمكنكم الإنظام إلى مجموعة الواتس أب لمعرفة أخر التحديثات في التطبيق، وكذلك لدينا معلم متخصص للمناقشة والرد على الاستفسارات 

`,


    links: [
      { url: 'https://t.me/+f3vhNb5X9fcxNGY0', text: 'رابط التلجرام للصف التاسع' },
      { url: 'https://t.me/photonCls12Ye', text: 'رابط التلجرام للصف الثالث الثانوي' },
      { url: 'https://chat.whatsapp.com/B5TeLUyIlYlI0QD3UQbf2c', text: 'رابط مجموعة الواتس للصف التاسع' },
      { url: 'https://chat.whatsapp.com/FHIJHjOsbvjIJc8aahyMzg', text: 'رابط مجموعة الواتس للصف الثالث الثانوي' },
    ]
  },

]