import React, { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';

interface BgiButtonProps extends ButtonProps {
  isExam?: boolean;
  isShowResult?: boolean;
  isShowMoreInfo?: boolean;
  isCurExam?: boolean
}

const BgiButton: FC<BgiButtonProps> =   ({ isExam, isShowResult, isShowMoreInfo, isCurExam, ...rest }) => {
    if (isExam || isShowResult || isShowMoreInfo || isCurExam) {
      return null; 
    }

    return <Button {...rest} />;
  }


export default BgiButton;
