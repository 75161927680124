const getInstalledCourses = (): { id: string; version: number; }[] => {
  try {
    let data: any = window.localStorage.getItem('installedCourses')

    if (!data) return []

    data = JSON.parse(data)

    if (!(data instanceof Array)) return []

    const result: { id: string; version: number; }[] = []

    data.forEach(arrItem => {
      if (typeof arrItem === 'object') {
        if (
            typeof arrItem.id === 'string' && /[0-9]+/.test(arrItem.id) &&
            typeof arrItem.version === 'number' && !Number.isNaN(arrItem.version)
        ) {
          result.push({ id: arrItem.id, version: arrItem.version })
        }
      }
    })

    return result
  } catch {
    return []
  }
}

export default getInstalledCourses
