import genId from "../utils/genId";

export interface Topic {
  id: string;
  title: string;
}

export const topics: Topic[] = [
  {
    id: "1",
    title: "مكونات الذرة",
  },
  {
    id: "2",
    title: "التوزيع الإلكتروني",
  },
  {
    id: "3",
    title: "السالبية الكهربائية",
  },
  {
    id: "4",
    title: "الروابط",
  },
];


export interface Slide {
  id: string;
  questioners?: string;
  quNumber: string;
  point: number;
  topicId: string;
  question: string;
  img: string;
  shortWrite?: string;    // I added this **f
  lognWrite?: string;    // I added this **f
  video?: string;
  answerStart: string;
  answers: {
    id: string;
    text: string;
  }[];
  correctAnswer: string[];
  showResult?: boolean;
  inputCorrectAnswer?: string;
  moreInfo?: string;
}
// كنت بحاجة إلى خصائصهم من التعريف السابق وإعادة تعريفهم
type InternalSlide = Omit<Slide, "answers" | "correctAnswer"> & {
  answers: string[];
  correctAnswer: number[];
};

const processSlidesData = (internalSides: InternalSlide[]) => {
  return internalSides.map((slide) => {
    const correctAnswer: string[] = [];

    const answers = slide.answers.map((answer, index) => {
      const answerId = genId();

      if (slide.correctAnswer.includes(index)) {
        correctAnswer.push(answerId);
      }

      return {
        id: answerId,
        text: answer,
      };
    });

    return {
      ...slide,
      answers,
      correctAnswer,
    };
  });
};

const internalSides: InternalSlide[] = [
  
  {
    id: "3024",
    quNumber: "",
    questioners:
     "يتم أجراء تطوير في الخدمات التعليمية" ,
    point: 0,
    topicId: "",
    question: "",
    img: "",
    video: "",
    answerStart: "",
    answers: [],
    correctAnswer: [],
  },

  
  {
    id: "3024",
    quNumber: "3",
    point: 0,
    topicId: "1",
    question: "",
    img: "/img/A2.png",
    video: "",
    answerStart: "",
    answers: [],
    correctAnswer: [],
    inputCorrectAnswer: '50',
    shortWrite: 'km',
    moreInfo: '# العنوان\n\n\nThis is **text** \n\n\n[open pdf](https://hyperskill.org/certificates/0a5a7727-29fb-4d82-97ec-2e3c37a5caa6.pdf)',
    lognWrite: "",

  },
  {
    id: "2",
    quNumber: "4",
    point: 1,
    topicId: "1",
    question: "هل يمكن تقسيم الذرة؟",
    img: "",
    answerStart: "",
    answers: ["نعم", "لا"],
    correctAnswer: [0],
    moreInfo: 'Slide 2 info'
  },


  



  // ------------------------------------------------
  {
    id: "377b88",
    quNumber: "0",
    point: 0,
    topicId: "",
    question: "",
    img: "/img/A1.png",
    answerStart: "",
    answers: [],
    correctAnswer: [],
  },
  {
    id: "3778q8",
    quNumber: "0",
    point: 0,
    topicId: "",
    question: "",
    img: "/img/A2.png",
    answerStart: "",
    answers: [],
    correctAnswer: [],
  },
  {
    id: "3w7788",
    quNumber: "0",
    point: 0,
    topicId: "",
    question: "",
    img: "/img/A3.png",
    answerStart: "",
    answers: [],
    correctAnswer: [],
  },
  {
    id: "3778r8",
    quNumber: "0",
    point: 0,
    topicId: "",
    question: "",
    img: "/img/A4.png",
    answerStart: "",
    answers: [],
    correctAnswer: [],
  },
  {
    id: "37t788",
    quNumber: "0",
    point: 0,
    topicId: "",
    question: "",
    img: "/img/A5.png",
    answerStart: "",
    answers: [],
    correctAnswer: [],
  },
// --------------------------------------------------------------------------
  {
    id: "06",
    quNumber: "",
    questioners: "",
    point: 0,
    topicId: "",
    question: "",
    img: "",
    video: "https://www.youtube.com/embed/A3D6CofXFjM?rel=0",
    answerStart: "",
    answers: [],
    correctAnswer: [],
  },

  {
    id: "1",
    quNumber: "5",
    point: 2,
    topicId: "1",
    question: "ما هي مكونات الذرة؟",
    img: "",
    answerStart: "",
    answers: ["بروتونات.", "نيترونات.", "إلكترونات."],
    correctAnswer: [0, 1, 2],
  },

  {
    id: "2",
    quNumber: "4",
    point: 1,
    topicId: "1",
    question: "هل يمكن تقسيم الذرة؟",
    img: "",
    answerStart: "",
    answers: ["نعم", "لا"],
    correctAnswer: [0],
  },

  {
    id: "3",
    quNumber: "12",
    point: 2,
    topicId: "1",
    question: "هل تعبر هذه الصورة عن تركيب مبسط للذرة؟",
    img: "/img/D3RChR1051Ar.png",
    answerStart: "",
    answers: ["نعم", "لا"],
    correctAnswer: [0],
  },

  {
    id: "4",
    quNumber: "15",
    point: 2,
    topicId: "1",
    question: "هل هناك خلل ما في هذ الصورة؟",
    img: "/img/D3RChR1061.png",
    answerStart: "",
    answers: [
      "نعم، لأنه لا توجد في الطبيعة ذرة تحتوي نواتها على نيترون فقط.",
      "لا، لأن هذه تمثل ذرة من نواه وإلكترون.",
    ],
    correctAnswer: [0],
  },

  {
    id: "5",
    quNumber: "20",
    point: 1,
    topicId: "1",
    question: "مما تتكون النواة ؟",
    img: "",
    answerStart: "تتكون النواة من:",
    answers: ["البروتونات.", "النيترونات.", "الإلكترونات."],
    correctAnswer: [0, 1],
  },

  {
    id: "6",
    quNumber: "24",
    point: 4,
    topicId: "1",
    question: "بما أن الذرة تحتوي على جسيمات ذات شحنات كهربائية مختلفة",
    img: "",
    answerStart: " فإن شحنة النواة هي:",
    answers: ["موجبة.", "متعادلة.", "سالبة."],
    correctAnswer: [0],
  },

  {
    id: "7",
    quNumber: "27",
    point: 5,
    topicId: "1",
    question: "ما الذي يجب الانتباه إليه عندما نشاهد ذرة ذات نواة واحدة؟",
    img: "",
    answerStart: "أن هذه النواه عبارة عن :",
    answers: ["بروتون فقط.", "نيوترون فقط."],
    correctAnswer: [0],
  },

  {
    id: "8",
    quNumber: "33",
    point: 3,
    topicId: "1",
    question: "كيف أعرف أن الذرة متزنة كهربائياً؟",
    img: "",
    answerStart: "من خلال: ",
    answers: [
      "عدد البروتونات  تساوي عدد الإلكترونات.",
      "إجمالي الشحنة الموجبة المساوية للشحنة السالبة.",
      "عدد البروتونات تساوي عدد النيترونات.",
    ],
    correctAnswer: [0, 1],
  },

  {
    id: "9",
    quNumber: "35",
    point: 2,
    topicId: "1",
    question: "معظم كتلة الذرة تتركز في : ",
    img: "",
    answerStart: "",
    answers: [
      "النواة.",
      "البروتونات والإلكترونات.",
      "النيترونات والإلكترونات.",
    ],
    correctAnswer: [0],
  },

  {
    id: "10",
    quNumber: "39",
    point: 3,
    topicId: "1",
    question: "هل من الممكن أن نجد في الطبيعة ذرة تحتوي على بروتون فقط؟",
    img: "",
    answerStart: "",
    answers: ["نعم", "لا"],
    correctAnswer: [0],
  },

  {
    id: "11",
    quNumber: "",
    point: 0,
    topicId: "1",
    question: "",
    img: "",
    video: "https://www.youtube.com/embed/HqYr7CJGh34?rel=0",
    answerStart: "",
    answers: [],
    correctAnswer: [],
  },

  {
    id: "12",
    quNumber: "50",
    point: 2,
    topicId: "1",
    question: "ما هو البروتون؟",
    img: "",
    answerStart: "",
    answers: [
      "جسيم نووي موجب الشحنة.",
      "جسيم نووي سالب الشحنة.",
      "جسم نووي متعادل الشحنة.",
    ],
    correctAnswer: [0],
  },

  {
    id: "13",
    quNumber: "53",
    point: 1,
    topicId: "1",
    question: "من خلال الصورة أي منهم تتوقع أن يكون الإلكترون.",
    img: "/img/D3RChR1013.png",
    answerStart: "",
    answers: ["رقم 1.", "رقم2."],
    correctAnswer: [0],
  },

  {
    id: "14",
    quNumber: "",
    point: 0,
    topicId: "1",
    question: "",
    img: "",
    video: "https://www.youtube.com/embed/CHZqCchQPAE?rel=0",
    answerStart: "",
    answers: [],
    correctAnswer: [],
  },

  {
    id: "15",
    quNumber: "232",
    point: 4,
    topicId: "2",
    question:
      "كيف يكون تصرف ذرة تحتوي على 7 إلكترونات في المستوى الثاني لمستوى الطاقة الخارجي لتصل للاستقرار؟",
    img: "",
    answerStart: "",
    answers: [
      "تميل إلى اكتساب إلكترون.",
      "تميل إلى فقدان إلكترون.",
      "تميل إلى فقدان 7 إلكترونات.",
    ],
    correctAnswer: [0],
  },

  {
    id: "16",
    quNumber: "250",
    point: 3,
    topicId: "2",
    question:
      "عندما يكون عدد الإلكترونات في المستوى الثاني 8 إلكترونات، ما نطلق على الذرة؟",
    img: "",
    answerStart: "",
    answers: [" ذرة خاملة.", "ذرة مستقرة.", "ذرة مثارة.", "ذرة مشعة."],
    correctAnswer: [0, 1],
  },

  {
    id: "17",
    quNumber: "258",
    point: 3,
    topicId: "2",
    question: "ماهي العراقيل التي تمنع الذرة المستقرة من التفاعل؟",
    img: "",
    answerStart: "",
    answers: [
      "امتلاء كل مداراتها بالإلكترونات.",
      "امتلاء بعض مداراتها بالإلكترونات.",
      "مدارتها فارغة.",
    ],
    correctAnswer: [0],
  },

  {
    id: "18",
    quNumber: "270",
    point: 2,
    topicId: "2",
    question:
      "هل يشير هذا التوزيع الإلكتروني إلى ذرة غير مستقرة قابلة للتفاعل بسهولة؟",
    img: "/img/D2RChR10250R.png",
    answerStart: "",
    answers: ["نعم.", "لا."],
    correctAnswer: [0],
  },

  {
    id: "19",
    quNumber: "",
    point: 0,
    topicId: "3",
    question: "",
    img: "",
    video: "https://www.youtube.com/embed/dm71RQGrHts?rel=0",
    answerStart: "",
    answers: [],
    correctAnswer: [],
  },

  {
    id: "20",
    quNumber: "312",
    point: 5,
    topicId: "3",
    question: "عندما نتحدث عن السالبية الكهربائية فإننا نقصد:",
    img: "",
    answerStart: "",
    answers: [
      "الذرات التي تكون مركبات.",
      "الذرات التي تؤثر شحنتها على ذرات أخرى.",
      "الذرات المنفردة.",
    ],
    correctAnswer: [0, 1],
  },

  {
    id: "21",
    quNumber: "320",
    point: 4,
    topicId: "3",
    question:
      "تم تحديد ساليبية كهربائية لكل عنصر في الجدول الدوري ، كيف تم قياسها؟",
    img: "",
    answerStart: "يتم قياسها من خلال:",
    answers: ["اتحاد الذرات مع ذرات أخر.", "عبر قياس شحنها المنفردة."],
    correctAnswer: [0],
  },

  {
    id: "22",
    quNumber: "331",
    point: 3,
    topicId: "3",
    question: "ما الذي يحدث عندما تتعامد السالبية الكهربائية بين ذرتين؟",
    img: "",
    answerStart: "تعمل على: ",
    answers: ["تلاشي كلاً منهم قوة الأخرى.", "تضاعف قوة السالبية الكهربائية."],
    correctAnswer: [0],
  },

  {
    id: "23",
    quNumber: "340",
    point: 4,
    topicId: "3",
    question:
      "عندما تكون الذرات المكونة للمركب غير متعامدة فإنه المركب يتكون له ساليبية كهربائية؟",
    img: "",
    answerStart: "",
    answers: ["نعم.", "لا."],
    correctAnswer: [0],
  },

  {
    id: "24",
    quNumber: "344",
    point: 3,
    topicId: "3",
    question: "هل لك أن تخمن كم مقدار السالبية الكهربائية لهذه الذرة؟ ",
    img: "/img/D2RChR10251R.png",
    answerStart: "",
    answers: [
      "لا توجد، لأن الذرة المنفردة ليس لها ساليبية كهربائية.",
      "عالية.",
      "منخفضة.",
    ],
    correctAnswer: [0],
  },

  {
    id: "25",
    quNumber: "350",
    point: 3,
    topicId: "3",
    question:
      "تسعى ذرتي الفلور للاتحاد مع بعضهم ، ومقدار السالبية لكل منهم هو 4، فكم سيكون مجموع السالبية الكهربائية لهم؟",
    img: "/img/D2RChR10252R.png",
    answerStart: "",
    answers: ["صفر؛ لانهم متعامدين.", "8"],
    correctAnswer: [0],
  },

  {
    id: "26",
    quNumber: "352",
    point: 4,
    topicId: "3",
    question: "هل يتكون لمركب الماء ساليبية كهربائية. ",
    img: "/img/D2RChR10253R.png",
    answerStart: "",
    answers: [
      "نعم ، لان ذرات الهيدروجين ليست متعامدة مع الأكسجين.",
      "لا: لان ذرات الهيدروجين متعامدة مع بعضها.",
    ],
    correctAnswer: [0, 1],
  },

  {
    id: "27",
    quNumber: "355",
    point: 5,
    topicId: "3",
    question: "ما سبب انحناء الروابط بزاوية عند تكوين المركبات؟",
    img: "",
    answerStart: "",
    answers: [
      "علاقة الزوايا بعدد الروابط.",
      "وجود إلكترونات حرة بجوار الروابط.",
      "اقتراب المدارات من النواة.",
    ],
    correctAnswer: [0, 1],
  },

  {
    id: "28",
    quNumber: "358",
    point: 3,
    topicId: "3",
    question: "لاحظت أن هناك أزواج من الإلكترونات الحرة، في أي موقع تتواجد؟",
    img: "",
    answerStart: "",
    answers: [
      "في الغلاف الرئيسي الأخير للذرة.",
      "في المدارات الداخلية.",
      "في المدارات الأقرب للنواة.",
    ],
    correctAnswer: [0],
  },

  {
    id: "29",
    quNumber: "363",
    point: 4,
    topicId: "3",
    question: "ما تأثير أزوج الإلكترونات الحرة على الروابط الكيميائية ",
    img: "",
    answerStart: "",
    answers: [
      "تعمل على ثني الرابطة.",
      "تعمل على تكوين روابط مع ذرات أخرى.",
      "تعمل على تقوية الرابطة.",
    ],
    correctAnswer: [0, 1],
  },

  {
    id: "30",
    quNumber: "",
    point: 0,
    topicId: "4",
    question: "",
    img: "",
    video: "https://www.youtube.com/embed/BeB3Ufu34Cs?rel=0",
    answerStart: "",
    answers: [],
    correctAnswer: [],
  },

//   {
//     id: "31",
//     quNumber: "720",
//     point: 5,
//     topicId: "4",
//     question: "ماذا يقصد بالدوران الحر؟",
//     img: "",
//     answerStart: "",
//     answers: [
//       "قدرة الذرة المتصلة بالرابطة الأحادية الدوران بحرية حول الرابطة نفسها.",
//       "تمكن إلكترونات الرابطة من الأنتقال من ذرة إلى أخرى بصورة دوارنية.",
//     ],
//     correctAnswer: [0],
//   },

//   {
//     id: "32",
//     quNumber: "725",
//     point: 3,
//     topicId: "4",
//     question: "من مميزات الروابط الأحادية :",
//     img: "",
//     answerStart: "",
//     answers: [
//       "لديها دوران حرة حول نفسها. ",
//       "سهولة في كسر رابطتها مقارنة مع الذرات التي لديها روابط ثنائية.",
//     ],
//     correctAnswer: [0, 1],
//   },

//   {
//     id: "33",
//     quNumber: "729",
//     point: 4,
//     topicId: "4",
//     question:
//       "أحد ذرات المركب تتحرك بصورة دائمة ما الذي يجب فعله لإيقاف دورانها؟",
//     img: "/img/D2RChR10254R.png",
//     answerStart: "",
//     answers: ["عمل رابطة أخرى لتصبح الرابطة ثنائية.", "زيادة درجة الحرارة."],
//     correctAnswer: [0],
//   },
  
//   // الجزء الخاص باطالب
//   // {
//   //   id: "34",
//   //   quNumber: "",
//   //   questioners:
//   //     "https://docs.google.com/forms/d/e/1FAIpQLSe714BxTbk98TXifftqSDF4UNb1KUXisV8SQox9oqLdfZbAXg/viewform?embedded=true",
//   //   point: 0,
//   //   topicId: "",
//   //   question: "",
//   //   img: "",
//   //   video: "",
//   //   answerStart: "",
//   //   answers: [],
//   //   correctAnswer: [],
//   // },

//   {
//     id: "3024",
//     quNumber: "",
//     questioners:
//     "https://docs.google.com/forms/d/e/1FAIpQLSfSHB83oM-PaMj5P2T4bzamkGQ-mbVRhgO1tai2lMz38c7hWA/viewform?embedded=true",
//     point: 0,
//     topicId: "",
//     question: "",
//     img: "",
//     video: "",
//     answerStart: "",
//     answers: [],
//     correctAnswer: [],
//   },
//   {
//     id: "3024",
//     quNumber: "",
//     questioners:
//     "https://docs.google.com/forms/d/e/1FAIpQLSddXF4NVFcgrC5Gh3q1Fc-AwRuE7wdrfvx9aHAMTbL6nNnRzA/viewform?embedded=true",
//     point: 0,
//     topicId: "",
//     question: "",
//     img: "",
//     video: "",
//     answerStart: "",
//     answers: [],
//     correctAnswer: [],
//   },



  {
    id: "",
    quNumber: "",
    point: 0,
    topicId: "",
    question: "",
    img: "",
    questioners: "",
    answerStart: "",
    answers: [],
    correctAnswer: [],
    showResult: true,
  },
];

const slidesData = processSlidesData(internalSides);

export default slidesData;
