import React, { FC } from 'react'
interface IframeYoutubeProps {
  YoutubeSrc: string;
}

const IframeYoutube: FC<IframeYoutubeProps> = ({ YoutubeSrc }) => {
  return (
    <div>
      <iframe
        style={{ display: 'block', margin: '0 auto', height:'20rem', maxWidth: '35rem' }}
        src={`https://www.youtube.com/embed/${YoutubeSrc}?rel=0`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      >
      </iframe>
    </div>
  )
}

export default IframeYoutube
