
import { CreateContact } from '../contact-us/CreateContact'
import Layout from './Layout'

function ContactUs() {
  return (
    <Layout>
      <CreateContact />
    </Layout>
  )
}

export default ContactUs



