import { useForm, FormProvider } from 'react-hook-form'
import UnitFormPage, { UnitForm } from './UnitFormPage'
import { Container } from '@mui/material'

export const UnitCreatePage = () => {
  const unitForm = useForm<UnitForm>({
    defaultValues: {
      unit: {
        summary: '--',
      }
    }
  })

  return (
    <Container maxWidth="md">
      <FormProvider {...unitForm}>
        <UnitFormPage />
      </FormProvider>
    </Container>
  )
}
