/** @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react'
import { SlideView } from '../../../types/SlideView'
import { FlashcardSlide } from '../../../types/slide'
import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL'
import { css } from '@emotion/react'
import MarkdownView from './MarkdownView'
import makeStyles from '../../../utils/makeStyles'
import {determineTextDirection } from '../../../utils/determineTextDirection'
import AdsClickIcon from '@mui/icons-material/AdsClick';


const SlideViewFlashcard: SlideView<FlashcardSlide> = ({ slide, filesHashes }) => {
  const [isFlipped, setIsFlipped] = useState(false)

  const imageUrl = useMemo(() => {
    const imageFileHash = filesHashes.find(
      (fileHash) => fileHash.id === slide.imageFileId
    )?.fileHash

    
    return imageFileHash ? `${FILES_BASE_URL}/${imageFileHash}` : null
  }, [slide.imageFileId, filesHashes])
  
  const handleCardClick = () => {
    setIsFlipped(!isFlipped)
  }
  
  const frontDirection = determineTextDirection(slide.flashcardFront)
  const backDirection = determineTextDirection(slide.flashcardBack)

  // ---ret-------------------------------------------------
  return (
    <div css={styles.card} onClick={handleCardClick}>
      {isFlipped ? (
        <div style={{direction:backDirection }}>
        <MarkdownView value={slide.flashcardBack} />
        </div>
      ) : (
        <div style={{direction:frontDirection }}>
          <AdsClickIcon style={{textAlign: 'left', display: 'block'}}/>
          <img  src={imageUrl ?? ''} alt="" css={styles.image}/>
          <MarkdownView value={slide.flashcardFront} />
        </div>

      )}
    </div>
  )
}

const styles = makeStyles({
  card: css({
    height: '100%',
    cursor: 'pointer',
    padding: '1rem',
    textAlign: 'center',
    userSelect: 'none',
    fontSize: '1.5rem',
    lineHeight: '1.5',
    border: '10px solid #ccc',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    backgroundColor: 'beige',
    }),
    image: css({
      width: '80%',
      height: 'auto',
      marginBottom: '1rem',
    }),
})

export default SlideViewFlashcard
