import { Button, TableCell, TableRow } from '@mui/material'
import React, { FC } from 'react'
import { User } from '../../types/user'
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { useActivateUserMutation, useDeleteUserMutation } from '../../store/pendingUsersApi';

interface PendingUserRowProps {
  pendingUser: User
  index: number
}

const PendingUserRow: FC<PendingUserRowProps> = ({ pendingUser, index }) => {
  
  const [activateUser, { isLoading: isActivating }] = useActivateUserMutation();
  const [deleteUser, {isLoading: isDelete }] = useDeleteUserMutation();

  return (

    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 },
      backgroundColor: index % 2 == 0 ? 'beige' : 'gray.200',
    }}
    >
      <TableCell>{pendingUser.name}</TableCell>
      <TableCell>{pendingUser.lastName}</TableCell>
      <TableCell>{pendingUser.classLevel}</TableCell>
      <TableCell>{pendingUser.email}</TableCell>
      <TableCell>{pendingUser.school} --# {pendingUser.schoolType}</TableCell>
      <TableCell>{pendingUser.city}</TableCell>
      <TableCell>{pendingUser.mobile}</TableCell>
      <TableCell>{pendingUser.whatsapp}</TableCell>
      <TableCell>{pendingUser.country?.name}</TableCell>
      {/* <TableCell>{pendingUser.createdAt}</TableCell> */}
      <TableCell>
        <Button
          type="button"
          variant="contained"
          onClick={() => activateUser(pendingUser.id)}
          disabled={isActivating}
        >
          <DoneIcon />
        </Button>
      </TableCell>
      <TableCell>
        
        <Button
          type="button"
          variant="contained"
          color="error"
          onClick={() => deleteUser(pendingUser.id)}
        >
          <DeleteIcon />
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default PendingUserRow
