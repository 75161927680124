/** @jsxImportSource @emotion/react */
import { useFormContext, useWatch } from 'react-hook-form'
import { CreateContactT, ContactT } from '../../types/contact'
import { BgiTextField } from '../../UI/BgiTextField'
import { Button, Container, Stack } from '@mui/material'
import { buildGetErrMsg } from '../../utils/buildGetErrMsg'
import { useSnackbar } from 'notistack'
import { useCreateContactMutation } from '../../store/contactApi'
import { ResErr } from '../../utils/parseResErr'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import UseDynamicLanguageStyle from '../i18n/UseDynamicLanguageStyle'



const SUCCESS_MSGS = {
  create: (contactId: string) => (
    <p>Course has been created successfully with {' '}</p>
  ),
  update: 'تم إرسال الرسالة بنجاح',
}


const getErrMsg = buildGetErrMsg({
  DUP_COURSE_CODE: 'كود الدورة مكرر. ادخل كود آخر',
  COURSE_IS_REFERENCED: "Please delete course's chapters first",
})

// ---------------------------------------------------------------------
// ---------------------------------------------------------------------
const ContactUsForm = () => {
  const { control, handleSubmit, reset } = useFormContext<ContactT>()
  const contactForm = useFormContext<ContactT>()

  
  const { t } = useTranslation(["ContactUs", "common"])
  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()

  const contactId = useWatch({ control, name: 'id' })
  const isCreateForm = !contactId

  const { enqueueSnackbar } = useSnackbar()

  const [CreateContact, { isLoading: isCreating }] = useCreateContactMutation()

  // ---------------------------------------------------------------------------------

  const onSubmit = async (contact: ContactT) => {
    const { id, ...restPayload } = contact;

    if (!id) {
      try {
        const result = await CreateContact({ ...restPayload });
        if ('data' in result) {
          enqueueSnackbar({
            message: SUCCESS_MSGS.create(result.data.id),
            variant: 'success',
          });
          contactForm.reset();
        } else {
          enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' });
        }
      } catch (error) {
        if (error instanceof Error) {
          const formattedError: ResErr = { message: error.message };
          enqueueSnackbar({ message: getErrMsg(formattedError), variant: 'error' });
        } else {
          enqueueSnackbar({ message: 'An unexpected error occurred', variant: 'error' });
        }
      }
    }
  }



  return (
    <div css={styles.allPage}>
      <h2>{t('We_are_happy_to_communicate_with_you')}</h2>
      <form onSubmit={handleSubmit(onSubmit)} css={styles.ArabicDir}>
      <Stack spacing={3}>
        <BgiTextField
          label={t('Your_Name')}
          name="name"
          required
          fullWidth
          size='small'
          maxLength={128}
        />

        <div css={styles.towInOne}>
          <BgiTextField
            label={t('Job_or_study')}
            name="job"
            size='small'
            maxLength={128}
          />
          <BgiTextField
            label={t('Your_Company_or_organization')}
            name="company"
            size='small'
            maxLength={128}
          />
        </div>

        <div css={styles.towInOne}>
          <BgiTextField
            label={t('Your_Country')}
            name="country"
            fullWidth
            size='small'
            maxLength={64}
          />
          <BgiTextField
            label={t('City')}
            name="city"
            fullWidth
            size='small'
            maxLength={64}
          />
        </div>

        <BgiTextField
          label={t('Title')}
          name="title"
          required
          fullWidth
          size='small'
          maxLength={255}
          placeholder={t('Messages_cannot_be_sent')}
        />
        <BgiTextField
          label={t('Message')}
          name="message"
          required
          fullWidth
          size='small'
          multiline
          minRows={7}
          maxRows={15}
          maxLength={1200}
          placeholder={t('Messages_cannot_be_sent')}
        />

        <div>
          <Button
            variant="contained"
            type="submit"
            disabled={isCreating}
          >
            {t('Send')}
          </Button>

          <Button
            variant="outlined"
            color="error"
            onClick={() => reset()}
            disabled={isCreating}
          >
            {t('common:Cancel')}
          </Button>
        </div>
        </Stack>
      </form>

    </div>
  )
}

export default ContactUsForm


const styles = {
  allPage: css({
    backgroundColor: 'whiteSmoke',
    margin: '4rem 0 20rem 0',
  }),
  towInOne: css({
    display: 'flex'
  }),
  ArabicDir: css({
   direction: 'rtl'
  }),
}