import React, { ReactNode } from 'react'
import Header from './Header'
import Footer from './Footer'

interface ChildrenProp {
  children: ReactNode
}

const Layout: React.FC<ChildrenProp> = ({ children }) => {
  return (
    <div className="layout-container">
      <Header />
      <main className="main-content">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
