import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SpecialExamResultFV, specialExamResult } from '../types/specialExamResult'
import { apiConfig } from './api'
// import { User } from '../types/user'

export const specialExamResultApi = createApi({
  reducerPath: 'specialExamResultApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/special-exam-result`,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['SpecialExamResult'],
  endpoints: (builder) => ({
    getSpecialExamResults: builder.query<specialExamResult[], string>({
      query: () => '',
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'SpecialExamResult' as const, id })), 'SpecialExamResult']
          : ['SpecialExamResult'],
    }),

    getSpecialExamResult: builder.query<specialExamResult | null, string>({
      query: (resultId) => `/${resultId}`,
    }),
    createSpecialExamResult: builder.mutation<specialExamResult, SpecialExamResultFV>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),
    updateSpecialExamResult: builder.mutation<specialExamResult, Partial<SpecialExamResultFV> & Pick<specialExamResult, 'id'>>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
    }),
    deleteSpecialExamResult: builder.mutation<void, string>({
      query: (resultId) => ({ url: `/${resultId}`, method: 'DELETE' }),
    }),


  }),
})

export const {
  useGetSpecialExamResultsQuery,
  useGetSpecialExamResultQuery,
  useCreateSpecialExamResultMutation,
  useUpdateSpecialExamResultMutation,
  useDeleteSpecialExamResultMutation,
} = specialExamResultApi
