/** @jsxImportSource @emotion/react */


import { Container } from '@mui/system'
import { Link } from 'react-router-dom'
import { Button, Paper, Stack } from '@mui/material'
import { useGetUnitsQuery } from '../../../store/unitsApi'
import { BgiLink } from '../../../UI/BgiLink'
import PageHeader from '../../../UI/PageHeader'
import LoadingPage from '../../LoadingPage'
import { useTranslation } from 'react-i18next'
import Layout from '../../page/Layout'
import UseDynamicLanguageStyle from '../../i18n/UseDynamicLanguageStyle'
import { css } from '@emotion/react'

const UnitsPage = () => {
  const { t } = useTranslation(['Units', 'common'])
  const { isLoading, error, data: units } = useGetUnitsQuery()

  if (isLoading) return <LoadingPage />

  return (
    <Layout>
      <Container maxWidth="md">
        <BgiLink to={'/'}>Back to the home page</BgiLink>

        <div style={{ width: '100%', height: 30 }} />

        <PageHeader
          style={{ marginBottom: 24 }}
          title={t('Units')}
          actions={
            <Button
              component={Link}
              to="/data-entry/units/create"
              type="submit"
              variant="contained"
            >
              {t('Create_unit')}
            </Button>
          }
        />

        {error || !units ? (
          <p>Unknown Error occurred</p>
        ) : (
          <Stack spacing={3}>
            {units.map((unit) => (
              <Paper key={unit.id} style={{ padding: '1rem' }} elevation={3}>
                <h6>{unit.unitNum}</h6>
                <h6>{unit.searchTags}</h6>
                <h4>{unit.briefTitle}</h4>
                <h3>{unit.detailedTitle}</h3>

                <p css={styles.summary}>{unit.summary}</p>
                <div css={styles.buttons}>
                  <Button
                    component={Link}
                    to={`/data-entry/units/${unit.id}/slides`}
                    variant="contained"
                  >
                    {t('Manage_slides')}
                  </Button>

                  <Button
                    component={Link}
                    to={`/data-entry/units/${unit.id}/update`}
                    variant="contained"
                  >
                    {t('Update_unit')}
                  </Button>
                </div>
              </Paper>
            ))}
          </Stack>
        )}

        <div style={{ width: '100%', height: 30 }} />
      </Container>
    </Layout>
  )
}

export default UnitsPage

// --------------------------------------------------
// --------------------------------------------------

const styles = {
  pageHeader: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24,
  }),
  unitBox: css({
    position: 'relative',
    padding: 16,
    marginBottom: 32,
  }),
  summary: css({
    fontSize: 16,
    minHeight: '5rem',
    background: 'rgb(247, 241, 221)',
  }),
  h_unit: css({
    textAlign: 'center',
    background: 'rgb(253, 228, 145)',
  }),
  buttons: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
  }),
}
