import { Alert, Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Course } from '../../../types/course'
import { CourseForm } from './CourseForm'
import { useGetCourseQuery } from '../../../store/coursesApi'
import PrivateCourseUsers from './PrivateCourseUsers'
import LoadingPage from '../../LoadingPage'

export const UpdateCourse = () => {
  const { courseId } = useParams<{ courseId: string }>()

  const formContext = useForm<Course>()
  const { data: course } = useGetCourseQuery(courseId!)

  if (course) {
    Object.keys(course).forEach((untypedCourseKey) => {
      const courseKey = untypedCourseKey as keyof Course
      formContext.setValue(courseKey, course[courseKey])
    })
  }

  if (!course) return <LoadingPage />

  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <CourseForm />

        <PrivateCourseUsers course={course} />
      </FormProvider>
    </Container>
  )
}
