// export const determineTextDirection = (text: any): Array<'rtl' | 'ltr'> => {
//   if (typeof text !== 'string') {
//     text = String(text);
//   }

//   const lines = text.split('\n');
//   const arabicPattern = /[\u0600-\u06FF\u0750-\u077F]/;

//   return lines.map((line: string) => {
//     const cleanedLine = line.replace(/[\d\s]+/g, '');
//     if (arabicPattern.test(cleanedLine)) {
//       return 'rtl';
//     } else {
//       return 'ltr';
//     }
//   });
// };

// const value= ''

// // const value = `
// // هذا نص باللغة العربية.
// // This is a text in English.
// // النص بالعربية مرة أخرى.
// // Another line in English.
// // `;

// const directions = determineTextDirection(value);
// console.log(directions);











export const determineTextDirection = (text: any): 'rtl' | 'ltr' => {
  if (typeof text !== 'string') {
    text = String(text);
  }

  const cleanedText = text.replace(/[\d\s]+/g, '');

  const arabicPattern = /[\u0600-\u06FF\u0750-\u077F]/;

  if (arabicPattern.test(cleanedText)) {
    return 'rtl';
  } else {
    return 'ltr';
  }
};

const value = '';

const direction = determineTextDirection(value); // لا حاجة لـ || '' لأن الدالة تتعامل مع غير السلاسل





