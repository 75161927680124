import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { User } from '../types/user'
import { apiConfig } from './api'
import countries from '../data/countries'

type RawUser = Omit<User, 'country'> & {
  countryCode?: string
}

const transformUser = (pendingUsers: RawUser) => ({
  ...pendingUsers,
  country: countries.find((c) => c.code === pendingUsers.countryCode)!,
})

export const pendingUsersApi = createApi({
  reducerPath: 'pendingUsersApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/pending-users`,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['PendingUser'],
  endpoints: (builder) => ({
    getPendingUsers: builder.query<User[], void>({
      query: () => '',
      transformResponse: (data: RawUser[]) => data.map(transformUser),
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'PendingUser' as const, id })), 'PendingUser']
          : ['PendingUser'],
    }),
    activateUser: builder.mutation<void, string>({
      query: (userId) => ({ url: `/${userId}/activate`, method: 'POST' }),
      invalidatesTags: ['PendingUser'],
    }),


    deleteUser: builder.mutation<void, string>({
      query: (userId) => ({ url: `/${userId}`, method: 'DELETE' }),
      invalidatesTags: ['PendingUser'],
    }),
  }),
})

export const {
  useGetPendingUsersQuery,
  useActivateUserMutation,
  useDeleteUserMutation,
} = pendingUsersApi
