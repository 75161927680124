/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { Container, Paper } from '@mui/material'
import Layout from './Layout'
import UseDynamicLanguageStyle from '../i18n/UseDynamicLanguageStyle'
import { useTranslation } from 'react-i18next'




const IdeaEducation = () => {
  const { t } = useTranslation("HomePage")
  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()
  return (
    <Layout>
      <Container style={{ backgroundColor: '#FBEECACF' }} css={[styles.page, dynamicLanguageStyle]}>
        <Paper elevation={10} style={{ backgroundColor: '#FBEECACF' }}>

          <div css={styles.banner}>
            <br />
            <h1>{t('Idea_education_home_page_10')}</h1>
            <p>{t('Idea_education_home_page_20')}</p>
            <p>{t('Idea_education_home_page_30')}</p>
            <p>{t('Idea_education_home_page_40')}</p>
          </div>
          {/* ----------------------------------------------------------------------- */}
          {/* ----------------------------------------------------------------------- */}
          <div css={styles.contain}>
            <div><h1>{t('Idea_education_home_page_50')}</h1></div>
            <div><img src="seeTheScience.jpg" /></div>
          </div>


          <div css={styles.contain}>
            <div css={styles.imageSecond}>
              <img src="childExcited.jpg" />
            </div>
            <div css={styles.textFirst}>
              <h1>{t('Idea_education_home_page_60')}</h1>
            </div>
          </div>

          <div css={styles.contain}>
            <div css={styles.textFirst}>
              <h1>{t('Idea_education_home_page_70')}</h1>
            </div>
            <div css={styles.imageSecond}>
              <img src="Gaps.jpg" />
            </div>
          </div>


          {/* ----------------------------------------------------------------------- */}
          {/* ----------------------------------------------------------------------- */}

          <div css={styles.contain}>
            <div css={styles.imageSecond}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/gXfdTm36BoY?rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div css={styles.textFirst}>
              <h1>{t('Idea_education_home_page_80')}</h1>
            </div>

          </div>
        </Paper>
      </Container>
    </Layout>
  )
}

export default IdeaEducation



const styles = {
  page: css({
    // direction: 'rtl',
    minHeight: '100vh',
    'h1': {
      textAlign: 'center',
    },
    '@media (max-width: 700px)': {
      fontSize: '0.8rem',
    },
  }),
  banner: css({
    display: 'block',
    height: 'auto',
    margin: '20px',
    'h1': {
      textAlign: 'center',
    },
    'p': {
      textAlign: 'center',
    },
    '@media (max-width: 700px)': {
      banner: css({
        display: 'block',
      }),
    }
  }),
  // -------------------------------------------
  contain: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: 'auto',
    placeItems: 'center',
    textAlign: 'center',
    'img': {
      maxWidth: '100%',
      borderRadius: '0.8rem',
    },
    '@media (max-width: 700px)': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      margin: '1rem 0.5rem',
      'img': {
        maxWidth: '100%',
        borderRadius: '0.8rem',
      },
      'iframe': {
        maxWidth: '100%',
      },
    },
  }),
  // --------------------------------------
  textFirst: css({
    '@media (max-width: 700px)': {
      order: 1,
    },
  }),
  imageSecond: css({
    '@media (max-width: 700px)': {
      order: 2,
    },
  }),

}