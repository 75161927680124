import { Button, Container } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { BgiLink } from '../../UI/BgiLink'
import PageHeader from '../../UI/PageHeader'
import LoadingPage from '../LoadingPage'
import SubcollectionsGrid from './SubcollectionsGrid'
import { useGetSubcollectionsQuery } from '../../store/subcollectionsApi'
import { useGetCollectionQuery } from '../../store/collectionsApi'
import { useAppSelector } from '../../store/hooks'
import { TopLinearProgress } from '../TopLinearProgress'
import Layout from '../page/Layout'
import { usePageTracking } from '../../GoogleAnalytics'
import { useTranslation } from 'react-i18next'



// ---Comp----------------------------------------------------
// -------------------------------------------------------
export const SubcollectionsListPage = () => {
  const userMe = useAppSelector((state) => state.auth.userMe)
  const { collectionId } = useParams<{ collectionId: string }>()
  const { t } = useTranslation('Collections')
  
  usePageTracking()
  
  const { data: collection } = useGetCollectionQuery(collectionId!)
  const { data: subcollections } = useGetSubcollectionsQuery(collectionId!)

  if (!collection || !subcollections) {
    return <TopLinearProgress />
  }


  return (
    <Layout>
    <Container maxWidth="md">
      {/* <BgiLink to={'/collections'}>Back to the collections page</BgiLink> */}
      <div style={{ width: '100%', height: 30 }} />

      <PageHeader
        style={{ marginBottom: 24 }}
        title={collection.title}
        actions={
          userMe?.role === 'admin' ? (
            <Button
              component={Link}
              variant="contained"
              to={`/data-entry/subcollections/create?collectionId=${collectionId}`}
            >
              {t('Create_subcollection')}
            </Button>
          ) : undefined
        }
      />

      <SubcollectionsGrid
        subcollections={subcollections}
        to={(subcollectionId) => `/data-entry/subcollections/${subcollectionId}/update?collectionId=${collectionId}`}
      />

      <div style={{ width: '100%', height: 30 }} />
    </Container>
    </Layout>
  )
}
