import { FC, useId } from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useFieldRules } from '../hooks/useFieldRules'
import parseFieldErrorText from '../utils/parseFieldErrorText'
import { css } from '@emotion/css';

type BgiSelectProps = SelectProps<any> & {
  name: string
  options: { label: string; value: string }[]
  noOptionText?: string
  lineShading?: boolean
  notched?: any
}

export const BgiSelect: FC<BgiSelectProps> = ({
  name,
  options,
  label: rawLabel,
  fullWidth,
  onChange,
  size = 'small',
  required,
  lineShading,
  noOptionText,
  ...selectProps
}) => {
  const { control } = useFormContext()
  const uniqueId = useId()
  const labelId = `${uniqueId}-label`

  const rules = useFieldRules({ required })

  const label = rawLabel && rawLabel + (required ? ' *' : '')

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { ref, value, onChange: rhfOnChange }, fieldState: { error } }) => {
        const errorText = parseFieldErrorText(error)

        return (
          <FormControl fullWidth={fullWidth} error={!!errorText}>
            {label && (
              <InputLabel
                id={labelId}
                size={size === 'small' ? 'small' : undefined}
                shrink={!!value}
              >
                {label}
              </InputLabel>
            )}

            <Select
              value={value ?? (noOptionText ? '' : value)}
              ref={ref}
              onChange={(e, child) => {
                rhfOnChange(e.target.value)
                if (onChange) onChange(e, child)
              }}
              name={name}
              labelId={label ? labelId : undefined}
              fullWidth={fullWidth}
              label={label}
              size={size}
              notched={!!value}
              {...selectProps}
            >
              {noOptionText && (
                <MenuItem value="" style={{ color: '#9e9e9e' }}>
                  {noOptionText}
                </MenuItem>
              )}

              {options.map((option, index) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  style={lineShading && (index % 2 === 1) ? { backgroundColor: 'yellow' } : undefined}
                  >
                  {option.label}
                </MenuItem>
              ))}
            </Select>

            {errorText && <FormHelperText>{errorText}</FormHelperText>}
          </FormControl>
        )
      }}
    />
  )
}

const mc = {
  lineShading: {
      backgroundColor: 'yellow'
  }
}

// const lineShadingStyle = css`
//   background: yellow;
// `;
