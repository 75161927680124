import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useFieldRules } from '../hooks/useFieldRules'
import parseFieldErrorText from '../utils/parseFieldErrorText'

export interface BgiAutocompleteExtraProps {
  name: string
  label?: string
  required?: boolean
  labelDirection?: 'left' | 'right',

}

const BgiAutocomplete = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  name,
  label,
  size = 'small',
  labelDirection = 'left',
  required,
  ...autoCompleteProps
}: Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'onChange' | 'value'
> &
  BgiAutocompleteExtraProps) => {
  const { control } = useFormContext()

  const rules = useFieldRules({ required })

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      rules={rules}
      render={({
        field: { ref, value, onChange, onBlur },
        fieldState: { error },
      }) => {
        const errorText = parseFieldErrorText(error)

        return (
          <Autocomplete
            {...autoCompleteProps}
            size={size}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label={label + (required ? ' *' : '')}
                inputRef={ref}
                onBlur={onBlur}
                error={!!errorText}
                helperText={errorText}
                InputLabelProps={{
                  style: {
                    textAlign: labelDirection === 'right' ? 'right' : 'left',
                    right: labelDirection === 'right' ? '3rem' : 'auto',
                    left: labelDirection === 'right' ? 'auto' : '0',
                    position: 'absolute',
                  }
                }}
              />
            )}
            value={value}
            onChange={(e, newValue) => onChange(newValue)}
          />
        )
      }}
    />
  )
}

export default BgiAutocomplete
