import { Container } from '@mui/system'
import { Link } from 'react-router-dom'
import { css } from '@emotion/css'
import { Button, Paper, Stack } from '@mui/material'
import { useGetUsersQuery } from '../../../store/usersApi'
import { BgiLink } from '../../../UI/BgiLink'
import PageHeader from '../../../UI/PageHeader'
import LoadingPage from '../../LoadingPage'
import Layout from '../../page/Layout'

const UsersPage = () => {
  const { isLoading, error, data: users } = useGetUsersQuery()

  if (isLoading) return <LoadingPage />

  return (
    <Layout>
      <Container maxWidth="md">

        <div style={{ width: '100%', height: 30 }} />

        <PageHeader
          style={{ marginBottom: 24 }}
          title="Users"
          actions={
            <Button
              component={Link}
              to="/data-entry/users/create"
              type="button"
              variant="contained"
            >
              Create User
            </Button>
          }
        />

        <Button
          component={Link}
          to="/data-entry/pending-users"
          type="button"
          variant="contained"
        >
          Pending users
        </Button>

        {error || !users ? (
          <p>Unknown Error occurred</p>
        ) : (
          <ol style={{ paddingLeft: 24 }}>
            {users.map((user) => (
              <li key={user.id}>
                <BgiLink to={`/data-entry/users/${user.id}/update`}>
                  {user.name} {user.lastName} - {user.email}
                </BgiLink>
              </li>
            ))}
          </ol>
        )}

        <div style={{ width: '100%', height: 30 }} />
      </Container>
    </Layout>
  )
}

export default UsersPage
