import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Unit, UnitI } from '../types/unit'
import { apiConfig } from './api'

export const unitsApi = createApi({
  reducerPath: 'unitsApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/units`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getUnits: builder.query<Unit[], void>({
      query: () => '',
    }),
    getUnit: builder.query<Unit | null, string>({
      query: (unitId) => `/${unitId}`,
    }),
    createUnit: builder.mutation<Unit, Omit<UnitI, 'id'>>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),
    updateUnit: builder.mutation<Unit, Partial<UnitI> & Pick<Unit, 'id'>>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
    }),
    deleteUnit: builder.mutation<void, string>({
      query: (unitId) => ({ url: `/${unitId}`, method: 'DELETE' }),
    }),
  }),
})

export const {
  useGetUnitsQuery,
  useGetUnitQuery,
  useCreateUnitMutation,
  useUpdateUnitMutation,
  useDeleteUnitMutation,
} = unitsApi
