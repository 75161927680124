/** @jsxImportSource @emotion/react */

import React from 'react'
import { Button, Container } from '@mui/material'
import Layout from './Layout'
import { CSSObject } from '@emotion/react';
import IframeYoutube from '../../UI/IframeYoutube';

const InstructionsForUse = () => {
  return (
    <Layout>
      <Container maxWidth="md">
        <div css={cSty.text}>
          يمكنك الحصول على كافة خدمات منصة فوتون من خلال التسجيل المجاني. <br />

          إذا كنت سوف تسجل للمرة الأولى قم بالضغط على الزر  <Button variant="contained" color="error">فتح حساب جديد</Button>  <br />
          إذا كان قد سبق لك وأن سجلت فقم بالضغط على
          <Button variant="contained" color="error">دخول</Button>
        </div>

        <div css={cSty.text}>
          قيم بتعبئة الحقول وفي حالة كان لديك أي مشكلة فيمكنك مشاهدة الفلم التالي: <br />

          <IframeYoutube YoutubeSrc="ZiUY0-3fuuQ?si=PCG6-nc0nlkDX706" />
        </div>

        <div css={cSty.text}>
          عندما تنتهي من تعبئة البيانات وتضغط على الزر إرسال، يجب عليك  الذهاب إلى بريدك الإلكتروني الذي سجلته لكي تقوم بتأكيد الأيميل (لكي يتأكد أن من قام بالتسجيل كان شخصاً وليس ربورت). <br />
          إذا لم تجد الأيميل يمكنك مشاهدة الفيديو التالي لمزيد من المعلومات

          <IframeYoutube YoutubeSrc="Mi_oY4m2coc?si=ndAecAxVj5Hck60m" />

          <b style={{ color: 'red' }}>ملاحظة:   </b>
          إذا لم يصل الايميل خلال دقائق، فهذا يعني أنك قمت بكتابة الايميل بطريقة خاطئة، أعد التسجيل وتأكد من كتابة الأيميل بطريقة صحيحة.

        </div>



        <hr /><hr />
        <div css={cSty.text}>
          <b style={{ color: 'red' }}>تنزيل المواد الدراسية:   </b>
          تنزيل المواد الدراسية إلى الهاتف والعمل معها بدون الحاجة للاتصال بالأنترنت: <br />
          هناك : خطوتان يجب عملهم لكي تتمكن من استخدام المواد الدراسية على الهاتف أو الحاسوب بدون الحاجة إلى الانترنت. <br />
          الأولى : هي تنزيل هيكل الموقع على الجهاز (لمرة واحدة فقط) <br />
          الثانية : هي الذهاب إلى أي وحدة دراسية ومن ثم تنزلها على الجهاز. <br /> <br />
          ويمكنك مشاهدة طريقة التنزيل والتعامل مع الوحدات الدراسية من خلال الفيديو التالي: . <br />

          <IframeYoutube YoutubeSrc="-U1zFBa772w?start=181&end=242&si=NOL3feN8HhYgu9ey" />

          <b style={{ color: 'red' }}>تحديث الوحدات الدراسية:   </b>
          في كل مرة تقوم بفتح الوحدة الدراسية وقد تم عمل إضافات أو تعديلات على الوحدة الدراسية فإنك سوف تجد أعلى العناوين ملاحظة تخبرك أنه قد تم التحديث الوحدة الدراسية، فقط قم بالضغط على الزر تحديث الوحدة الدراسية.
        </div>


      </Container>
    </Layout>
  )
}


const cSty: { [key: string]: CSSObject } = {
  text: {
    direction: 'rtl',
    fontSize: '1.2rem',
    padding: '20px',
    fontWeight: 'bold',
    color: 'black',
  },
}

export default InstructionsForUse
