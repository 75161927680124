/** @jsxImportSource @emotion/react */

import { FC, useEffect, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  useCreateSlideMutation,
  useDeleteSlideMutation,
  useUpdateSlideMutation,
} from '../../../store/slidesApi'
import { SlideFormValues, getSlidePayload } from '../../../utils/getSlidePayload'
import { useNavigate } from 'react-router-dom'
import { Button, Checkbox, Container, FormControlLabel, Paper, Stack, } from '@mui/material'
import BgiCheckboxSelect from '../../../UI/BgiCheckboxSelect'
import BgiInputsList from '../../../UI/BgiInputsList'
import { BgiSelect } from '../../../UI/BgiSelect'
import { BgiTextField } from '../../../UI/BgiTextField'
import { Category, FileHash, Incomplete, SlideI } from '../../../types/slide'
import { BgiLink } from '../../../UI/BgiLink'
import { UnitsSearch } from '../../UnitsSearch'
import FieldValue from '../../FieldValue'
import { AssetField } from '../../gallery/AssetField'
import MarkdownEditor from '../Markdown/MarkdownEditor'
import TextWithMath from '../../TextWithMath'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'
import { useSnackbar } from 'notistack'
import { deepClone } from '../../../utils/deepClone'
import VideoUi from '../../../UI/video/VideoUi'
import { useTranslation } from 'react-i18next'
import { SlidePreview } from './SlidePreview'
import { css } from '@emotion/css'
import {UiCheckboxOutHook, extractLastAutoGeneratedSlideNumber} from '../../../UI/UiCheckboxOutHook'



const SLIDE_TYPES = [
  { label: 'Question Checkboxes', value: 'question-checkboxes' },
  { label: 'Question Cards', value: 'question-cards' },
  { label: 'Question Multi Inputs', value: 'question-multi-inputs' },
  { label: 'Question Input', value: 'question-input' },
  { label: 'Image', value: 'image' },
  { label: 'Youtube Video', value: 'youtube-video' },
  { label: 'Text editor with img', value: 'only-text' },
  { label: 'Flashcard', value: 'flashcard' },
  // { label: 'Question Textarea', value: 'question-textarea' },  // TODO save data in DB in sever
  // { label: 'Feedback', value: 'feedback' },  // TODO: support feedback
]

interface SlideFormProps {
  categories: Category[]
  incompletes: Incomplete[]
}


// -----------------------------------------------------------------------------------
// ----comp---------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
export const SlideForm: FC<SlideFormProps> = ({ categories, incompletes }) => {
  const { control, handleSubmit, getValues, reset, clearErrors, setValue } =
    useFormContext<{ slide: SlideFormValues }>()
  const { t } = useTranslation('Slide')
  const navigate = useNavigate()


  const categoriesOptions = useMemo(
    () =>
      categories.map((category) => ({
        label: category.title,
        value: category.id,
      })),
    [categories]
  )

  const incompletesOptions = useMemo(
    () =>
      incompletes.map((category) => ({
        label: category.title,
        value: category.id,
      })),
    [incompletes]
  )

  const slideType = useWatch({ control, name: 'slide.type' }) ?? '' // TODO Fuad , who it can take a type with out path
  const slideId = useWatch({ control, name: 'slide.id' })
  const unit = useWatch({ control, name: 'slide.unit' })
  const isCreateForm = !slideId
  const initialUnitId = useMemo(() => getValues().slide.unit?.id, [])
  const [stableUnitId, setStableUnitId] = useState(initialUnitId)
  const defaultValues = useMemo(() => deepClone(getValues()), [])
  const [isShowMath, setIsShowMath] = useState(false)
  const [number, setNumber] = useState<string>("");


  const dir = unit.language === 'ar' ? 'rtl' : 'ltr'



  const [createSlide, { isLoading: isCreating }] = useCreateSlideMutation()

  const [updateSlide, { isLoading: isUpdating }] = useUpdateSlideMutation()

  const [deleteSlide, { isLoading: isDeleting }] = useDeleteSlideMutation()


  // ----Message---------------------------------------------------------
  const SUCCESS_MSGS = {
    create: (slideId: string) => (
      <p>
        {t('Slide_has_been_created_successfully_with')}{' '}
        <BgiLink color="#fff" to={`/data-entry/slides/${slideId}/update`}>
          #{slideId}
        </BgiLink>
      </p>
    ),
    update: 'Slide has been updated successfully',
  }
  const getErrMsg = buildGetErrMsg()

  // ---AutoNumber----------------------------------------------------------------
  useEffect(() => {
    const autoGeneratedSimNumber = localStorage.getItem('autoGeneratedSimNumber');
    if (autoGeneratedSimNumber) {
      setNumber(autoGeneratedSimNumber);
    }
  }, []);



  const handleCheckboxChange = (checked: boolean) => {
    if (checked) {
      const updateAutoGeneratedSimNumber = extractLastAutoGeneratedSlideNumber(number,2 + Math.round(Math.random())); 
      setNumber(updateAutoGeneratedSimNumber);
      localStorage.setItem('autoGeneratedSimNumber', updateAutoGeneratedSimNumber);
      setValue('slide.slideNum', updateAutoGeneratedSimNumber);
    } else {
      setValue('slide.slideNum', '');
    }
  };
  // ------------------------------------------------------------AutoNumber-------


  // ----------------------------------------------------------Message---

  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = handleSubmit(async ({ slide: slideInput }) => {

      const slidePayload = getSlidePayload(slideInput)


      if (!slideInput.id) {
        const result = await createSlide(slidePayload)
        localStorage.setItem('autoGeneratedSimNumber', slideInput.slideNum as string);


        if ('data' in result) {
          enqueueSnackbar({
            message: SUCCESS_MSGS['create'](result.data.id),
            variant: 'success',
          })
          reset({
            slide: {
              ...deepClone(defaultValues.slide),
              type: slideInput.type as any,
              //  @ts-ignore
              onlyText: '' as any,
              flashcardBack: '' as any,
              flashcardFront: '' as any,
            }
          })
          const updateAutoGeneratedSimNumber = extractLastAutoGeneratedSlideNumber(slideInput.slideNum as string, 2);
          setNumber(updateAutoGeneratedSimNumber);

        } else {
          enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
        }
      } else {
        const result = await updateSlide({ id: slideInput.id, ...slidePayload })
        if ('data' in result) {

          // unitId isn't undefined here since it's not auto generated slide
          const slideUnitId = result.data.unitId!
          setStableUnitId(slideUnitId)
          enqueueSnackbar({
            message: SUCCESS_MSGS['update'],
            variant: 'success',
          })
        } else {
          enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
        }
      }
  })

  // ------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------
  const onDelete = async () => {
    if (!slideId) return

    const confirmationMsg = t('Are_you_sure_you_want_to_delete_this_slide')

    if (!window.confirm(confirmationMsg)) return

    const result = await deleteSlide(slideId)

    if ('data' in result) {
      enqueueSnackbar({
        message: 'Slide has been deleted successfully',
        variant: 'success',
      })
      navigate(`/data-entry/units/${stableUnitId}/slides`)
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  }

  const toggleShowMath = () => {
    setIsShowMath(!isShowMath)
  }


  // --return------------------------------------------------------------------------
  // --------------------------------------------------------------------------
  return (
    <>
      {/* <div css={{ */}
      {/* width: '100%',
      maxWidth: 1784,
      padding: '0 24px',
      // display: 'grid',
      // gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      // gap: 32,
      margin: '0 auto'
    }}> */}
      <Container maxWidth="md">
        <BgiLink
          to={stableUnitId ? `/data-entry/units/${stableUnitId}/slides` : '/'}
        >
          Back to the {stableUnitId ? 'unit' : 'home'} page
        </BgiLink>

        <div style={{ width: '100%', height: 30 }} />

        <Paper style={{ padding: '1rem' }}>
          <h2 style={{ marginBottom: 24 }}>
            {slideId ? 'Update' : 'Create'} Slide
          </h2>

          <form onSubmit={onSubmit}>
            <Stack spacing={3}>
              <div>

                <BgiTextField
                  style={{ backgroundColor: '#bababa' }}
                  label={t('Title')}
                  name="slide.title"
                  fullWidth
                  size="small"
                  required
                />
                {/* <label><input type="checkbox" onChange={toggleDir} />Toggle Direction</label> */}

                <div style={{ marginTop: '0.5rem' }}>
                  <FormControlLabel
                    label={t('Show_math')}
                    control={
                      <Checkbox
                        checked={isShowMath}
                        onChange={toggleShowMath}
                      />
                    }
                  />
                </div>
              </div>


              {/* for sometime ----------------------*/}
              {/* to move slide form unit to unit */}
              <div>
                <UnitsSearch name="slide.unit" required />

                <FieldValue name="slide.unit.summary">
                  {(unitSummary: string) => (
                    <p className={mc.note}>{unitSummary}</p>
                  )}
                </FieldValue>
              </div>

              {/* <div className={mc.grid}>
                <BgiCheckboxSelect
                  name="slide.incompletesIds"
                  label={t('Incompletes')}
                  options={incompletesOptions}
                />

                <BgiSelect
                  name="slide.categoryId"
                  options={categoriesOptions}
                  label={t('Category')}
                  fullWidth
                  noOptionText="Chose a category"
                />
              </div> */}
              <UiCheckboxOutHook
                name="AutoSlideNumber"
                label="Auto Slide Number"
                onChange={(e, checked) => handleCheckboxChange(checked)}
              />
              <div className={mc.type_quAnd_slideNum}>
                <BgiSelect
                  name="slide.type"
                  options={SLIDE_TYPES}
                  label={t('Slide_type')}
                  fullWidth
                  size="small"
                  required
                  noOptionText="Chose a slide type"
                  onChange={() => clearErrors()}
                />


                <BgiTextField
                  style={{ backgroundColor: '#FFEBCD' }}
                  label={t('Slide_number')}
                  name="slide.slideNum"
                  fullWidth
                  size="small"
                  required
                />
              </div>
              {slideType.startsWith('question-') && (
                <>
                  <div className={mc.pointAndQuestion}>
                    <BgiTextField
                      label={t('Point')}
                      name="slide.point"
                      type="number"
                      fullWidth
                      size="small"
                      required
                    />

                    <BgiTextField
                      style={{ backgroundColor: '#bababa' }}
                      label={t('Question')}
                      name="slide.question"
                      fullWidth
                      size="small"
                      required
                    />
                  </div>
                  {isShowMath && (
                    <FieldValue name="slide.question">
                      {(value) => <TextWithMath value={value} />}
                    </FieldValue>
                  )}


                  <BgiTextField
                    label={t('Answer_beginning')}
                    name="slide.answerBeginning"
                    fullWidth
                    size="small"
                  />
                </>
              )}

              {(slideType === 'question-checkboxes' || slideType === 'question-cards' || slideType === 'question-multi-inputs') && (
                <>
                  <BgiInputsList
                    key={slideType}
                    name="slide.answers"
                    label={t('Answers')}
                    checkbox
                    supportMath={isShowMath}
                    minLength={1}
                    addBtnContent="Add Answer"
                    questionType={slideType}
                  // supportFormatting={slideType === 'question-checkboxes'}
                  />
                </>
              )}
              {(slideType === 'question-checkboxes' || slideType === 'question-input') && (
                <BgiTextField
                  label={t('Code_input')}
                  name="slide.codeInput"
                  multiline
                  fullWidth
                  minRows={8}
                  maxRows={16}
                />
              )}

              {slideType !== 'youtube-video' && (
                <>
                  <Stack direction="row" spacing={3}>
                    <AssetField
                      fileKind="image"
                      name="slide.imageFile"
                      required={slideType === 'image'}
                      label={t('Image')}
                      onChange={imageFile => setValue('slide.imageFileId', imageFile?.id)}
                    />

                    <AssetField
                      fileKind="audio"
                      name="slide.audioFile"
                      label={t('Audio')}
                      onChange={audioFile => setValue('slide.audioFileId', audioFile?.id)}
                    />
                  </Stack>
                </>
              )}

              {slideType === 'question-input' && (
                <>
                  <BgiTextField
                    label={t('Input_label')}
                    name="slide.inputLabel"
                    fullWidth
                    size="small"
                    required
                  />

                  <BgiTextField
                    label={t('Correct_answer')}
                    name="slide.inputCorrectAnswer"
                    fullWidth
                    size="small"
                    required
                  />
                </>
              )}

              {slideType === 'flashcard' && (
                <>
                  <h2>the first face</h2>
                  <MarkdownEditor
                    label="flashcardFront"
                    name="slide.flashcardFront"
                    isPreview={isShowMath}
                    minRows={5}
                    maxRows={10}
                  />
                  <h2>the second face</h2>
                  <MarkdownEditor
                    label="flashcardBack"
                    name="slide.flashcardBack"
                    isPreview={isShowMath}
                    minRows={5}
                    maxRows={10}
                  />
                  {/* <BgiTextField
                    label="flashcardFront"
                    name="slide.flashcardFront"
                    fullWidth
                    size="small"
                    required
                  /> */}

                  {/* <BgiTextField
                    label="flashcardBack"
                    name="slide.flashcardBack"
                    fullWidth
                    size="small"
                    required
                  />*/}
                </>
              )}

              {/* ---video---------------------------------------------------------------------------------------- */}
              {slideType === 'youtube-video' && (
                <>
                  <BgiTextField
                    label={t('Youtube_video_slug')}
                    name="slide.youtubeVideoSlug"
                    fullWidth
                    size="small"
                    required
                    maxLength={256}
                    pattern={/^\s*[a-zA-Z0-9_-]+\s*/}
                    processValue={url => {
                      const longVideoBaseUrl = 'https://www.youtube.com/watch?v='
                      const shortVideoBaseUrl = 'https://www.youtube.com/shorts/'


                      if (url.startsWith(shortVideoBaseUrl)) {
                        return url.slice(shortVideoBaseUrl.length)
                      } else if (url.startsWith(longVideoBaseUrl)) {
                        const params = new URLSearchParams(url.slice(longVideoBaseUrl.length - 3))
                        return params.get('v') ?? ''
                      }

                      return ''
                    }}
                  />

                  <FieldValue name="slide.youtubeVideoSlug">
                    {(youtubeVideoSlug) => (
                      <VideoUi
                        videoUrl={`https://www.youtube.com/embed/${youtubeVideoSlug}`}
                      />
                    )}
                  </FieldValue>

                </>
              )}
              {/* -----------------------------------------------------------------------------------------video-- */}
              {slideType === 'only-text' && (
                <MarkdownEditor
                  label={t("Only_text_with_img")}
                  name="slide.onlyText"
                  isPreview={isShowMath}
                  minRows={5}
                  maxRows={10}
                />
              )
              }

              <MarkdownEditor
                label={t('More_info')}
                name="slide.moreInfo"
                isPreview={isShowMath}
                minRows={2}
                maxRows={4}
              />

              <BgiTextField
                label={t('Note')}
                name="slide.note"
                multiline
                fullWidth
                size="small"
                minRows={2}
                maxRows={4}
              />

              <Stack direction="row" justifyContent="space-between">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isCreating || isUpdating}
                >
                  {isCreateForm ? 'Create' : 'Update'}
                </Button>

                {!isCreateForm && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={onDelete}
                    disabled={isDeleting}
                  >
                    Delete
                  </Button>
                )}
              </Stack>
            </Stack>
          </form>
        </Paper>

        <div style={{ width: '100%', height: 30 }} />

        <div dir={dir} css={{ width: '50rem' }}>
          <SlidePreview key={slideType} dir={dir} />
        </div>
      </Container>

      {/* </div> */}
    </>
  )
}





const mc = {
  category: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    height: '8rem',
    boxShadow: '5px 5px 5px 5px gray',
    borderRadius: '10px',
  }),
  bgiSelectIn: css({
    marginTop: '2rem',
  }),
  incomplete: css({
    width: '19.5rem',
  }),
  point: css({
    display: 'flex',
    gap: 16,
  }),
  imageWithAnswer: css({
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    boxShadow: '5px 5px 5px 5px gray',
  }),
  assetWrapper: css({
    position: 'relative',
    width: 200,
    height: 200,
    overflow: 'hidden',
    borderRadius: '0.25rem',
    border: '1px solid #64748b',
  }),
  assetItem: css({
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
  }),
  uploadBtn: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 14,
    color: '#64748b',
    cursor: 'pointer',
    width: 200,
    height: 200,
    flexShrink: 0,
    border: '1px solid #64748b',
    borderRadius: '0.25rem',
    overflow: 'hidden',
    margin: 0,
  }),
  deleteBtn: css({
    position: 'absolute',
    top: 6,
    left: 6,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#333',
    background: '#ccc',
    borderRadius: 8,
    padding: 4,
  }),
  grid: css({
    display: 'grid',
    gap: 16,
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  }),
  note: css({
    marginTop: 4,
    fontSize: 14,
  }),
  type_quAnd_slideNum: css({
    display: 'flex',
    flexDirection: 'row-reverse',
  }),
  pointAndQuestion: css({
    display: 'grid',
    gridTemplateColumns: '0.08fr 1fr'
  })
}


