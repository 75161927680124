import { useState, Fragment, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useGetLatestAnnouncementQuery } from '../../../store/announcementsApi';
import MarkdownView from '../../slides/slide-view/MarkdownView';

export default function AnnouncementPopup() {
  const { data: announcement } = useGetLatestAnnouncementQuery()


  const [open, setOpen] = useState(false);
  const isInitialAnnouncementProcessed = useRef(false);

  useEffect(() => {
    if (!announcement || isInitialAnnouncementProcessed.current) return;
    const isSeen = window.localStorage.getItem('announcementSeen') === announcement.id;

    if (!isSeen) {
      setOpen(true);
    }

    isInitialAnnouncementProcessed.current = true;
  }, [announcement]);

  const handleClose = () => {
    setOpen(false);
    window.localStorage.setItem('announcementSeen', announcement!.id);
    //  TODO: send request user saw the announcement
  };

  if (!announcement) return null

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {announcement.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <MarkdownView value={announcement.description} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
