import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiConfig } from './api'
import { FeedbackT, FeedbackI, UpdateFeedbackT } from '../types/feedback'

export const feedbacksApi = createApi({
  reducerPath: 'feedbackApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/feedbacks`,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['Feedback'],
  endpoints: (builder) => ({

    getFeedbacks: builder.query<FeedbackT[], void>({
      query: () => '',
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Feedback' as const, id })), 'Feedback']
          : ['Feedback'],
    }),


    createFeedback: builder.mutation<FeedbackT, FeedbackI>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),

    deleteFeedback: builder.mutation<void, string>({
      query: (feedbackId) => ({url: `/${feedbackId}`, method: 'DELETE'}),
    }),
    updateFeedback: builder.mutation<FeedbackT, UpdateFeedbackT>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
      invalidatesTags: ['Feedback'],
    })
  }),
})

export const { 
  useGetFeedbacksQuery, 
  useCreateFeedbackMutation,
  useDeleteFeedbackMutation,
  useUpdateFeedbackMutation
} = feedbacksApi
