/** @jsxImportSource @emotion/react */

import Layout from './Layout';
import {
  QuickAccessHighSchoolCoursesData,
  QuickAccessHighSchoolEnglishCoursesData,
  QuickAccessMiddleSchoolCoursesData
} from '../../data/QuickAccessCoursesData';
import { Container, Paper } from '@mui/material';
import AccordionPage from '../../UI/AccordionPage';
import { css } from '@emotion/react'



const QuickAccessCoursesPage = () => {
  return (
    <Layout>
      <br /><br /><br />
      <Container>
        <Paper elevation={12}>
          <h3 css={mcss.heading}>مناهج الفيزياء</h3>
          <AccordionPage
            allData={QuickAccessHighSchoolCoursesData}
            color='info'
          />
          <br />
        </Paper>
        <br />

        <h3 css={mcss.heading}>مناهج العلوم</h3>
        <Paper elevation={7}>
          <br />
          <AccordionPage
            allData={QuickAccessMiddleSchoolCoursesData}
            color='info'
          />
          <br />
        </Paper>


        <h3 css={mcss.heading}>اللغة الإنجليزية</h3>
        <Paper elevation={7}>
          <br />
          <AccordionPage
            allData={QuickAccessHighSchoolEnglishCoursesData}
            color='info'
          />
          <br />
        </Paper>
      </Container>
    </Layout>
  )
}

const mcss = {
  heading: css({
    backgroundColor: '#f0f8ff',
    border: '2px solid #007bff',
    borderRadius: '8px',
    padding: '10px 15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    color: '#007bff',
    fontFamily: `'Arial', sans-serif`,
    fontSize: '3rem',
    textAlign: 'center',
    marginBottom: '20px',
  }),
};

export default QuickAccessCoursesPage
