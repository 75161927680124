import { css } from '@emotion/css'
import clsx from 'clsx'
import {
  FC,
  useRef,
  useEffect,
  useState,
  CSSProperties,
  MouseEventHandler,
} from 'react'
import PlayIcon from '@mui/icons-material/VolumeUp'
import PauseIcon from '@mui/icons-material/Pause'

interface AudioProps {
  src: string
  className?: string
  style?: CSSProperties
  stopClickPropagation?: boolean
  size?: 'large' | 'medium'
}

const Audio: FC<AudioProps> = ({
  className,
  style,
  src,
  stopClickPropagation,
  size='medium'
}) => {
  const audioRef = useRef(new window.Audio(src))
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    const onPause = () => setIsPlaying(false)

    audioRef.current.addEventListener('pause', onPause)

    return () => {
      audioRef.current.removeEventListener('pause', onPause)
      audioRef.current.pause()
    }
  }, [])

  useEffect(() => {
    setIsPlaying(false)
    audioRef.current.pause()
    audioRef.current.src = src
  }, [src])

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (stopClickPropagation) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (isPlaying) {
      audioRef.current.pause()
    } else {
      setIsPlaying(true)
      audioRef.current.play()
    }
  }

  return (
    <button
      className={clsx(styles.button, className)}
      style={style}
      onClick={handleClick}
      type="button"
    >
      {isPlaying ? (
        <PauseIcon fontSize={size} />
      ) : (
        <PlayIcon fontSize={size} />
      )}
    </button>
  )
}

const styles = {
  button: css({
    border: 'none',
    background: 'transparent',
    padding: 0,
    outline: 0,
    cursor: 'pointer',
  }),
}

export default Audio
