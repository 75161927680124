/** @jsxImportSource @emotion/react */

import { useGetUsersQuery } from '../../store/usersApi'
import { useGetSpecialExamResultsQuery } from '../../store/specialExamResultApi'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LoadingPage from '../LoadingPage'
import { Container } from '@mui/material'
import { css } from '@emotion/react'

// ---Comp-------------------------------------------------------------
// ----------------------------------------------------------------
const ShowSpecialExamResult = () => {
  const { isFetching: isFetchingResults, error: resultsError, data: examResults } = useGetSpecialExamResultsQuery('id');
  const { isLoading, error: usersError, data: users } = useGetUsersQuery()

  // ------------------------------------------------------------------

  if (isFetchingResults || isLoading) return <LoadingPage />;
  if (resultsError || usersError) return <div>Error occurred</div>;

  return (
    <Container maxWidth='md'>
      <h1 style={{ textAlign: 'center' }}>نتائج المشاركين في المسابقة</h1>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>lastName</TableCell>
              <TableCell align="center">name</TableCell>
              <TableCell align="center">school</TableCell>
              <TableCell align="center">city</TableCell>
              <TableCell align="center">Result</TableCell>
              <TableCell align="center">Exam code</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examResults?.map((result, index) => (
              <TableRow
                key={result.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: index % 2 == 0 ? 'beige' : 'gray.200',
                }}
              >
                <TableCell component="th" scope="row">
                  {result.user.lastName}
                </TableCell>
                <TableCell align="center">{result.user.name}</TableCell>
                <TableCell align="center">{result.user.school}</TableCell>
                <TableCell align="center">{result.user.city}</TableCell>
                <TableCell align="center">{result.percentageResult}</TableCell>
                <TableCell align="center">{result.token}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


    </Container>
  );
}


export default ShowSpecialExamResult
