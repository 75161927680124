import { Alert, Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Collection } from '../../../types/collection'
import { CollectionForm } from './CollectionForm'
import { useGetCollectionQuery } from '../../../store/collectionsApi'
import LoadingPage from '../../LoadingPage'

export const UpdateCollection = () => {
  const { collectionId } = useParams<{ collectionId: string }>()

  // Ensure collectionId is available
  if (!collectionId) {
    throw new Error('Collection ID not provided.')
  }

  const formContext = useForm<Collection>()
  const { data: collection } = useGetCollectionQuery(collectionId)

  if (collection) {
    Object.keys(collection).forEach((untypedCollectionKey) => {
      const collectionKey = untypedCollectionKey as keyof Collection
      formContext.setValue(collectionKey, collection[collectionKey])
    })
  }

  if (!collection) return <LoadingPage />

  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <CollectionForm />
      </FormProvider>
    </Container>
  )
}
