/** @jsxImportSource @emotion/react */

import clsx from "clsx";
import React, { DetailedHTMLProps, TouchEventHandler, VideoHTMLAttributes, useEffect, useRef, useState } from "react";
import { css } from '@emotion/react';
import { enqueueYtApiCallback } from "../../utils/ytApiUtils";
import useSwipe from "../../hooks/useSwipe";


//  when i have mp4 or ogg
type NativeVideoProps = DetailedHTMLProps<
  VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

interface VideoProps extends NativeVideoProps {
  videoUrl?: string;
  videoId?: string
  onSwipeLeft?: () => void
  onSwipeRight?: () => void
}

// ---comp---------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------

const VideoUi = ({ className, children, videoUrl, videoId, onSwipeLeft, onSwipeRight, ...rest }: VideoProps) => {
  const isYoutubeVideo = !!videoId;
  const ytPlayer = useRef<any>()
  let lastClickTime = useRef(0);


  useEffect(() => {
    enqueueYtApiCallback(() => {
      // @ts-ignore
      const player = new window.YT.Player('youtube-player', {
        videoId: videoId,
        playerVars: {
          playsinline: 1
        },
        events: {
          onReady: () => {
            ytPlayer.current = player
            toggleYtVideoState()
          }
        }
      });
    })
  }, [])

  const swipeHandlers = useSwipe({
    onSwipeLeft,
    onSwipeRight
  })


  function handleDoubleClick() {
    const currentTime = new Date().getTime();
    if (currentTime - lastClickTime.current < 300) { // 300ms للتحقق من النقر المزدوج
      handleFullScreen(); // تفعيل أو إلغاء وضع ملء الشاشة
    }
    lastClickTime.current = currentTime;
  }

  function handleTouchEnd(e: any) {
    const currentTime = new Date().getTime();
    if (currentTime - lastClickTime.current < 300) { // تحقق من النقر المزدوج
      handleFullScreen(); // تفعيل أو إلغاء وضع ملء الشاشة
      lastClickTime.current = 0; // إعادة تعيين لتجنب تفعيل النقر المزدوج مرة أخرى بسرعة
      e.preventDefault(); // قد يساعد في تجنب بعض التعارضات
    } else {
      lastClickTime.current = currentTime;
    }
  }



  function handleFullScreen() {
    const playerElement = document.getElementById("youtube-player");
    if (!playerElement) return;
    if (playerElement.requestFullscreen) {
      playerElement.requestFullscreen();
    } // إضافة شروط لدعم المتصفحات الأخرى إذا لزم الأمر
  }


  function toggleYtVideoState() {
    if (!ytPlayer.current) return

    const YT_STATES = {
      ENDED: 0,
      PLAYING: 1
    }

    const state = ytPlayer.current.getPlayerState()

    if (state === YT_STATES.ENDED) {
      ytPlayer.current.seekTo(0)
    } else if (state === YT_STATES.PLAYING) {
      ytPlayer.current.stopVideo()
    } else {
      ytPlayer.current.playVideo()
      ytPlayer.current.unMute()
      ytPlayer.current.setVolume(100)
    }
  }

  if (isYoutubeVideo) {
    return (
      <div css={mc.videoContainer}>
        <div id="youtube-player">Loading...</div>
        <div
          css={mc.ytOverlay}
          {...swipeHandlers}
          onClick={toggleYtVideoState}
        >

        </div>
      </div>
    )
  }
  // --return--------------------------------------------------
  // ----------------------------------------------------------
  return (
    <video className={clsx([mc.videoCss, className])} controls {...rest}>
      <source src={videoUrl} type="video/mp4"></source>
    </video>
  );
};

export default VideoUi;


const mc = {
  videoContainer: css({
    position: 'relative',
    width: '100%',
    height: '100%',
    '@media (max-width: 1200px)': {
      position: 'absolute',
      zIndex: 5,
      left:'0',
      top: '0',
      transform: 'translate (-50%, -50%)',
    },
    '& > iframe': {
      width: '100%',
      height: '100%',
    },
  }),
  ytOverlay: css({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'transparent',
    '@media (min-width: 1200px)': {
      display: 'none',
    }
  }),
  videoCss: css({
    width: '100%',
    height: '100%',
  }),
  youtubeVideo: css({
    width: '100%',
    // height: '100%', // Un-comment this line if you want to use the height property
  }),
};