/** @jsxImportSource @emotion/react */

import React from 'react'
import { Container } from '@mui/material'
import { css } from '@emotion/react'
import Layout from '../page/Layout'
import { useTranslation } from 'react-i18next'


const AfterExam = () => {
  const { t } = useTranslation(['Exams']);
  return (
    <Layout>
    <Container>
      {/* <h2>Dear student, the exam period has ended.</h2> */}
      <h2 style={{ direction: 'rtl', margin: '10%' }}>{(t('The_competition_has_ended'))}</h2>
      <div css={mcss.logo}>
        <img src="/bgiIcon512.jpg" alt="Logo"/>
      </div>
    </Container>
    </Layout>
  )
}

const mcss = {
  logo: css({
    display: 'flex',
    height: '15rem',
  })
}
export default AfterExam
