import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Collection, CollectionI } from '../types/collection'
import { apiConfig } from './api'

export const collectionsApi = createApi({
  reducerPath: 'collectionsApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/collections`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getCollections: builder.query<Collection[], void>({
      query: () => '',
    }),
    getCollection: builder.query<Collection | null, string>({
      query: (collectionId) => `/${collectionId}`,
    }),
    createCollection: builder.mutation<Collection, CollectionI>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),
    updateCollection: builder.mutation<Collection, Partial<CollectionI> & Pick<Collection, 'id'>>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
    }),
    deleteCollection: builder.mutation<void, string>({
      query: (collectionId) => ({ url: `/${collectionId}`, method: 'DELETE' }),
    }),
  }),
})

export const {
  useGetCollectionsQuery,
  useGetCollectionQuery,
  useCreateCollectionMutation,
  useUpdateCollectionMutation,
  useDeleteCollectionMutation,
} = collectionsApi
