import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiConfig } from './api'
import { ChapterSlidesData, Slide } from '../types/slide'
import randomArr from '../utils/randomArr'
import { Chapter, UpdateChapterIndexInput } from '../types/chapter'
import randomInt from '../utils/randomInt'
import crypto from 'crypto-js'

export const chaptersApi = createApi({
  reducerPath: 'chaptersApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/chapters`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getChapters: builder.query<Chapter[], string>({
      query: (courseId) => `/?courseId=${courseId}`,
    }),
    getChapterSlides: builder.query<ChapterSlidesData | null, string>({
      query: (chapterId) => `/${chapterId}/slides`,
      transformResponse: transformChapterSlides,
    }),
    updateChapterIndex: builder.mutation<string[], UpdateChapterIndexInput>({
      query: ({ id, ...body }) => ({
        url: `/${id}/updateIndex`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
})

export const randomizeSlideAnswers = (slide: Slide) => {
  if (slide.type === 'question-checkboxes' || slide.type === 'question-cards') {
    return {
      ...slide,
      answers: randomArr(slide.answers as any) as any,
    } as Slide
  }

  return slide as Slide
}

function transformChapterSlides(data: ChapterSlidesData | null) {
  if (!data) return null

  // -----cryptoSlides------------------------------------------------------------//.enc.Utf8
  const stringifiedSlides = data.data as unknown as string

  const result = crypto.AES.decrypt(stringifiedSlides, process.env.REACT_APP_CRYPTO_SECRET!).toString(crypto.enc.Utf8);

  const decryptedSlides = JSON.parse(result) as ChapterSlidesData['data']

  const slides = randomArr(decryptedSlides, {
    avoidRandomize: (slide) => data.relations.pinnedUnits.includes(slide.unitId!),
  }).map(randomizeSlideAnswers)
  // -----------------------------------------------------------------

  // -----------------------------------------------------------------

  const { stickyAds, bannerAd, ...restRelations } = data.relations

  if (bannerAd) {
    const { type, ...restBannerAd } = bannerAd

    slides.unshift({
      type: 'banner-ad',
      incompletesIds: [],
      unavailable: false,
      note: '',
      moreInfo: '',
      ...restBannerAd,
    })
  }

  return {
    ...data,
    data: slides,
    relations: {
      ...restRelations,
      bannerAd,
      stickyAds: [...new Array(slides.length)].map((_, idx) =>
        stickyAds.length > 0 && !(bannerAd && idx === 0)
          ? stickyAds[randomInt(0, stickyAds.length) % slides.length] ?? null
          : null
      ),
    },
  }
}

export const {
  useGetChapterSlidesQuery,
  useGetChaptersQuery,
  useUpdateChapterIndexMutation
} = chaptersApi
