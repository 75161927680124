import { FC, ReactNode } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type BgiCheckboxProps = CheckboxProps & { name: string; label?: ReactNode };

export const BgiCheckbox: FC<BgiCheckboxProps> = ({
  name,
  label,
  ...CheckboxProps
}) => {
  const { control } = useFormContext();

  const checkboxControl = (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      render={({ field: { ref, value, onChange } }) => (
        <Checkbox
          {...CheckboxProps}
          ref={ref}
          name={name}
          checked={value}
          onChange={(e, newValue) => onChange(newValue)}
        />
      )
      }
    />
  );

  return label ? (
    <FormControlLabel control={checkboxControl} label={label} />
  ) : (
    checkboxControl
  );
};
