import { FC, ReactNode, CSSProperties } from 'react'
import { css } from '@emotion/css'

interface PageHeaderProps {
  style?: CSSProperties
  title: string
  actions?: ReactNode
}

const PageHeader: FC<PageHeaderProps> = ({ style, title, actions }) => {
  return (
    <div className={styles.root} style={style}>
      <h1>{title}</h1>
      <div>{actions}</div>
    </div>
  )
}

const styles = {
  root: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
}

export default PageHeader
