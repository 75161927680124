import { useMemo } from 'react'
import { RegisterOptions } from 'react-hook-form'

interface ValidationRules {
  required?: boolean
  maxLength?: number
  minLength?: number
  pattern?: RegExp
}

export const useFieldRules = ({ required, maxLength, minLength, pattern}: ValidationRules) => {
  return useMemo(() => {
    const result: RegisterOptions = {
      
    }

    if (required) {
      result.required = { value: true, message: 'هذا الحقل مطلوب' }
    } else {
      result.required = { value: false, message: '' }

    }

    if (typeof maxLength === 'number') {
      result.maxLength = {
        value: maxLength,
        message: 'الطول الأقصى هو' + ' ' + maxLength,
      }
    }

    if (typeof minLength === 'number') {
      result.minLength = {
        value: minLength,
        message: 'الطول الادنى هو' + ' ' + minLength,
      }
    }

    if (pattern) {
      result.pattern = { value: pattern, message: 'القيمة غير صالحة' }
    }

    return result
  }, [required, maxLength, minLength])
}
