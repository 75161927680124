import { TouchEventHandler, useState } from "react"

interface SwipeOptions {
  distance?: number
  onSwipeLeft?: () => void
  onSwipeRight?: () => void
}

const useSwipe = ({ distance = 50, onSwipeLeft, onSwipeRight}: SwipeOptions) => {
  const [touchStart, setTouchStart] = useState<number | null>(null)
  const [touchEnd, setTouchEnd] = useState<number | null>(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe


  const minSwipeDistance = distance

  const onTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove: TouchEventHandler<HTMLDivElement> = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd: TouchEventHandler<HTMLDivElement> = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe && onSwipeLeft) {
      onSwipeLeft()
    } else if (isRightSwipe && onSwipeRight) {
      onSwipeRight()
    }
  }

  const handlers = { onTouchStart, onTouchMove, onTouchEnd }

  return handlers
}

export default useSwipe
