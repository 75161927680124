// TODO: support local video upload
// TODO: make "moreInfo" TextField support Markdown
// TODO: support imageUrl across update

import { FormattingT } from '../UI/FormatText'
import { FileData } from '../components/gallery/FileForm'
import { Ad } from './ad'
import { BasicChapter, Chapter } from './chapter'
import { Course } from './course'
import { Unit } from './unit'

// util types
const stringT = <T extends string>(...values: T[]): T => values[0] ?? ''
const numberZeroT = (): number => 0
const booleanFalseT = (): boolean => false
const arrayT = <T extends any>(): T[] => []

// types
class BaseSlideI {
  title = stringT()
  categoryId? = stringT()
  incompletesIds = arrayT<string>()
  unitId? = stringT()
  unavailable = booleanFalseT()
  note = stringT()
  moreInfo = stringT()
  slideNum? = stringT()
}

class BaseQuestionSlideI extends BaseSlideI {
  question = stringT()
  point = numberZeroT()
  answerBeginning? = stringT()
  imageFileId? = stringT()
  audioFileId? = stringT()
}

export class QuestionCheckboxesSlideI extends BaseQuestionSlideI {
  type = stringT('question-checkboxes')
  answers = arrayT<
    { type: 'checkboxAnswer'; text: string; formatting?: FormattingT; isCorrect: boolean; }
  >();
  codeInput = stringT()
}

export class QuestionCheckboxesSlide extends QuestionCheckboxesSlideI {
  id = stringT()
  unitId = stringT()
}


interface MediaAnswerCommon {
  text: string
  imageFileId?: string
  audioFileId?: string
  imageFile?: FileData
  audioFile?: FileData
}

export type AnswerT = 
  ({ type: 'checkboxAnswer'; text: string; isCorrect: boolean; }) |
  (MediaAnswerCommon & { type: 'cardAnswer'; isCorrect: boolean; correctAnswer: string; }) |
  (MediaAnswerCommon & { type: 'inputAnswer'; correctAnswer: string; }) |
  (MediaAnswerCommon & { type: 'label' })


export class QuestionCardsSlideI extends BaseQuestionSlideI {
  type = stringT('question-cards') 
  answers = arrayT<
    (MediaAnswerCommon & { type: 'cardAnswer'; isCorrect: boolean; correctAnswer: string; })
  >();
}
export class QuestionCardsNotRandomSlideI extends BaseQuestionSlideI {
  type = stringT('question-cards') 
  answers = arrayT<
    (MediaAnswerCommon & { type: 'cardAnswer'; isCorrect: boolean; correctAnswer: string; })
  >();
}

class FlashcardSlideI extends BaseSlideI {
  type = stringT('flashcard')
  flashcardFront = stringT()
  flashcardBack = stringT()
  imageFileId? = stringT()
  audioFileId? = stringT()
}

export class QuestionCardsSlide extends QuestionCardsSlideI {
  id = stringT()
  unitId = stringT()
}

export class QuestionCardsNotRandomSlide extends QuestionCardsNotRandomSlideI {
  id = stringT()
  unitId = stringT()
}

export class QuestionMultiInputsSlideI extends BaseQuestionSlideI {
  type = stringT('question-multi-inputs')
  answers = arrayT<
  (MediaAnswerCommon & { type: 'inputAnswer'; correctAnswer: string; }) |
  (MediaAnswerCommon & { type: 'label' })
>();
}

export class QuestionMultiInputsSlide extends QuestionMultiInputsSlideI {
  id = stringT()
  unitId = stringT()
}

export class QuestionInputSlideI extends BaseQuestionSlideI {
  type = stringT('question-input')
  inputLabel = stringT()
  inputCorrectAnswer = stringT()
  codeInput = stringT()
}

export class QuestionInputSlide extends QuestionInputSlideI {
  id = stringT()
  unitId = stringT()
}

export class ImageSlideI extends BaseSlideI {
  type = stringT('image')
  imageFileId = stringT()
  audioFileId? = stringT()
}

export class ImageSlide extends ImageSlideI {
  id = stringT()
  unitId = stringT()
}

export class YoutubeVideoSlideI extends BaseSlideI {
  type = stringT('youtube-video')
  youtubeVideoSlug = stringT()
}

export class YoutubeVideoSlide extends YoutubeVideoSlideI {
  id = stringT()
  unitId = stringT()
}

export type BannerAdAutoGenSlide = BaseSlideI & { type: 'banner-ad' } & Omit<Ad, 'type'> 

// ---only read-------------------------------------------------
export class OnlyTextSlideI extends BaseSlideI {
  type = stringT('only-text')
  imageFileId? = stringT()
  onlyText = stringT()
}

export class OnlyTextSlide extends OnlyTextSlideI {
  id = stringT()
  unitId = stringT()
}

export class FlashcardSlide extends FlashcardSlideI {
  id = stringT()
  unitId = stringT()
}

// export class PdfSlideI extends BaseSlideI{
//   type = stringT('pdf-read')
//   pdfRead = stringT()
// }
// export class PdfSlide extends PdfSlideI {
//   id = stringT()
//   unitId = stringT()
// }
// ---------------------------------------------only read-------

export type SlideI =
  | QuestionCheckboxesSlideI
  | QuestionCardsSlideI
  | QuestionMultiInputsSlideI
  | ImageSlideI
  | QuestionInputSlideI
  | YoutubeVideoSlideI
  | OnlyTextSlideI
  | BannerAdAutoGenSlide
  | FlashcardSlideI

export type Slide = SlideI & { id: string }

export type FetchedSingleSlide = Slide & { unit: Unit }

// slideKeys
const classKeys = <T extends new (...args: any[]) => any>(
  Class: T
): (keyof InstanceType<T>)[] => Object.keys(new Class())

export const foreignEntities = ['unit', 'imageFile', 'audioFile'] as const

export const slidesKeys = {
  'question-checkboxes': classKeys(QuestionCheckboxesSlideI),
  'question-cards': classKeys(QuestionCardsSlideI),
  'question-multi-inputs': classKeys(QuestionMultiInputsSlideI),
  'question-input': classKeys(QuestionInputSlideI),
  image: classKeys(ImageSlideI),
  'youtube-video': classKeys(YoutubeVideoSlideI),
  'only-text': classKeys(OnlyTextSlideI),
  flashcard: classKeys(FlashcardSlideI),
}

// slides related types
export interface Category {
  id: string
  title: string
}

export interface Incomplete {
  id: string
  title: string
}

// frontend specific
export interface FileHash {
  id: string
  fileHash: string
}

export interface ChapterSlidesData {
  data: Slide[]
  relations: {
    units: Unit[]
    filesHashes: FileHash[]
    course: Course
    courseChapters: BasicChapter[]
    stickyAds: (Ad | null)[]
    bannerAd: Ad | null
    pinnedUnits: string[]
  }
}

export interface UpdateSlideIndexInput {
  id: string
  index: number
}
