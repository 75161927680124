import { ThemeOptions } from "@mui/material";

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'inherit',
    body1: {
      lineHeight: 'normal'
    }
  },
  
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  palette: {
    // primary: {
    //   main: '#ff0321'
    // }
  }
};
