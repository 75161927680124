// features/language/languageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LanguageState {
  currentLanguage: 'ar' | 'en' | 'de';
  direction: 'rtl' | 'ltr';
}

const initialState: LanguageState = {
  currentLanguage: navigator.language.startsWith('ar') ? 'ar' : 'en',
  // currentLanguage: navigator.language.startsWith('ar') ? 'ar' : ('en'? 'en' : 'de'),

  direction: navigator.language.startsWith('ar') ? 'rtl' : 'ltr',
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<'ar' | 'en' | 'de'>) => {
      state.currentLanguage = action.payload;
      state.direction = action.payload === 'ar' ? 'rtl' : 'ltr';
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
