import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiConfig } from './api'
import { ClickCountPerCourseT, ClickT, createClickT } from '../types/clickT';




export const clicksApi = createApi({
  reducerPath: 'clicksApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/clicks`,
  }),
  endpoints: (builder) => ({

    getClicks: builder.query<ClickT[], void>({
      query: () => '/clicks',
    }),

    createClick: builder.mutation<ClickT, createClickT>({
      query: (body) => (({ url: `/`, method: 'POST', body }))
    }),


    getClicksCountPerCourse: builder.query<number, string>({
      query: (courseId) => `/courses/${courseId}`,
    }),
  })
})

export const {
  useGetClicksQuery,
  useCreateClickMutation,
  useGetClicksCountPerCourseQuery,
  // useUpdateClicksMutation,

} = clicksApi;


//TODO: add jwt to this Fetch

//TODO: updateClicks: builder.mutation<void, { courseId: string, userId: string }>({
