import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { AnnouncementFv, AnnouncementI, AnnouncementT } from '../../../types/announcement'
import { BgiTextField } from '../../../UI/BgiTextField';
import MarkdownEditor from '../Markdown/MarkdownEditor';
import {
  useCreateAnnouncementMutation,
  useDeleteAnnouncementMutation,
  useUpdateAnnouncementMutation
} from '../../../store/announcementsApi';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { BgiLink } from '../../../UI/BgiLink';
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SUCCESS_MSGS = {
  create: (announcementId: string) => (
    <p>
      Announcement has been created successfully with{' '}
      <BgiLink color="#fff" to={`/data-entry/announcements/${announcementId}/update`}>
        #{announcementId}
      </BgiLink>
    </p>
  ),
  update: 'تم تحديث الإعلام بنجاح',
}

const getErrMsg = buildGetErrMsg({
  DUP_ANNOUNCEMENT_CODE: 'كود الإعلام مكرر. ادخل كود آخر',
  ANNOUNCEMENT_IS_REFERENCED: "Please delete announcement's chapters first",
})

// --Comp---------------------------------------------------------------------------
// ---------------------------------------------------------------------------------
const AnnouncementForm = () => {
  const { control, handleSubmit, reset } = useFormContext<AnnouncementFv>();
  const announcementId = useWatch({ control, name: 'id' })
  const isCreateForm = !announcementId

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [createAnnouncement, { isLoading: isCreating }] = useCreateAnnouncementMutation()
  const [updateAnnouncement, { isLoading: isUpdating }] = useUpdateAnnouncementMutation()
  const [deleteAnnouncement, { isLoading: isDeleting }] = useDeleteAnnouncementMutation()


  //   // -2--------------------------------------------------------------------------------

  const onSubmit = async (announcement: AnnouncementFv) => {
    const { id, ...restData } = announcement
    if (!id) {
      const result = await createAnnouncement(restData)
      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['create'](result.data.id),
          variant: 'success',
        })
        reset()
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    } else {
      const result = await updateAnnouncement({ id, ...restData })
      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['update'],
          variant: 'success',
        })
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    }
  }
    // -3--------------------------------------------------------------------------------

  const onDelete = async () => {
    if (!announcementId) return

    const result = await deleteAnnouncement(announcementId.toString())
    if ('data' in result) {
      enqueueSnackbar({
        message: 'تم حذف الإعلام بنجاح',
        variant: 'success',
      })
      navigate(`/data-entry/announcements`);
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  }


    // ---ret--------------------------------------------------------------------------------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <BgiTextField
          label="Title"
          name="title"
          required
          fullWidth
          size='small'
          maxLength={128}
        />

        <MarkdownEditor
          label="description"
          name="description"
          isPreview={false}
          minRows={5}
          maxRows={10}
        />

        <Stack direction="row" justifyContent="space-between">
          <Button variant="contained" type="submit" disabled={isCreating || isUpdating}>
            {isCreateForm ? 'Create' : 'Update'}
          </Button>

          {!isCreateForm && (
            <Button color="error" variant="outlined" onClick={onDelete} disabled={isDeleting}>
              Delete
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  )
}

export default AnnouncementForm






// import React from 'react'
// import { useFormContext, useWatch } from 'react-hook-form'
// import { AnnouncementI, AnnouncementT } from '../../../types/announcement'
// import { BgiTextField } from '../../../UI/BgiTextField';
// import MarkdownEditor from '../Markdown/MarkdownEditor';
// import {
//   useCreateAnnouncementMutation,
//   useDeleteAnnouncementMutation,
//   useUpdateAnnouncementMutation
// } from '../../../store/announcementsApi';
// import { enqueueSnackbar, useSnackbar } from 'notistack';
// import { BgiLink } from '../../../UI/BgiLink';
// import { buildGetErrMsg } from '../../../utils/buildGetErrMsg';
// import { Button, Stack } from '@mui/material';

// const SUCCESS_MSGS = {
//   create: (announcementId: string) => (
//     <p>
//       Announcement has been created successfully with{' '}
//       <BgiLink color="#fff" to={`/data-entry/announcements/${announcementId}`}>
//         #{announcementId}
//       </BgiLink>
//     </p>
//   ),
//   update: 'تم تحديث الإعلام بنجاح',
// }

// const getErrMsg = buildGetErrMsg({
//   DUP_ANNOUNCEMENT_CODE: 'كود الإعلام مكرر. ادخل كود آخر',
//   ANNOUNCEMENT_IS_REFERENCED: "Please delete announcement's chapters first",
// })

// const AnnouncementForm = () => {
//   const { control, handleSubmit, reset } = useFormContext<AnnouncementI & { id?: string }>();
//   const announcementId = useWatch({ control, name: 'id', defaultValue: '' });
//   const isCreateForm = !announcementId;

//   const { enqueueSnackbar } = useSnackbar();

//   const [createAnnouncement, { isLoading: isCreating }] = useCreateAnnouncementMutation();
//   const [updateAnnouncement, { isLoading: isUpdating }] = useUpdateAnnouncementMutation();
//   const [deleteAnnouncement, { isLoading: isDeleting }] = useDeleteAnnouncementMutation();

//   const onSubmit = async (announcement: AnnouncementT) => {
//     if (isCreateForm) {
//       const result = await createAnnouncement(announcement);
//       if ('data' in result) {
//         enqueueSnackbar({
//           message: SUCCESS_MSGS.create(result.data.id),
//           variant: 'success',
//         });
//         reset();
//       } else {
//         enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' });
//       }
//     } else {
//       const result = await updateAnnouncement(announcement);
//       if ('data' in result) {
//         enqueueSnackbar({
//           message: SUCCESS_MSGS.update,
//           variant: 'success',
//         });
//       } else {
//         enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' });
//       }
//     }
//   }

//   const onDelete = async () => {
//     if (isCreateForm) return;

//     const result = await deleteAnnouncement(announcementId);
//     if ('data' in result) {
//       enqueueSnackbar({
//         message: 'تم حذف الإعلام بنجاح',
//         variant: 'success',
//       });
//       reset();
//     } else {
//       enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' });
//     }
//   }

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <Stack spacing={3}>
//         <BgiTextField
//           label="Title"
//           name="title"
//           required
//           fullWidth
//           size="small"
//           maxLength={128}
//         />

//         <MarkdownEditor
//           label="description"
//           name="description"
//           isPreview={false}
//           minRows={5}
//           maxRows={10}
//         />

//         <Stack direction="row" justifyContent="space-between">
//           <Button variant="contained" type="submit" disabled={isCreating || isUpdating}>
//             {isCreateForm ? 'Create' : 'Update'}
//           </Button>

//           {!isCreateForm && (
//             <Button color="error" variant="outlined" onClick={onDelete} disabled={isDeleting}>
//               Delete
//             </Button>
//           )}
//         </Stack>
//       </Stack>
//     </form>
//   );
// }

// export default AnnouncementForm;
