import { useEffect, useId, useMemo, useRef } from 'react'
import { useAppSelector } from '../../../store/hooks'
import { css } from '@emotion/css'
import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL'
import { useFormContext, useWatch } from 'react-hook-form'
import { SlideView } from '../../../types/SlideView'
import { QuestionCardsSlide } from '../../../types/slide'
import TextWithMath from '../../TextWithMath'
import { useLocation } from 'react-router-dom'
import { determineTextDirection } from '../../../utils/determineTextDirection'

export const questionCardsDefaultAnswer = []

export const checkSlideQuestionCardsAnswer = (
  { answers }: QuestionCardsSlide,
  userAnswer: number[]
) => {
  const isIncorrect =
    answers.some(
      (answer, answerIdx) => answer.isCorrect && !userAnswer.includes(answerIdx)
    ) || userAnswer.some((answerPart) => !answers[answerPart].isCorrect)

  return !isIncorrect
}
// ------------------------------------------------------------------------
// ------------------------------------------------------------------------
const SlideViewQuestionCards: SlideView<QuestionCardsSlide> = ({
  slide,
  filesHashes,
  isCurExam
}) => {
  const audiosRef = useRef<(HTMLAudioElement | null)[]>([])

  useEffect(() => {
    return () => {
      audiosRef.current.forEach((audio) => {
        if (audio) audio.pause()
      })
    }
  }, [])

  const componentId = useId()

  const imageUrl = useMemo(() => {
    const imageFileHash = filesHashes.find(
      (fileHash) => fileHash.id === slide.imageFileId
    )?.fileHash

    return imageFileHash ? `${FILES_BASE_URL}/${imageFileHash}` : null
  }, [filesHashes])

  const submittedAnswer = useAppSelector(
    (state) => state.slides.userAnswers[slide.type][slide.id]
  )

  const { control, setValue } = useFormContext<{ userAnswer: number[] }>()

  const userAnswer = useWatch({ control, name: 'userAnswer' })


  // -------------------------------------------------
  const toggleAnswer = (answerIdx: number) => {
    setValue(
      'userAnswer',
      userAnswer.includes(answerIdx)
        ? userAnswer.filter((answerPart) => answerPart !== answerIdx)
        : [...userAnswer, answerIdx]
    )
  }
  // -------------------------------------------------

  const playAudio = (answerIdx: number) => {
    const audio = audiosRef.current[answerIdx]

    if (!audio) return

    audiosRef.current.forEach((iterateAudio, iterateI) => {
      if (
        iterateAudio &&
        iterateI !== answerIdx &&
        !iterateAudio.paused
      ) iterateAudio.pause()
    })

    if (!audio.paused) {
      audio.currentTime = 0
    } else {
      audio.play()
    }
  }

  return (
    <>
      <h2 className={styles.questionTitle} data-for-screen="desktop">
        <TextWithMath value={slide.question} />
      </h2>

      <div className={styles.grid(!!imageUrl)}>
        <div>
          <p className={styles.answerBeginning}>{slide.answerBeginning}</p>

          <div className={styles.answers}>
            {slide.answers.map((answer, answerIdx) => (
              <div key={answerIdx} className={styles.answerBox} onClick={() => playAudio(answerIdx)}>
                {answer.audioFile && (
                  <audio className={styles.answerAudio} ref={el => audiosRef.current[answerIdx] = el}>
                    <source
                      src={`${FILES_BASE_URL}/${answer.audioFile.fileHash}`}
                      type={answer.audioFile.fileExt === '.ogg' ? 'audio/ogg' : 'audio/mpeg'}
                    />
                  </audio>
                )}
                {/* don't change the input css. */}
                <input
                  className={styles.answerInput}
                  type="checkbox"
                  id={`${componentId}-answer-${answerIdx}`}
                  checked={userAnswer.includes(answerIdx)}
                  onChange={() => toggleAnswer(answerIdx)}
                  disabled={!!submittedAnswer}
                  dir={determineTextDirection(answer.text)}
                />


                {/* all the change in css will be only her on Label. */}
                <label
                  className={styles.answerLabel} htmlFor={`${componentId}-answer-${answerIdx}`}
                  // back it to !isCurExam
                  // data-submitted-result={isCurExam? (submittedAnswer
                  data-submitted-result={submittedAnswer
                    ? (answer.isCorrect ? 'correct' : 'incorrect')
                    : null}
                    dir={determineTextDirection(answer.text)}

                >
                  {answer.imageFile && (
                    <img className={styles.answerImg} src={`${FILES_BASE_URL}/${answer.imageFile.fileHash}`} />
                  )}
                  <span><TextWithMath value={answer.text} /></span>
                </label>
              </div>
            ))}
          </div>
        </div>

        {imageUrl && <img key={imageUrl} className={styles.img} src={imageUrl} alt="" />}
      </div>
    </>
  )
}

const styles = {
  grid: (hasImage: boolean) =>
    css({
      display: 'grid',
      width: '100%',
      gridTemplateColumns: hasImage ? '1fr 1fr' : '1fr',
      gap: '1rem',
      '@media screen and (max-width: 676px)': {
        gridTemplateColumns: '1fr',
      },
    }),
  questionTitle: css({
    fontSize: 22,
    fontWeight: 700,
    marginBottom: '1.5rem',
    display: 'block',
    userSelect: 'none',
  }),
  answerBeginning: css({
    fontSize: 14,
    marginBottom: '0.5rem',
    userSelect: 'none',
  }),
  img: css({
    maxWidth: '100%',
    margin: '0 auto',
    display: 'block',
    userSelect: 'none',
    borderRadius: '0.4rem',
    '@media screen and (max-width: 676px)': {
      gridRow: '1 / 2',
    },
  }),
  answers: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.5rem',
    alignItems: 'center',
    textAlign: 'center',
  }),
  
  answerBox: css({
    position: 'relative',
    marginLeft: 16,
    marginBottom: 16,
    cursor: 'pointer',
    
  }),
  answerAudio: css({
    width: 0,
    height: 0,
    position: 'absolute',
    top: 0,
    visibility: 'hidden',
  }),
  answerInput: css({
    // width: 0,
    // height: 0, 
    position: 'absolute',
    top: 0,
    visibility: 'hidden',
    '& + label': {
      background: '#a4c2e6',
      border: '3px solid transparent',
      cursor: 'pointer',
      height: '5rem',

    },
    // '&:disabled + label': {
    //   border: '3px solid var(--clrBg)',
    // },
    '&:checked + label': {
      border: '3px solid var(--clrBg)',
    },
    '& + label[data-submitted-result="correct"]': {
      background: '#43a047',
      color: '#fff',
      cursor: 'default',
    },
    '& + label[data-submitted-result="incorrect"]': {
      background: '#EF5350',
      color: '#fff',
      cursor: 'default',

    },
  }),
  answerLabel: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 100,
    minHeight: 50,
    userSelect: 'none',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px'
  }),
  answerImg: css({
    maxWidth: 100,
    marginBottom: 4
  })
}

export default SlideViewQuestionCards

//  i will back to this code later to mack the answerLabel css better.
// const getGridStyles = (numberOfItems: number) => {
//   let styles: { [key: string]: any } = {
//     display: 'grid',
//     gridTemplateColumns: '1fr 1fr',
//     gap: '1.5rem',
//     '& > *': {
//       width: '100%',
//       minWidth: '10rem',
//       maxWidth: '20rem',
//     },
//   };

//   if (numberOfItems === 3) {
//     styles = {
//       ...styles,
//       '& > :nth-child(3)': {
//         gridColumn: '1 / -1',
//         justifySelf: 'center',
//       },
//     };
//   } else if (numberOfItems === 4) {
//     styles = {
//       ...styles,
//       '& > :nth-child(3)': {
//         gridColumn: '1 / -1',
//         justifySelf: 'center',
//         gridRow: '2 / 3',
//       },
//       '& > :nth-child(4)': {
//         gridColumn: 'auto',
//         gridRow: '2 / 3',
//       },
//     };
//   } else if (numberOfItems === 5) {
//     styles = {
//       ...styles,
//       '& > :nth-child(5)': {
//         gridColumn: '1 / -1',
//         justifySelf: 'center',
//         gridRow: '2 / 3',
//       },
//     };
//   } else if (numberOfItems >= 7) {
//     styles = {
//       ...styles,
//       '& > :nth-child(7)': {
//         gridColumn: '1 / -1',
//         justifySelf: 'center',
//         gridRow: '3 / 4',
//       },
//     };
//   }

//   return css(styles);
// };
