import React, { FC } from 'react'
import MarkdownView from '../slides/slide-view/MarkdownView'
import { Button } from '@mui/material'
import { determineTextDirection } from '../../utils/determineTextDirection'

interface MoreInfoProp {
  moreInfo: string
  onClose: () => void
}


const MoreInfo: FC<MoreInfoProp> = ({ moreInfo, onClose }) => {

  return (
    <div style={{ flexShrink: 1}}>
      <div
        style={{
          flex: 1,
          margin: '1rem',
          direction: determineTextDirection(moreInfo),
          overflow: 'auto',
          maxHeight: '500px',

        }}
      >
        <MarkdownView value={moreInfo} />
      </div>

      <div style={{ position : 'fixed', bottom: '0.2rem', margin: '1rem'}}>
        <Button
          variant="contained"
          onClick={onClose}
        >
          العودة للشريحة
        </Button>
      </div>
    </div>
  )
}

export default MoreInfo




