import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiConfig } from './api'
import { AnnouncementT, AnnouncementI, UpdateAnnouncementT } from '../types/announcement'

export const announcementsApi = createApi({
  reducerPath: 'announcementApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/announcements`,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['Announcement'],
  endpoints: (builder) => ({

    getLatestAnnouncement: builder.query<AnnouncementT, void>({
      query: () => '/latest',
      providesTags: (result, error, arg) => result ? [{ type: 'Announcement' as const, id: result.id }, 'Announcement'] : ['Announcement']
    }),

    getAnnouncements: builder.query<AnnouncementT[], void>({
      query: () => '',
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Announcement' as const, id })), 'Announcement']
          : ['Announcement'],
    }),

    getAnnouncement: builder.query<AnnouncementT, string>({
      query: (announcementId) => `/${announcementId}`,
      providesTags: (result, error, arg) => result ? [{ type: 'Announcement' as const, id: result.id }] : []
    }),


    createAnnouncement: builder.mutation<AnnouncementT, AnnouncementI>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),

    deleteAnnouncement: builder.mutation<void, string>({
      query: (announcementId) => ({ url: `/${announcementId}`, method: 'DELETE' }),
    }),
    updateAnnouncement: builder.mutation<AnnouncementT, UpdateAnnouncementT>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
      invalidatesTags: ['Announcement'],
    })
  }),
})

export const {
  useGetAnnouncementsQuery,
  useGetAnnouncementQuery,
  useGetLatestAnnouncementQuery,
  useCreateAnnouncementMutation,
  useDeleteAnnouncementMutation,
  useUpdateAnnouncementMutation
} = announcementsApi
