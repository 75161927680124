import { useMemo } from 'react'
import { SlideView } from '../../../types/SlideView'
import { ImageSlide } from '../../../types/slide'
import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL'
import { css } from '@emotion/css'

const SlideViewImage: SlideView<ImageSlide> = ({ slide, filesHashes }) => {


  const imageUrl = useMemo(() => {
    const imageFileHashObject = filesHashes.find(
      (fileHash) => fileHash.id === slide.imageFileId
    )
    
    if (!imageFileHashObject) {
      // console.error(`File hash for imageFileId ${slide.imageFileId} not found`)
      return ''
    }

    return `${FILES_BASE_URL}/${imageFileHashObject.fileHash}`
  }, [slide.imageFileId, filesHashes])

  return <img className={styles.root} src={imageUrl} alt="" />
}

const styles = {
  root: css({
    maxWidth: '100%',
    margin: '0 auto',
    display: 'block',
    userSelect: 'none',
  }),
}

export default SlideViewImage








// الكود الأصلي

// import { useMemo } from 'react'
// import { SlideView } from '../../../types/SlideView'
// import { ImageSlide } from '../../../types/slide'
// import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL'
// import { css } from '@emotion/css'

// const SlideViewImage: SlideView<ImageSlide> = ({ slide, filesHashes }) => {
//   const imageUrl = useMemo(() => {

//     const imageFileHash = filesHashes.find(
//       (fileHash) => fileHash.id === slide.imageFileId
//     )!.fileHash

//     return `${FILES_BASE_URL}/${imageFileHash}`
//   }, [])

//   return <img className={styles.root} src={imageUrl} alt="" />
// }

// const styles = {
//   root: css({
//     maxWidth: '100%',
//     margin: '0 auto',
//     display: 'block',
//     userSelect: 'none',
//   }),
// }

// export default SlideViewImage
