import { FC, ReactNode } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'


interface FieldValueProps {
  name: string,
  children: (value: any) => ReactNode
}

const FieldValue: FC<FieldValueProps> = ({ name, children }) => {
  const { control } = useFormContext()
  const value = useWatch({ control, name })
  return <>{children(value)}</> 
}

export default FieldValue