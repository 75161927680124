import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { FC, useMemo, useState } from 'react'
import { uploadFile } from '../../utils/uploadFile'
import dayjs from 'dayjs'
import { axiosInstance } from '../../utils/axiosInstance'
import { Asset, AssetProps } from '../../UI/Asset'
import { buildGetErrMsg } from '../../utils/buildGetErrMsg'
import useSnackResErr from '../../hooks/useSnackResErr'
import { useSnackbar } from 'notistack'
import UpdateIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { getExtension } from '../../utils/liteMime'

export interface FileData {
  id: string
  label: string
  fileHash: string
  fileExt: string
  createdAt: string
  updatedAt: string
}

interface FileFormProps {
  fileData: FileData
  onCancel: () => void
  onUpdated?: (newFile: FileData) => void
  onDeleted?: () => void
  fileKind: AssetProps['fileKind']
}

const getErrMsg = buildGetErrMsg({
  FILE_IS_IN_USE: 'File is used',
})

export const FileForm: FC<FileFormProps> = ({
  fileData,
  onCancel,
  onUpdated,
  onDeleted,
  fileKind
}) => {
  const [updatedFile, setUpdatedFile] = useState<File | null>(null)
  const [updatedLabel, setUpdatedLabel] = useState(fileData.label)
  // TODO: explain toBase64 vs  URL.createObjectURL
  const previewFile = useMemo(() => {
    if (updatedFile) {
      const fileExt = getExtension(updatedFile.type)

      if (fileExt) {
        return {
          fileSrc: URL.createObjectURL(updatedFile),
          fileExt: `.${fileExt}`,
        }
      }
    }

    return null
  }, [updatedFile])

  const { enqueueSnackbar } = useSnackbar()
  const snackResErr = useSnackResErr(getErrMsg)

  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const onUpdate = async () => {
    setIsUpdating(true)

    const formData = new FormData()
    if (updatedFile) formData.append('file', updatedFile)
    formData.append('label', updatedLabel)

    try {
      const res = await uploadFile({
        formData,
        onUploadProgress: (progress) => null,
        method: 'patch',
        path: `/files/${fileData.id}`,
      })

      const { data, isDupLabelError } = res.data

      if (isDupLabelError) {
        enqueueSnackbar({
          message: "Label hasn't updated because the new label is duplicated",
          variant: 'error',
          autoHideDuration: 6000,
        })
      } else {
        enqueueSnackbar({
          message: 'File updated successfully',
          variant: 'success',
        })
      }

      if (onUpdated) onUpdated(data)
    } catch (e) {
      snackResErr(e)
    } finally {
      setIsUpdating(false)
    }
  }

  const onDelete = async () => {
    setIsDeleting(true)

    try {
      await axiosInstance.delete(`/files/${fileData.id}`)

      enqueueSnackbar({
        message: 'File deleted successfully',
        variant: 'success',
      })

      if (onDeleted) onDeleted()
    } catch (e) {
      snackResErr(e)
    } finally {
      setIsDeleting(false)
    }
  }

  const isProcessing = isUpdating || isDeleting

  return (
    <Dialog
      open={!!fileData}
      // onClose={close}
      maxWidth="xl"
    >
      <DialogTitle>File #{fileData.id}</DialogTitle>

      <DialogContent>
        {fileData && (
          <>
            <Asset
              value={
                previewFile
                  ? previewFile
                  : { fileSrc: fileData.fileHash, fileExt: fileData.fileExt }
              }
              updateButton={{
                type: 'input',
                onChange: (file) => setUpdatedFile(file),
              }}
              fileKind={fileKind}
            />

            <TextField
              value={updatedLabel}
              onChange={(e) => setUpdatedLabel(e.target.value)}
            />

            <p>
              CreatedAt: {dayjs(fileData.createdAt).format('YYYY-MM-DD HH:mm')}
            </p>
            <p>
              UpdatedAt: {dayjs(fileData.updatedAt).format('YYYY-MM-DD HH:mm')}
            </p>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          startIcon={
            isUpdating ? <CircularProgress size={20} /> : <UpdateIcon />
          }
          onClick={onUpdate}
          disabled={isProcessing}
        >
          Update
        </Button>

        <Button
          variant="contained"
          startIcon={
            isDeleting ? <CircularProgress size={20} /> : <DeleteIcon />
          }
          color="error"
          onClick={onDelete}
          disabled={isProcessing}
        >
          Delete
        </Button>

        <Button
          variant="outlined"
          color="error"
          onClick={onCancel}
          disabled={isProcessing}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
