import { FormProvider, useForm } from 'react-hook-form'
import { ExamFv } from '../../../types/exam'
import { ExamForm } from './ExamForm'
import { Container } from '@mui/material'
import { BgiLink } from '../../../UI/BgiLink'

export const CreateExam = () => {
  const formContext = useForm<ExamFv>({
    defaultValues: {
      courses: [],
      questionTypes: [
        { point: 5, count: '' },
        { point: 4, count: '' },
        { point: 3, count: '' },
        { point: 2, count: '' },
        { point: 1, count: '' },
      ],
    },
  })

  return (
    <Container maxWidth="md">
      <BgiLink to="/data-entry/exams">Back to the exams page</BgiLink>
      <div style={{ width: '100%', height: 30 }} />

      <FormProvider {...formContext}>
        <ExamForm />
      </FormProvider>

      <div style={{ width: '100%', height: 30 }} />
    </Container>
  )
}
