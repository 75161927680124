/** @jsxImportSource @emotion/react */

import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RemFormT } from '../../types/remedialTeaching';
import { useCreateRemMutation } from '../../store/remedialTeaching';
import { buildGetErrMsg } from '../../utils/buildGetErrMsg';
import { BgiTextField } from '../../UI/BgiTextField';
import { BgiLink } from '../../UI/BgiLink';
import { useSnackbar } from 'notistack';
import BgiCheckboxSelect from '../../UI/BgiCheckboxSelect';
import BgiAutocomplete from '../../UI/BgiAutocomplete';
import countries from '../../data/countries';
import { FormProvider, useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import { Container, Button, Paper } from '@mui/material';


interface RemProp {
}

const getErrMsg = buildGetErrMsg()


// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const RemedialTeaching: FC<RemProp> = () => {

  const formContext = useForm<RemFormT>({
    defaultValues: {
      studyType: [],
      selectedTime: [],
      selectedDays: [],
    }
  })
  const { handleSubmit, reset } = formContext
  const [createRem] = useCreateRemMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [isSending, setIsSending] = useState(false);


  const onSend = handleSubmit(async ({ country, ...restPayload }) => {
    const payload = {
      ...restPayload,
      countryCode: country.code
    }

    setIsSending(true)

    const result = await createRem(payload)

    if ('data' in result) {
      reset()
      enqueueSnackbar({ message: 'تم الارسال', variant: 'success' })
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
    setIsSending(false)
  })

  return (
    <>
      <Container maxWidth="sm" dir='rtl' >
        <BgiLink to="/">Back to Home page</BgiLink>
        <h2 css={styles.title}>حجز مقعد لدرس مجاني في فيزياء الصف التاسع</h2>
        <img css={styles.imgRem} src="/remedial-teaching2001.jpg" />
        <FormProvider {...formContext}>

          <form onSubmit={onSend}>
            <div >
              <Paper style={{ display: 'grid', gap: '1.5rem', padding: '1rem'}} elevation={5}>
                <BgiTextField
                  label="الاسم"
                  name="bookedStudentName"
                  fullWidth
                  size='small'
                  maxLength={64}
                  required
                  dir='rtl'
                  labelDirection='right'
                />

                <BgiTextField
                  label="الصف"
                  labelDirection='right'
                  name="classNumber"
                  fullWidth
                  size='small'
                  maxLength={32}
                  required
                />



                <BgiTextField
                  name="email"
                  label="البريد الإلكتروني"
                  type="email"
                  fullWidth
                  maxLength={32}
                  dir='ltr'
                  labelDirection='right'
                />

                <BgiTextField
                  name="mobile"
                  label="موبيل"
                  type="tel"
                  fullWidth
                  maxLength={32}
                  required
                  labelDirection='right'
                />

                <BgiTextField
                  name="whatsapp"
                  label="الواتس أب"
                  type="tel"
                  fullWidth
                  maxLength={32}
                  labelDirection='right'
                />

                <BgiTextField
                  name="telegram"
                  label="تلجرام"
                  fullWidth
                  maxLength={32}
                  labelDirection='right'
                  dir='ltr'
                  placeholder='تلجرام'
                />

                <BgiAutocomplete
                  name="country"
                  size='small'
                  label='أختير الدولة'
                  labelDirection='right'
                  options={countries}
                  groupBy={(country) => country.name[0]}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  required
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                />
                <div>
                  <BgiCheckboxSelect
                    css={styles.myCheckBox}
                    labelDirection='right'
                    name='studyType'
                    label='حدد نوع الدراسة'
                    options={[
                      { label: 'المنهج كاملاً', value: 'isAllCurriculum' },
                      { label: 'وحدات من المنهج', value: 'isCurriculumPart' },
                      { label: 'سبق دراستها في المدرسة', value: 'isStudied' },
                      { label: 'لم تدرس من قبل', value: 'isNewStudy' },
                      { label: 'دروس نظامية', value: 'isRegularLessons' },
                      { label: 'دروس تحضير للامتحانات', value: 'isExamPrepare' },
                    ]}
                  />

                  <BgiCheckboxSelect
                    css={styles.myCheckBox}
                    name="selectedTime"
                    label="حدد الساعات المفضلة"
                    labelDirection='right'
                    options={[
                      { label: '08:00 صباحاً', value: "8" },
                      { label: '10:00 صباحاً', value: "10" },
                      { label: '12:00 ظهراً', value: "12" },
                      { label: '02:00 ظهراً', value: "14" },
                      { label: '04:00 عصراً', value: "16" },
                      { label: '06:00 مساءً', value: "18" },
                      { label: '08:00 مساءً', value: "20" },
                      { label: '10:00 مساءً', value: "22" },
                    ]}
                  />

                  <BgiCheckboxSelect
                    style={{ width: '20rem' }}
                    css={styles.myCheckBox}
                    labelDirection='right'
                    name='selectedDays'
                    label='حدد الأيام التي ترغب بالدراسة بها'
                    options={[
                      { label: 'السبت', value: 'Saturday' },
                      { label: 'الأحد', value: 'Sunday' },
                      { label: 'الاثنين', value: 'Monday' },
                      { label: 'الثلاثاء', value: 'Tuesday' },
                      { label: 'الأربعاء', value: 'Wednesday' },
                      { label: 'الخميس', value: 'Thursday' },
                      { label: 'الجمعة', value: 'Friday' },
                    ]}
                  />
                </div>
                <BgiTextField
                  name="note"
                  label="إضافة ملاحظات"
                  fullWidth
                  maxLength={500}
                  rows={3}
                  multiline
                  labelDirection='right'
                />

                <Button
                  variant='contained'
                  type="submit"
                  disabled={isSending}
                >
                  إرسال
                </Button>

                <Button
                  variant='outlined'
                  color="error"
                  disabled={isSending}
                  onClick={() => reset()}
                >
                  تصفية
                </Button>
              </Paper>
            </div>
          </form>
        </FormProvider>
      </Container>
    </>
  )
}

export default RemedialTeaching


const styles = {
  title: css({
    display: 'block',
    margin: 'auto',
    background: 'beige',
    color: 'darkblue',
    borderRadius: '20%',
    height: '5rem',
    width: '50rem'
  }),
  imgRem: css({
    width: '34rem',
    display: 'block',
    margin: 'auto',
    borderRadius: '0.5rem'
  }),
  '.myTextField .MuiInputLabel-root': ({
    right: '0',
    left: 'auto',
    textAlign: 'right',
  }),
  myCheckBox: css({
    display: 'block',
    width: '200px',
    backgroundColor: 'beige',
  }),

}