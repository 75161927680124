import React, { CSSProperties, FC, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { FileData } from './FileForm'
import { Asset, AssetProps } from '../../UI/Asset'
import { FilesLib } from './FilesLib'
import { useFieldRules } from '../../hooks/useFieldRules'
import parseFieldErrorText from '../../utils/parseFieldErrorText'


interface AssetFieldProps {
  name: string
  required?: boolean
  fileKind: AssetProps['fileKind']
  label?: string
  assetWrapperStyle?: CSSProperties
  onChange?: (fileData: FileData | null) => void
}

export const AssetField: FC<AssetFieldProps> = ({
  name,
  required,
  fileKind,
  label,
  assetWrapperStyle,
  onChange: propOnChange
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { control } = useFormContext()

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const rules = useFieldRules({ required })

  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { value: untypedValue, onChange: rhfOnChange },
          fieldState: { error },
        }) => {
          const value = untypedValue as FileData | undefined

          const onChange = (fileData: FileData | null) => {
            rhfOnChange(fileData);
            propOnChange?.(fileData);
          }

          const handleSelect = (fileData: FileData) => {
            onChange(fileData)
            close()
          }

          const errorText = parseFieldErrorText(error)

          return (
            <>
              {label && (
                <h5 style={{ fontSize: 14, marginBottom: 6 }}>{label}</h5>
              )}

              <Asset
                value={value}
                updateButton={{ type: 'button', onClick: open }}
                createButton={{ type: 'button', onClick: open }}
                unsetButton={
                  !required
                    ? { type: 'button', onClick: () => onChange(null) }
                    : undefined
                }
                error={!!errorText}
                helperText={errorText}
                fileKind={fileKind}
                assetWrapperStyle={assetWrapperStyle}
              />

              <Dialog open={isOpen} maxWidth="md" fullWidth>
                <DialogTitle>Files Library</DialogTitle>

                <DialogContent>
                  <FilesLib
                    onSelect={handleSelect}
                    selectedFileId={value?.id}
                    disabledAssetUpdate
                    pageImagesLimit={10}
                    fileKind={fileKind}
                  />
                </DialogContent>

                <DialogActions>
                  <Button color="error" variant="outlined" onClick={close}>
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )
        }}
      />
    </div>
  )
}
