import { FormProvider, useForm } from 'react-hook-form'
import { SlideForm } from './SlideForm'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { axiosInstance } from '../../../utils/axiosInstance'
import { Unit } from '../../../types/unit'
import LoadingPage from '../../LoadingPage'
import { SlideFormValues } from '../../../utils/getSlidePayload'
import { useGetCategoriesQuery, useGetIncompletesQuery } from '../../../store/slidesApi'

const DEFAULT_ANSWERS = [...new Array(4)].fill({ type: 'checkboxAnswer', isCorrect: false, text: '' })

// ------only when i need some text in the field----------
// const texts = ['True', 'False'];
// const DEFAULT_ANSWERS: { type: 'checkboxAnswer'; isCorrect: boolean; text: string }[] = texts.map((text) => ({
//   type: 'checkboxAnswer',
//   isCorrect: false,
//   text: text
// }));



export const CreateSlide = () => {
  const [searchParams] = useSearchParams()

  const unitId = searchParams.get('unitId') ?? undefined

const data = new Date()
const isoString = data.toISOString()

  const formContext = useForm<{ slide: SlideFormValues }>({
    defaultValues: {
      slide: {
        type: 'question-checkboxes' as const,
        point: 2,
        categoryId: '',
        unavailable: false,
        // unitId,
        // TODO: make `answers` default value for question checkboxes only
        answers: DEFAULT_ANSWERS,
        incompletesIds: [],
        moreInfo: '',
        // slideNum: String(isoString),
        title: String(isoString)
      }
    },
  })

  const { setValue } = formContext

  const [loading, setLoading] = useState(!!unitId)

  const { data: categories } = useGetCategoriesQuery()
  const { data: incompletes } = useGetIncompletesQuery()

  useEffect(() => {
    if (!unitId) return
    ;(async () => {
      try {
        const res = await axiosInstance(`/units/${unitId}`)
        const unit: Unit | null = res.data

        if (!unit) return

        setValue('slide.unit', unit, {})

        setLoading(false)
      } catch {}
    })()
  }, [])

  if (loading || !categories || !incompletes) return <LoadingPage />

  return (
    <FormProvider {...formContext}>
      <SlideForm categories={categories} incompletes={incompletes} />
    </FormProvider>
  )
}
