import { FC, useMemo } from 'react'
import { Collection } from '../../types/collection'
import { Button, Card, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material'
import { Link, useSearchParams } from 'react-router-dom'
import { FILES_BASE_URL } from '../../constants/FILES_BASE_URL'
import { useAppSelector } from '../../store/hooks'
import { useTranslation } from 'react-i18next'

interface CollectionsGridProps {
  collections: Collection[]
  to: (collectionId: string) => string
}

const CollectionsGrid: FC<CollectionsGridProps> = ({ collections, to }) => {
  const [searchParams] = useSearchParams()
  const curriculum = searchParams.get('curriculum')
  const { t } = useTranslation(['Collections', 'common'])

  const userMe = useAppSelector((state) => state.auth.userMe)

  const urls: string[] = useMemo(
    () =>
      collections
        .filter((collection) => (collection ? collection.code.includes(`#${curriculum}#`) : true))
        .map(({ id }) => to(id)),
    [collections, curriculum]
  )
  
  if (collections.length === 0) {
    return <p>{t('There_is_no_collections_yet')}</p>
  }

  if (!curriculum) {
    return <p>Page NotFound</p>
  }

  return (
    <Grid container spacing={2} alignItems="stretch" style={{ direction: 'rtl' }}>
      {collections
        .filter((collection) => collection? collection.code.includes(`#${curriculum}#`): true)
        .map((collection, idx) => (
        <Grid item xs={12} sm={6} md={6} lg={4} key={collection.id}>
          <div style={{ width: '100%' }}>
            <Card
              sx={{
                width: '100%',
                height: '100%',
                display: 'block',
                color: 'inherit',
                textDecoration: 'none',
                paddingTop: 2,
                paddingBottom: 2,
              }}
              component={Link}
              to={`/collections/${collection.id}/subcollections`}
            >
              <Stack spacing={2}>
                <Typography sx={{ padding: '0 16px' }} variant="h6" component="div">
                  {collection.title}
                </Typography>

                {collection.imageFile && (
                  <CardMedia
                    style={{
                      width: '100%',
                      height: 200,
                      objectFit: 'cover',
                    }}
                    image={`${FILES_BASE_URL}/${collection.imageFile.fileHash}`}
                  />
                )}

                {collection.description.trim().length > 0 && (
                  <Typography variant="body2" color="text.secondary" component="div" sx={{ padding: '0 16px' }}>
                    {collection.description}
                  </Typography>
                )}
              </Stack>
            </Card>

            {userMe?.role === 'admin' && (
              <Button component={Link} to={urls[idx]} variant="outlined">
                {t('common:Update')}
              </Button>
            )}
          </div>
        </Grid>
      ))}
    </Grid>
  )
}

export default CollectionsGrid
