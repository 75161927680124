import inverseObj from './inverseObj'

export const contentTypes: Record<string, string[]> = {
  'image/gif': ['gif'],
  'image/jpeg': ['jpeg', 'jpg'],
  'image/png': ['png'],
  'image/svg+xml': ['svg'],
  'image/webp': ['webp'],
  'audio/mpeg': ['mp3'],
  'audio/ogg': ['ogg'],
  'audio/webm': ['webm'],
}

export const extensions = inverseObj(contentTypes)

export const getContentType = (extension: string): string | undefined => {
  return extensions[extension]
}

export const getExtension = (contentType: string): string | undefined => {
  return contentTypes[contentType][0]
}
