import { Container } from '@mui/system'
import { Link } from 'react-router-dom'
import { useGetExamsQuery } from '../../../store/examsApi'
import { BgiLink } from '../../../UI/BgiLink'
import PageHeader from '../../../UI/PageHeader'
import LoadingPage from '../../LoadingPage'
import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ExamsPage = () => {
  const { isLoading, error, data: exams } = useGetExamsQuery()
  const { t } = useTranslation(['common', 'Exams'])

  if (isLoading) return <LoadingPage />

  return (
    <Container maxWidth="md">

      <div style={{ width: '100%', height: 30 }} />

      <PageHeader
        style={{ marginBottom: 24 }}
        title="Exams"
        actions={
          <Button
            component={Link}
            to="/data-entry/exams/create"
            type="submit"
            variant="contained"
          >
            {t`Create_exam`}
          </Button>
        }
      />

      {error || !exams ? (
        <p>Unknown Error occurred</p>
      ) : (
        <Stack spacing={2}>
          {exams.map((exam) => (
            <div key={exam.id}>
              <BgiLink
                style={{ marginRight: 16 }}
                to={`/data-entry/exams/${exam.id}/update`}
              >
                {exam.title}
              </BgiLink>
              (
              {exam.isStandard ? (
                'standard'
              ) : (
                <BgiLink to={`/exams/${exam.token}`}>
                  {t('common:Open')}
                </BgiLink>
              )}
              )
            </div>
          ))}
        </Stack>
      )}

      <div style={{ width: '100%', height: 30 }} />
    </Container>
  )
}

export default ExamsPage
