import { Alert, Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../../hooks/useFetch'
import { Exam, ExamFv } from '../../../types/exam'
import { ExamForm } from './ExamForm'
import LoadingPage from '../../LoadingPage'
import { BgiLink } from '../../../UI/BgiLink'
import { useState } from 'react'

export const UpdateExam = () => {
  const { examId } = useParams()
  const formContext = useForm<ExamFv>()

  const [restValues, setRestValues] = useState<Pick<
    Exam,
    'isStandard' | 'token'
  > | null>(null)

  const [loading, exam, error] = useFetch<Exam>(`/exams/${examId}`, (exam) => {
    if (exam) {
      const resRestValues: Record<string, any> = {}

      Object.keys(exam).forEach((untypedAttrKey) => {
        const attrKey = untypedAttrKey as keyof Exam
        if (attrKey !== 'isStandard' && attrKey !== 'token') {
          formContext.setValue(attrKey, exam[attrKey])
        } else {
          resRestValues[attrKey] = exam[attrKey]
        }
      })

      setRestValues(resRestValues as typeof restValues)
    }
  })

  if (!restValues) return <LoadingPage />

  return (
    <Container maxWidth="md">
      {error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
          <BgiLink to="/data-entry/exams">Back to the exams page</BgiLink>

          <div style={{ width: '100%', height: 30 }} />

          <FormProvider {...formContext}>
            <ExamForm {...restValues} />
          </FormProvider>

          <div style={{ width: '100%', height: 30 }} />
        </>
      )}
    </Container>
  )
}
