import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `bearer ${localStorage.getItem('token')}`;
  return config;
});
