import { axiosInstance } from './axiosInstance';

interface UploadFileInput {
  formData: FormData;
  onUploadProgress: (progress: number) => void;
  path?: string;
  method?: 'patch' | 'post';
}

export const uploadFile = ({
  formData,
  onUploadProgress,
  path,
  method,
}: UploadFileInput) => {
  if (onUploadProgress) onUploadProgress(0);

  return axiosInstance[method ? method : 'post'](
    path ? path : '/files/upload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },

      onUploadProgress: (progressEvent) => {
        if (onUploadProgress)
          onUploadProgress(
            Math.floor((progressEvent.loaded / progressEvent.total!) * 100)
          );
      },
    }
  );
};
