import { css } from '@emotion/css'
import { SlideView } from '../../../types/SlideView'
import { YoutubeVideoSlide } from '../../../types/slide'
import VideoUi from '../../../UI/video/VideoUi'

const SlideViewYoutubeVideo: SlideView<YoutubeVideoSlide> = ({ slide, nextSlide, prevSlide }) => {
  return <VideoUi videoId={slide.youtubeVideoSlug} onSwipeLeft={prevSlide} onSwipeRight={nextSlide} />
  // return (
  //   <iframe
  //     className={styles.root}
  //     src={`https://www.youtube.com/embed/${slide.youtubeVideoSlug}`}
  //     title="YouTube video player"
  //     frameBorder="0"
  //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  //     allowFullScreen
  //   />
  // )
}

const styles = {
  root: css({
    width: '100%',
    height: '80%',
    '@media screen and (max-width: 676px)': {
      height: '100%',
    },
  }),
}

export default SlideViewYoutubeVideo
