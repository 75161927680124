import { FC } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useFieldRules } from '../hooks/useFieldRules'
import parseFieldErrorText from '../utils/parseFieldErrorText'
import { determineTextDirection } from '../utils/determineTextDirection'

type BgiTextFieldProps = TextFieldProps & {
  name: string
  required?: boolean
  maxLength?: number
  minLength?: number
  pattern?: RegExp
  backgroundColor?: string,
  inputRule?: 'natural-number'
  labelDirection?: 'left' | 'right',
  processValue?: (val: string) => string
}

export const BgiTextField: FC<BgiTextFieldProps> = ({
  name,
  // rules,
  size = 'small',
  label,
  backgroundColor,
  required,
  maxLength,
  minLength,
  pattern,
  inputRule,
  labelDirection,
  processValue,
  ...textFieldProps
}) => {
  const { control } = useFormContext()

  const rules = useFieldRules({ required, maxLength, minLength, pattern })


  return (
    <Controller
      control={control}
      name={name}
      defaultValue={''}
      rules={rules}
      render={({
        field: { ref, value, onChange, onBlur },
        fieldState: { error },
      }) => {
        const errorText = parseFieldErrorText(error)

        return (
          <TextField
            {...textFieldProps}
            label={required && label ? label + ' ' + '*' : label}
            size={size}
            inputRef={ref}
            onBlur={onBlur}
            name={name}
            value={value}
            dir={determineTextDirection(value)}
            // multiline
            onChange={(e) => {
              const { value } = e.target

              if (inputRule === 'natural-number' && !/^[0-9]*$/.test(value)) {
                return
              }

              onChange(processValue ? processValue(value) : value)
            }}
            fullWidth
            error={!!errorText}
            helperText={errorText}
            InputProps={{
              style:{
                backgroundColor: backgroundColor || 'transparent',
              }
            }}
            InputLabelProps={{
              style: {
                textAlign: labelDirection === 'right' ? 'right' : 'left',
                right: labelDirection === 'right' ? '1.2rem' : 'auto',
                left: labelDirection === 'right' ? 'auto' : '0',
                position: 'absolute',
              }
            }}
          />
        )
      }}
    />
  )
}
