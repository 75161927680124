import { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import UnitFormPage, { UnitForm } from './UnitFormPage'
import { useGetUnitQuery } from '../../../store/unitsApi'
import { Container } from '@mui/material'
import LoadingPage from '../../LoadingPage'

export const UnitUpdatePage = () => {
  const unitForm = useForm<UnitForm>()
  const { setValue } = unitForm
  const { unitId } = useParams() as { [k: string]: string }
  const [loading, setLoading] = useState(true)

  const { data: unit } = useGetUnitQuery(unitId)

  useEffect(() => {
    if (unit) {
      const { slidesCount, ...restUnit } = unit
      setValue('unit', restUnit)
      setLoading(false)
    }
  }, [unit])

  if (loading) return <LoadingPage />

  return (
    <Container maxWidth="md">
      <FormProvider {...unitForm}>
        <UnitFormPage />
      </FormProvider>
    </Container>
  )
}
