/** @jsxImportSource @emotion/react */

import { FC, useMemo, Fragment } from 'react'
import MathJax from 'react-mathjax'
import ReactMarkdown from 'react-markdown';
import { css } from '@emotion/react'



interface TextWithMathProps {
  value: string
}

const processText = (inputText: string) => {
  if (!inputText) return [];
  const output: {
    type: 'normal-text' | 'math' | 'markdown-text'
    content: string
  }[] = []
  const mathRegex = /\$(.*?)\$/g
  // const markdownRegex = /\#(.*?)\#/g
  const markdownRegex = /\(\#(.*?)\#\)/g;


  let lastIndex = 0
  let match

  // معالجة النص الرياضي
  while ((match = mathRegex.exec(inputText)) !== null) {
    // إضافة النص العادي قبل النص الرياضي
    if (match.index > lastIndex) {
      output.push({
        type: 'normal-text',
        content: inputText.substring(lastIndex, match.index),
      });
    }

    // إضافة النص الرياضي
    output.push({
      type: 'math',
      content: match[1],
    });

    lastIndex = mathRegex.lastIndex;
  }

  // معالجة النص المتبقي للبحث عن Markdown
  const remainingText = inputText.substring(lastIndex);
  lastIndex = 0;

  while ((match = markdownRegex.exec(remainingText)) !== null) {
    // إضافة النص العادي قبل Markdown
    if (match.index > lastIndex) {
      output.push({
        type: 'normal-text',
        content: remainingText.substring(lastIndex, match.index),
      });
    }

    // إضافة النص بتنسيق Markdown
    output.push({
      type: 'markdown-text',
      content: match[1],
    });

    lastIndex = markdownRegex.lastIndex;
  }

  // إضافة أي نص عادي متبقي
  if (lastIndex < remainingText.length) {
    output.push({
      type: 'normal-text',
      content: remainingText.substring(lastIndex),
    });
  }

  return output;
};


const TextWithMath: FC<TextWithMathProps> = ({ value }) => {
  const parts = useMemo(() => (value ? processText(value): []), [value])

  return (
    <MathJax.Provider>
      {parts.map((part, i) => (
        <Fragment key={i}>
          {part.type === 'math' 
          ? (<MathJax.Node inline formula={part.content} />) 
          : (part.type === 'markdown-text' 
          ? (<ReactMarkdown css={styles.react_markdown}>{part.content}</ReactMarkdown>) 
          : ( part.content ))}
        </Fragment>
      ))}
    </MathJax.Provider>
  );
}


const styles ={
  react_markdown: css({
    fontSize: '1.1rem'
  })
}

export default TextWithMath








// ---the original ----------------------------------------------------
// --------------------------------------------------------------------
// --------------------------------------------------------------------

// import { FC, useMemo, Fragment } from 'react'
// import MathJax from 'react-mathjax'
// import ReactMarkdown from 'react-markdown';


// interface TextWithMathProps {
//   value: string
// }

// const processText = (inputText: string) => {
//   const output: {
//     type: 'normal-text' | 'math' | 'markdown-text'
//     content: string
//   }[] = []
//   const regex = /\$(.*?)\$/g
//   const markdownRegex = /\#\#(.*?)\#\#/g


//   // Find all the matches and their indexes
//   const matches = []

//   let match
//   while ((match = regex.exec(inputText)) !== null) {
//     matches.push({
//       match: match[1],
//       index: match.index,
//       endIndex: regex.lastIndex,
//     })
//   }

//   let startIndex = 0

//   // Iterate over the matches
//   for (let i = 0; i < matches.length; i++) {
//     // If there's normal text before this match, add it
//     if (matches[i].index > startIndex) {
//       output.push({
//         type: 'normal-text',
//         content: inputText.substring(startIndex, matches[i].index),
//       })
//     }

//     // Add the math content
//     output.push({
//       type: 'math',
//       content: matches[i].match,
//     })

//     // Update the start index
//     startIndex = matches[i].endIndex
//   }

//   // If there's any normal text left at the end, add it
//   if (startIndex < inputText.length) {
//     output.push({
//       type: 'normal-text',
//       content: inputText.substring(startIndex),
//     })
//   }

//   return output
// }

// const TextWithMath: FC<TextWithMathProps> = ({ value }) => {
//   const parts = useMemo(() => processText(value), [value])

//   return (
//     <MathJax.Provider>
//       {parts.map((part, i) => (
//         <Fragment key={i}>
//           {part.type === 'math' 
//           ? (<MathJax.Node inline formula={part.content} />) 
//           : part.type === 'markdown-text' 
//           ? (<ReactMarkdown>{part.content}</ReactMarkdown>) 
//           : ( part.content )}
//         </Fragment>
//       ))}
//     </MathJax.Provider>
//   );

// }

// export default TextWithMath
