export interface Country {
  name: string
  code: string
}

const countries: Country[] = [
  {
    name: 'آيسلندا',
    code: 'IS',
  },
  {
    name: 'أذربيجان',
    code: 'AZ',
  },
  {
    name: 'أرمينيا',
    code: 'AM',
  },
  {
    name: 'أستراليا',
    code: 'AU',
  },
  {
    name: 'أفغانستان',
    code: 'AF',
  },
  {
    name: 'ألبانيا',
    code: 'AL',
  },
  {
    name: 'ألمانيا',
    code: 'DE',
  },
  {
    name: 'أنتيغوا وباربودا',
    code: 'AG',
  },
  {
    name: 'أندورا',
    code: 'AD',
  },
  {
    name: 'أوزبكستان',
    code: 'UZ',
  },
  {
    name: 'أوغندا',
    code: 'UG',
  },
  {
    name: 'أوكرانيا',
    code: 'UA',
  },
  {
    name: 'إثيوبيا',
    code: 'ET',
  },
  {
    name: 'إريتريا',
    code: 'ER',
  },
  {
    name: 'إسبانيا',
    code: 'ES',
  },
  {
    name: 'إستونيا',
    code: 'EE',
  },
  {
    name: 'إسواتيني',
    code: 'SZ',
  },
  {
    name: 'إندونيسيا',
    code: 'ID',
  },
  {
    name: 'إيران',
    code: 'IR',
  },
  {
    name: 'إيطاليا',
    code: 'IT',
  },
  {
    name: 'الأرجنتين',
    code: 'AR',
  },
  {
    name: 'الأردن',
    code: 'JO',
  },
  {
    name: 'الأوروغواي',
    code: 'UY',
  },
  {
    name: 'الإكوادور',
    code: 'EC',
  },
  {
    name: 'الإمارات',
    code: 'AE',
  },
  {
    name: 'البحرين',
    code: 'BH',
  },
  {
    name: 'البرازيل',
    code: 'BR',
  },
  {
    name: 'البرتغال',
    code: 'PT',
  },
  {
    name: 'البوسنة والهرسك',
    code: 'BA',
  },
  {
    name: 'التشيك',
    code: 'CZ',
  },
  {
    name: 'الجزائر',
    code: 'DZ',
  },
  {
    name: 'الدنمارك',
    code: 'DK',
  },
  {
    name: 'السعودية',
    code: 'SA',
  },
  {
    name: 'السلفادور',
    code: 'SV',
  },
  {
    name: 'السنغال',
    code: 'SN',
  },
  {
    name: 'السودان',
    code: 'SD',
  },
  {
    name: 'السويد',
    code: 'SE',
  },
  {
    name: 'الصومال',
    code: 'SO',
  },
  {
    name: 'الصين',
    code: 'CN',
  },
  {
    name: 'العراق',
    code: 'IQ',
  },
  {
    name: 'الغابون',
    code: 'GA',
  },
  {
    name: 'الفلبين',
    code: 'PH',
  },
  {
    name: 'الكاميرون',
    code: 'CM',
  },
  {
    name: 'الكويت',
    code: 'KW',
  },
  {
    name: 'المالديف',
    code: 'MV',
  },
  {
    name: 'المجر',
    code: 'HU',
  },
  {
    name: 'المغرب',
    code: 'MA',
  },
  {
    name: 'المكسيك',
    code: 'MX',
  },
  {
    name: 'النرويج',
    code: 'NO',
  },
  {
    name: 'النمسا',
    code: 'AT',
  },
  {
    name: 'النيجر',
    code: 'NE',
  },
  {
    name: 'الهند',
    code: 'IN',
  },
  {
    name: 'الولايات المتحدة',
    code: 'US',
  },
  {
    name: 'اليابان',
    code: 'JP',
  },
  {
    name: 'اليمن',
    code: 'YE',
  },
  {
    name: 'اليونان',
    code: 'GR',
  },
  {
    name: 'بابوا غينيا الجديدة',
    code: 'PG',
  },
  {
    name: 'باراغواي',
    code: 'PY',
  },
  {
    name: 'باربادوس',
    code: 'BB',
  },
  {
    name: 'باكستان',
    code: 'PK',
  },
  {
    name: 'بالاو',
    code: 'PW',
  },
  {
    name: 'باهاماس',
    code: 'BS',
  },
  {
    name: 'بروناي',
    code: 'BN',
  },
  {
    name: 'بريطانيا (المملكة المتحدة)',
    code: 'GB',
  },
  {
    name: 'بلجيكا',
    code: 'BE',
  },
  {
    name: 'بلغاريا',
    code: 'BG',
  },
  {
    name: 'بليز',
    code: 'BZ',
  },
  {
    name: 'بنغلاديش',
    code: 'BD',
  },
  {
    name: 'بنما',
    code: 'PA',
  },
  {
    name: 'بنين',
    code: 'BJ',
  },
  {
    name: 'بوتان',
    code: 'BT',
  },
  {
    name: 'بوتسوانا',
    code: 'BW',
  },
  {
    name: 'بوركينا فاسو',
    code: 'BF',
  },
  {
    name: 'بوروندي',
    code: 'BI',
  },
  {
    name: 'بولندا',
    code: 'PL',
  },
  {
    name: 'بوليفيا',
    code: 'BO',
  },
  {
    name: 'بيرو',
    code: 'PE',
  },
  {
    name: 'بيلاروس',
    code: 'BY',
  },
  {
    name: 'تايلاند',
    code: 'TH',
  },
  {
    name: 'تركمانستان',
    code: 'TM',
  },
  {
    name: 'تركيا',
    code: 'TR',
  },
  {
    name: 'ترينيداد وتوباغو',
    code: 'TT',
  },
  {
    name: 'تشاد',
    code: 'TD',
  },
  {
    name: 'تشيلي',
    code: 'CL',
  },
  {
    name: 'تنزانيا',
    code: 'TZ',
  },
  {
    name: 'توغو',
    code: 'TG',
  },
  {
    name: 'توفالو',
    code: 'TV',
  },
  {
    name: 'تونس',
    code: 'TN',
  },
  {
    name: 'تونغا',
    code: 'TO',
  },
  {
    name: 'تيمور الشرقية',
    code: 'TL',
  },
  {
    name: 'جامايكا',
    code: 'JM',
  },
  {
    name: 'جزر القمر',
    code: 'KM',
  },
  {
    name: 'جزر سليمان',
    code: 'SB',
  },
  {
    name: 'جزر مارشال',
    code: 'MH',
  },
  {
    name: 'جمهورية أفريقيا الوسطى',
    code: 'CF',
  },
  {
    name: 'جمهورية الدومينيكان',
    code: 'DO',
  },
  {
    name: 'جمهورية الكونغو',
    code: 'CG',
  },
  {
    name: 'جمهورية الكونغو الديمقراطية',
    code: 'CD',
  },
  {
    name: 'جنوب أفريقيا',
    code: 'ZA',
  },
  {
    name: 'جنوب السودان',
    code: 'SS',
  },
  {
    name: 'جورجيا',
    code: 'GE',
  },
  {
    name: 'جيبوتي',
    code: 'DJ',
  },
  {
    name: 'دومينيكا',
    code: 'DM',
  },
  {
    name: 'رواندا',
    code: 'RW',
  },
  {
    name: 'روسيا',
    code: 'RU',
  },
  {
    name: 'رومانيا',
    code: 'RO',
  },
  {
    name: 'زامبيا',
    code: 'ZM',
  },
  {
    name: 'زيمبابوي',
    code: 'ZW',
  },
  {
    name: 'ساحل العاج',
    code: 'CI',
  },
  {
    name: 'ساموا',
    code: 'WS',
  },
  {
    name: 'سان مارينو',
    code: 'SM',
  },
  {
    name: 'سانت فينسنت والغرينادين',
    code: 'VC',
  },
  {
    name: 'سانت كيتس ونيفيس',
    code: 'KN',
  },
  {
    name: 'سانت لوسيا',
    code: 'LC',
  },
  {
    name: 'ساو تومي وبرينسيب',
    code: 'ST',
  },
  {
    name: 'سريلانكا',
    code: 'LK',
  },
  {
    name: 'سلطنة عمان',
    code: 'OM',
  },
  {
    name: 'سلوفاكيا',
    code: 'SK',
  },
  {
    name: 'سلوفينيا',
    code: 'SI',
  },
  {
    name: 'سنغافورة',
    code: 'SG',
  },
  {
    name: 'سوريا',
    code: 'SY',
  },
  {
    name: 'سورينام',
    code: 'SR',
  },
  {
    name: 'سويسرا',
    code: 'CH',
  },
  {
    name: 'سيراليون',
    code: 'SL',
  },
  {
    name: 'سيشل',
    code: 'SC',
  },
  {
    name: 'صربيا',
    code: 'RS',
  },
  {
    name: 'طاجيكستان',
    code: 'TJ',
  },
  {
    name: 'غامبيا',
    code: 'GM',
  },
  {
    name: 'غانا',
    code: 'GH',
  },
  {
    name: 'غرينادا',
    code: 'GD',
  },
  {
    name: 'غواتيمالا',
    code: 'GT',
  },
  {
    name: 'غيانا',
    code: 'GY',
  },
  {
    name: 'غينيا',
    code: 'GN',
  },
  {
    name: 'غينيا الاستوائية',
    code: 'GQ',
  },
  {
    name: 'غينيا بيساو',
    code: 'GW',
  },
  {
    name: 'فانواتو',
    code: 'VU',
  },
  {
    name: 'فرنسا',
    code: 'FR',
  },
  {
    name: 'فلسطين',
    code: 'PS',
  },
  {
    name: 'فنزويلا',
    code: 'VE',
  },
  {
    name: 'فنلندا',
    code: 'FI',
  },
  {
    name: 'فيتنام',
    code: 'VN',
  },
  {
    name: 'فيجي',
    code: 'FJ',
  },
  {
    name: 'قبرص',
    code: 'CY',
  },
  {
    name: 'قطر',
    code: 'QA',
  },
  {
    name: 'قيرغيزستان',
    code: 'KG',
  },
  {
    name: 'كابو فيردي (الرأس الأخضر)',
    code: 'CV',
  },
  {
    name: 'كازاخستان',
    code: 'KZ',
  },
  {
    name: 'كرواتيا',
    code: 'HR',
  },
  {
    name: 'كمبوديا',
    code: 'KH',
  },
  {
    name: 'كندا',
    code: 'CA',
  },
  {
    name: 'كوبا',
    code: 'CU',
  },
  {
    name: 'كوريا الجنوبية',
    code: 'KR',
  },
  {
    name: 'كوريا الشمالية',
    code: 'KP',
  },
  {
    name: 'كوستاريكا',
    code: 'CR',
  },
  {
    name: 'كولومبيا',
    code: 'CO',
  },
  {
    name: 'كيريباتي',
    code: 'KI',
  },
  {
    name: 'كينيا',
    code: 'KE',
  },
  {
    name: 'لاتفيا',
    code: 'LV',
  },
  {
    name: 'لاوس',
    code: 'LA',
  },
  {
    name: 'لبنان',
    code: 'LB',
  },
  {
    name: 'لوكسمبورغ',
    code: 'LU',
  },
  {
    name: 'ليبيا',
    code: 'LY',
  },
  {
    name: 'ليبيريا',
    code: 'LR',
  },
  {
    name: 'ليتوانيا',
    code: 'LT',
  },
  {
    name: 'ليختنشتاين',
    code: 'LI',
  },
  {
    name: 'ليسوتو',
    code: 'LS',
  },
  {
    name: 'مالاوي',
    code: 'MW',
  },
  {
    name: 'مالطا',
    code: 'MT',
  },
  {
    name: 'مالي',
    code: 'ML',
  },
  {
    name: 'ماليزيا',
    code: 'MY',
  },
  {
    name: 'مدغشقر',
    code: 'MG',
  },
  {
    name: 'مصر',
    code: 'EG',
  },
  {
    name: 'مقدونيا الشمالية',
    code: 'MK',
  },
  {
    name: 'منغوليا',
    code: 'MN',
  },
  {
    name: 'موريتانيا',
    code: 'MR',
  },
  {
    name: 'موريشيوس',
    code: 'MU',
  },
  {
    name: 'موزمبيق',
    code: 'MZ',
  },
  {
    name: 'مولدوفا',
    code: 'MD',
  },
  {
    name: 'موناكو',
    code: 'MC',
  },
  {
    name: 'مونتينيغرو (الجبل الأسود)',
    code: 'ME',
  },
  {
    name: 'ميانمار',
    code: 'MM',
  },
  {
    name: 'ناميبيا',
    code: 'NA',
  },
  {
    name: 'ناورو',
    code: 'NR',
  },
  {
    name: 'نيبال',
    code: 'NP',
  },
  {
    name: 'نيجيريا',
    code: 'NG',
  },
  {
    name: 'نيكاراغوا',
    code: 'NI',
  },
  {
    name: 'نيوزيلندا',
    code: 'NZ',
  },
  {
    name: 'هايتي',
    code: 'HT',
  },
  {
    name: 'هندوراس',
    code: 'HN',
  },
  {
    name: 'هولندا',
    code: 'NL',
  },
].sort(
  (a, b) => -b.name[0].localeCompare(a.name[0])
)

export default countries
