type Cb = () => void

let isYtApiReady = false
const ytApiCallbacks: Cb[] = []

export function handleYtApiReady() {
  isYtApiReady = true
  ytApiCallbacks.forEach(cb => cb())
}

export function enqueueYtApiCallback (cb: Cb) {
  if (!isYtApiReady) {
    ytApiCallbacks.push(cb)
  } else {
    cb()
  }
}
