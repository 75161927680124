import SlideViewQuestionCheckboxes, { questionCheckboxDefaultAnswer,} from './SlideViewQuestionCheckboxes'
import SlideViewQuestionInput, { questionInputDefaultAnswer,} from './SlideViewQuestionInput'
import SlideViewQuestionCards, { questionCardsDefaultAnswer } from './SlideViewQuestionCards'
import SlideViewQuestionMultiInputs, { questionMultiInputsDefaultAnswer } from './SlideViewQuestionMultiInputs'
import SlideViewImage from './SlideViewImage'
import AuoGenSlideViewBannerAd from './AuoGenSlideViewBannerAd'
import SlideViewYoutubeVideo from './SlideViewYoutubeVideo'
import SlideOnlyText from './SlideViewOnlyText'
import SlideViewFlashcard from './SlideViewFlashcard'

export const slideDefaultValues = {
  'question-checkboxes': questionCheckboxDefaultAnswer,
  'question-cards': questionCardsDefaultAnswer,
  'question-input': questionInputDefaultAnswer,
  'question-multi-inputs': questionMultiInputsDefaultAnswer,
}

export const slideViews = {
  'question-checkboxes': SlideViewQuestionCheckboxes,
  'question-cards': SlideViewQuestionCards,
  'question-multi-inputs': SlideViewQuestionMultiInputs,
  'question-input': SlideViewQuestionInput,
  image: SlideViewImage,
  'banner-ad': AuoGenSlideViewBannerAd,
  'youtube-video': SlideViewYoutubeVideo,
  'only-text': SlideOnlyText,
  'flashcard': SlideViewFlashcard,
}
