import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Ad, AdI } from '../types/ad';
import { apiConfig } from './api';

export const adsApi = createApi({
  reducerPath: 'adsApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/ads`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getAds: builder.query<Ad[], void>({
      query: () => '',
    }),
    getAd: builder.query<Ad | null, string>({
      query: (adId) => `/${adId}`,
    }),
    createAd: builder.mutation<Ad, AdI>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),
    updateAd: builder.mutation<Ad, Partial<AdI> & Pick<Ad, 'id'>>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
    }),
    deleteAd: builder.mutation<void, string>({
      query: (adId) => ({ url: `/${adId}`, method: 'DELETE' }),
    }),
  }),
});

export const {
  useGetAdsQuery,
  useGetAdQuery,
  useCreateAdMutation,
  useUpdateAdMutation,
  useDeleteAdMutation,
} = adsApi;
