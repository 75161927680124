import { useParams } from 'react-router-dom'
import LoadingPage from '../LoadingPage'
import NotFound from '../NotFound'
import { useEffect } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { resetSlidesState } from '../../store/slides'
import { useGetSpecialExamQuery } from '../../store/examsApi'
import PageSlidesView from '../slides/PageSlidesView'
import { TopLinearProgress } from '../TopLinearProgress'
import BeforeExam from './BeforeExam'
import AfterExam from './AfterExam'

const SpecialExamView = () => {
  const { examToken } = useParams() as { examToken: string }

  const { isFetching: isSlidesFetching, data, error } = useGetSpecialExamQuery(examToken)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(resetSlidesState())
  }, [])

  if (isSlidesFetching) {
    return <TopLinearProgress />
  }

  if (error) {
    return (
      <p style={{ textAlign: 'center' }}>
        Please check your internet connection
      </p>
    )
  }

  if (data) {
    if ((typeof data == "object") && 'test_case' in data)
    if(data.test_case == 'beforeExam' && 'startTime' in data)
      return <BeforeExam startTime={ data.startTime}/>
      else if (data.test_case == 'afterExam')
      return <AfterExam/>
  }


  if (!data) return <NotFound />


  const { slides, units, filesHashes, stickyAds, bannerAd } = data

  if (slides.length === 0) {
    return <p style={{ textAlign: 'center' }}>No Slides yet</p>
  }

  return (
    <PageSlidesView
      dir="rtl"
      slides={slides}
      units={units}
      filesHashes={filesHashes}
      stickyAds={stickyAds}
      isThereBannerAd={!!bannerAd}
      isCurExam
    />
  )
}

export default SpecialExamView
