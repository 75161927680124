// TODO: support pagination
// TODO: debounce (e.g. useDebounce) search requests
import { useState, ReactNode, useMemo, Ref, CSSProperties } from 'react'
import ReactSelect, { InputActionMeta } from 'react-select'
import { axiosInstance } from '../utils/axiosInstance'
import { useClickOutside } from '../hooks/useClickOutside'
import { FormControl, FormHelperText } from '@mui/material'

export interface SelectOptionProcessed {
  label: string
  value: string
}

interface RenderPreInputParams {
  value: string
  onChange: (newValue: string) => void
  onFocus: () => void
}

export interface AsyncSelectSearchProps<
  V extends Record<string | number | symbol, any>
> {
  requestPathname: string
  mainInputParam: string
  preInputParam?: string
  renderPreInput?: (params: RenderPreInputParams) => ReactNode
  processOption: (optionData: V) => SelectOptionProcessed
  noOptionsMessage: string
  value: V | null
  onChange: (newValue: V | null) => void
  placeholder: string
  inputRef?: Ref<any>
  name?: string
  error?: boolean
  helperText?: string
  style?: CSSProperties
  retainUnmatchedValueWhenClose?: boolean
}

export const AsyncSelectSearch = <
  V extends Record<string | number | symbol, any>
>({
  renderPreInput,
  name,
  processOption,
  requestPathname,
  mainInputParam,
  preInputParam,
  noOptionsMessage,
  placeholder,
  error,
  helperText,
  value,
  onChange,
  inputRef,
  style,
  retainUnmatchedValueWhenClose,
}: AsyncSelectSearchProps<V>) => {
  const [mainInputValue, setMainInputValue] = useState('')
  const [preInputValue, setPreInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState<V[]>([])
  const [isOpen, setIsOpen] = useState(false)

  const fetchOptions = async (
    mainInputValue: string,
    preInputValue: string
  ) => {
    const trimmedMainInputValue = mainInputValue.trim()

    setIsLoading(true)
    setOptions([])

    const params = new URLSearchParams()
    params.set(mainInputParam, encodeURIComponent(trimmedMainInputValue))
    if (preInputParam) params.set(preInputParam, encodeURIComponent(preInputValue.trim()))

    const response = await axiosInstance.get(
      `${requestPathname}?${params.toString()}`
    )

    setOptions(response.data)
    setIsLoading(false)
  }

  const handleMainInputChange = async (
    newMainInputValue: string,
    { action }: InputActionMeta
  ) => {
    if (action === 'input-blur' || action === 'menu-close') return
    setMainInputValue(newMainInputValue)
    fetchOptions(newMainInputValue, preInputValue)
  }

  const handlePreInputChange = async (newPreInputValue: string) => {
    setPreInputValue(newPreInputValue)
    fetchOptions(mainInputValue, newPreInputValue)
  }

  const handlePreInputFocus = () => {
    setIsOpen(true)
  }

  const handleValueChange =
    (onChange: (v: any) => void) =>
    (newValue: SelectOptionProcessed | null) => {
      // const option
      const optionData =
        options.find(
          (option) => processOption(option).value === newValue?.value
        ) ?? null

      onChange(optionData)

      if (newValue) closeMenu()
    }

  const closeMenu = async () => {
    setIsOpen(false)

    if (!retainUnmatchedValueWhenClose) {
      setMainInputValue('')
      setOptions([])
    }
  }

  const boxRef = useClickOutside(closeMenu)

  const processedOptions = useMemo(() => options.map(processOption), [options])

  const processedValue = useMemo(
    () => (value ? processOption(value) : null),
    [value]
  )

  return (
    <div style={style} ref={boxRef as any}>
      {renderPreInput &&
        renderPreInput({
          onChange: handlePreInputChange,
          onFocus: handlePreInputFocus,
          value: preInputValue,
        })}

      <FormControl fullWidth error={error}>
        <ReactSelect
          ref={inputRef}
          name={name}
          inputValue={mainInputValue}
          onInputChange={handleMainInputChange}
          options={processedOptions}
          isLoading={isLoading}
          placeholder={placeholder}
          noOptionsMessage={({ inputValue }) =>
            isLoading
              ? 'Loading...'
              : inputValue.trim().length > 0
              ? noOptionsMessage
              : 'Type to start searching'
          }
          menuIsOpen={isOpen}
          onMenuOpen={() => setIsOpen(true)}
          value={processedValue}
          onChange={handleValueChange(onChange)}
          isClearable
          styles={{
            control: (styles) => ({
              ...styles,
              ...(error
                ? {
                    borderColor: '#d32f2f',
                    ':hover': {
                      ...(styles[':hover'] ?? {}),
                      borderColor: '#d32f2f',
                    },
                  }
                : {}),
            }),
            placeholder: (styles) => ({
              ...styles,
              ...(error ? { color: '#d32f2f' } : {}),
            }),
            menu: (styles) => ({
              ...styles,
              zIndex: 10,
            }),
          }}
        />

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  )
}
