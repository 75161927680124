import { useMemo, useState } from 'react'
import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL'
import { useFormContext, useWatch } from 'react-hook-form'
import { useAppSelector } from '../../../store/hooks'
import { SlideView } from '../../../types/SlideView'
import { QuestionCheckboxesSlide } from '../../../types/slide'

import TextWithMath from '../../TextWithMath'
import { Checkbox, FormControlLabel, List, ListItem } from '@mui/material'
import { css } from '@emotion/css'
import CodeView from '../../CodeView'
// import FormatText from '../../../UI/FormatText'
import { determineTextDirection } from '../../../utils/determineTextDirection'

export const questionCheckboxDefaultAnswer = []



export const checkSlideQuestionCheckboxAnswer = (
  { answers }: QuestionCheckboxesSlide,
  userAnswer: number[]
) => {
  const isIncorrect =
    answers.some(
      (answer, answerIdx) => answer.isCorrect && !userAnswer.includes(answerIdx)
    ) || userAnswer.some((answerPart) => !answers[answerPart].isCorrect)

  return !isIncorrect
}
// --const----------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
const SlideViewQuestionCheckboxes: SlideView<QuestionCheckboxesSlide> = ({ slide, filesHashes, }) => {

  const imageUrl = useMemo(() => {
    const imageFileHash = filesHashes.find(
      (fileHash) => fileHash.id === slide.imageFileId
    )?.fileHash

    return imageFileHash ? `${FILES_BASE_URL}/${imageFileHash}` : null
  }, [])


  // ---------------------------------------------------------

  const submittedAnswer = useAppSelector(
    (state) => state.slides.userAnswers[slide.type][slide.id]
  )

  const { control, setValue } = useFormContext<{ userAnswer: number[] }>()

  const userAnswer = useWatch({ control, name: 'userAnswer' })

  const toggleAnswer = (answerIdx: number) => {
    setValue(
      'userAnswer',
      userAnswer.includes(answerIdx)
        ? userAnswer.filter((answerPart) => answerPart !== answerIdx)
        : [...userAnswer, answerIdx]
    )
  }



  // ---renComp-----------------------------------------------
  // ---------------------------------------------------------
  return (
    <>
      <h2 className={styles.questionTitle} data-for-screen="desktop">
        <TextWithMath value={slide.question} />
      </h2>

      <div className={styles.grid(!!imageUrl)}>
        <div>
          <p className={styles.answerBeginning}>{slide.answerBeginning}</p>

          <List disablePadding>
            {slide.answers.map((answer, answerIdx) => (
              <ListItem
                key={answerIdx}
                style={{ marginBottom: 4 }}
                disablePadding
              >
                <FormControlLabel
                  sx={{
                    marginRight: 0,
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                    '.MuiTypography-root': {
                      flexGrow: 1
                    }
                  }}
                  control={
                    <Checkbox
                      checked={userAnswer.includes(answerIdx)}
                      onChange={() => toggleAnswer(answerIdx)}
                      disabled={!!submittedAnswer}
                    />
                  }
                  label={
                    <span
                      style={{
                        display: 'inline-block',
                        // width: '100%',
                        marginTop: '0.5rem',
                        direction: determineTextDirection(answer.text),
                        color: (!submittedAnswer || answer.isCorrect) ?
                          // !submittedAnswer.isCorrect &&
                          '#000000' : '#f443362A',
                        // back it when we correct the answer
                        // color: isCurExam
                        //   ? '#000000'
                        //   : (!submittedAnswer || answer.isCorrect
                        //     // !submittedAnswer.isCorrect &&
                        //     ? '#000000' : '#f443362A'),
                      }}>
                      <TextWithMath value={answer.text} />
                      {/* <FormatText formatting={answer.formatting} fullWidth>
                          {answer.text}
                        </FormatText> */}
                    </span>
                  }
                />
              </ListItem>
            ))}
          </List>
            
          {slide.codeInput && (
            <div style={{ height: '50rem' }}>
              <CodeView code={slide.codeInput} />
            </div>
          )}
        </div>

        {imageUrl && <img className={styles.img} src={imageUrl} alt="" />}
      </div>
    </>
  )
}

const styles = {
  grid: (hasImage: boolean) =>
    css({
      display: 'grid',
      width: '100%',
      gridTemplateColumns: hasImage ? '1fr 1fr' : '1fr',
      gap: '1rem',
      userSelect: 'none',
      '@media screen and (max-width: 676px)': {
        gridTemplateColumns: '1fr',
      },
    }),
  questionTitle: css({
    fontSize: 22,
    fontWeight: 700,
    marginBottom: '1.5rem',
    display: 'block',
    userSelect: 'none',
  }),
  answerBeginning: css({
    fontSize: 14,
    marginBottom: '0.5rem',
    userSelect: 'none',
  }),
  img: css({
    maxWidth: '100%',
    margin: '0 auto',
    display: 'block',
    borderRadius: '0.4rem',
    userSelect: 'none',
    '@media screen and (max-width: 676px)': {
      gridRow: '1 / 2',
    },
  }),
}

export default SlideViewQuestionCheckboxes
