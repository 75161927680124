import { FC } from 'react'
import { AsyncSelectSearchRhf } from '../../../UI/AsyncSelectSearchRhf'
import { TextField } from '@mui/material'
import { Subcollection } from '../../../types/subcollection'

interface SubcollectionsSearchProps {
  name: string
  required?: boolean
}

export const SubcollectionsSearch: FC<SubcollectionsSearchProps> = ({ name, required }) => {
  return (
    <AsyncSelectSearchRhf
      name={name}
      requestPathname="/subcollections"
      mainInputParam="search"
      noOptionsMessage="No subcollections match the search"
      processOption={(subcollection: Subcollection) => ({
        label: `${subcollection.code} - ${subcollection.title}`,
        value: subcollection.id,
      })}
      required={required}
      placeholder="Subcollection"
      retainUnmatchedValueWhenClose
    />
  )
}
