import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

const ERROR_STATUSES = {
  422: 'UNPROCESSABLE_ENTITY',
};

export type ResErr = FetchBaseQueryError | SerializedError | null | undefined

export const parseResErr = (err: ResErr): string | undefined => {
  if (!err) return;

    if (err && 'data' in err && 'status' in err) {
      const data = err.data as any | undefined;

      if (typeof data?.errCode === 'string') {
        return data.errCode;
      }

      if (err.status in ERROR_STATUSES) {
        return ERROR_STATUSES[err.status as keyof typeof ERROR_STATUSES];
      }
    }

    return 'UNKNOWN';
};
