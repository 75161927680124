import React, { FC } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs'
// the pest colors are: anOldHope, atomOneDark, vs2015, tomorrowNight

interface CodeViewProps {
  code: string
}

const CodeView: FC<CodeViewProps> = ({ code }) => {
  return (
    <div style={{ direction: 'ltr', overflowX: 'auto' }}>
      <SyntaxHighlighter
        language="javascript"
        style={vs2015}
        wrapLines={true}
        wrapLongLines={true}
        customStyle={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
          display: 'block',
          overflowX: 'auto',
          width: '100%'
        }}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  )
}

export default CodeView
