import { FormProvider, useForm } from 'react-hook-form'
import { Container } from '@mui/material'
import { UserI } from '../../../types/user'
import UserForm from './UserForm'

export const CreateUserPage = () => {
  const formContext = useForm<UserI>({
    defaultValues: {
      role: 'student',
      name: 'aa',
      lastName: 'aa',
      gender: 'male'

    },
  })

  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <UserForm />
      </FormProvider>
    </Container>
  )
}
