import {
  useDeleteRemMutation,
  useGetAllRemQuery,
} from '../../store/remedialTeaching'
import LoadingPage from '../LoadingPage'
import { Button, Container, Paper, Stack, LinearProgress } from '@mui/material'
import { BgiLink } from '../../UI/BgiLink'
import dayjs from 'dayjs'

const RemedialTeachingList = () => {
  const { isLoading: isGetting, isFetching, error, data: rems } = useGetAllRemQuery()

  const [deleteRem, { isLoading: isDeleting }] = useDeleteRemMutation()

  if (isGetting) return <LoadingPage />

  // ----------------------------------------------------------------
  return (
    <Container maxWidth="md">
      <BgiLink to={'/'}>Back to the home page</BgiLink>

      <div style={{ width: '100%', height: 30 }} />

      {error || !rems ? (
        <p>Unknown Error occurred</p>
      ) : (
        <Stack spacing={3}>
          {rems?.map((rem) => (
            <Paper key={rem.id}>
              <h3>{rem.bookedStudentName}</h3>

              <p>{dayjs(rem.createdAt).format('YYYY-MM-DD dddd HH:mm')}</p>

              <Button
                disabled={isDeleting}
                onClick={() => deleteRem(rem.id)}
                color="error"
                variant="contained"
              >
                Delete
              </Button>
            </Paper>
          ))}
        </Stack>
      )}

      {isFetching && (

        <LinearProgress style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: 4,
          width: '100%',
          zIndex: 5
        }} />

      )}

      <div style={{ width: '100%', height: 30 }} />
    </Container>
  )
}

export default RemedialTeachingList
