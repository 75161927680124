import { FormProvider, useForm } from 'react-hook-form'
import { CollectionI } from '../../../types/collection'
import { CollectionForm } from './CollectionForm'
import { Container } from '@mui/material'

export const CreateCollection = () => {
  const formContext = useForm<CollectionI>({
    defaultValues: {},
  })

  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <CollectionForm />
      </FormProvider>
    </Container>
  )
}
