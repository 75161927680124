/** @jsxImportSource @emotion/react */
import React, { useState, MouseEvent, useEffect, Suspense, memo } from 'react'
import { useAppSelector } from '../../store/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { logout } from '../../store/auth'
import { Button, Box, Stack, Menu, MenuItem, ListItem } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { useGetFeedbacksQuery } from '../../store/feedbacksApi';
import { BgiLink } from '../../UI/BgiLink'
import { css } from '@emotion/react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Home } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import SwitchLanguage from '../i18n/SwitchLanguage'
import UseDynamicLanguageStyle from '../i18n/UseDynamicLanguageStyle'
import { usePageTracking } from '../../GoogleAnalytics'



// // ---Comp--------------------------------------------------------------------------
// // -----------------------------------------------------------------------------
const Header = memo(() => {

  const { t } = useTranslation(['Header'])
  usePageTracking()

  const userMe = useAppSelector((state) => state.auth.userMe)
  const navigate = useNavigate()

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }

  const { data: FeedbackData } = useGetFeedbacksQuery()
  const FeedBackNumber = FeedbackData && (FeedbackData.length)


  // -2----------------------------------------------------------------------------
  const [anchorElMain, setAnchorElMain] = useState<null | HTMLElement>(null);
  const [anchorElSciences, setAnchorElSciences] = useState<null | HTMLElement>(null);
  const [anchorElPhysics, setAnchorElPhysics] = useState<null | HTMLElement>(null);
  const [anchorElNano, setAnchorElNano] = useState<null | HTMLElement>(null);
  const [anchorLifeFoundation, setAnchorLifeFoundation] = useState<null | HTMLElement>(null);


  const handleMainMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMain(event.currentTarget);
  };

  const handleSciencesMouseEnter: React.MouseEventHandler<HTMLLIElement> = (event: MouseEvent<HTMLLIElement>) => {
    setAnchorElSciences(event.currentTarget);
  };

  const handlePhysicsMouseEnter = (event: MouseEvent<HTMLLIElement>) => {
    setAnchorElPhysics(event.currentTarget);
  };

  const handleNanoMouseEnter = (event: MouseEvent<HTMLLIElement>) => {
    setAnchorElNano(event.currentTarget);
  };

  const handleLifeFoundationMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorLifeFoundation(event.currentTarget);
  };

  const handleCloseAll = () => {
    setTimeout(() => {
      setAnchorElMain(null);
      setAnchorElSciences(null);
      setAnchorElPhysics(null);
      setAnchorElNano(null);
      setAnchorLifeFoundation(null);
    }, 1500);
  }
  // ----------------------------------------------------------------2----

  // ---3-----------------------------------------------------------------

  const DropdownMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
  }

  // ----------------------------------------------------------------3----

  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()

  // ---return----------------------------------------------------- 
  // -------------------------------------------------------- 
  return (
    <Box css={[mcss.navBox, dynamicLanguageStyle]}>
      <div css={mcss.register}>
        <Button
          component={Link}
          to="/select-account-type"
          variant="contained" color="error"
          css={!userMe ? undefined : mcss.displayItem}
        >{t('Register')}</Button>
        <Button
          component={Link}
          to="/login" css={userMe ? mcss.displayItem : undefined}
          variant="contained" color="error"
        > {t('Login')}
        </Button>

        <SwitchLanguage />

        <Button
          component={Link}
          to="/instructions-for-use"
          variant="contained" color="info"
        > تعليمات الاستخدام
        </Button>

      </div>

      {/* -------------------------------------------------------------------------------------- */}
      <div css={mcss.withAdminNav}>
        <AppBar position="static">
          <Toolbar css={mcss.toolbarBgi} sx={{ backgroundColor: 'primary.main' }}>


            <span style={{ marginRight: 4 }} >
              {/* <MenuIcon /> */}

              <div css={mcss.LogoIcon}>

                <MenuItem>
                  <img src="/bgiIcon192.jpg" css={mcss.BgiIcon} />
                </MenuItem>
                <Button
                  endIcon={<ArrowDropDownIcon />}
                  color="inherit"
                  onClick={handleLifeFoundationMenuClick}
                >
                </Button>

                <Menu
                  id="life-foundation"
                  anchorEl={anchorLifeFoundation}
                  open={Boolean(anchorLifeFoundation)}
                  onClose={handleCloseAll}
                  onMouseLeave={handleCloseAll}
                >
                  <MenuItem onClick={handleCloseAll}>
                    <BgiLink to="/collections?curriculum=LF">{t('LifeFoundation')}</BgiLink>
                  </MenuItem>

                  <Button
                    onClick={handleLogout}
                    variant="contained" color="info"
                    css={userMe ? undefined : mcss.displayItem}
                  >{t('LogOut')} <LogoutIcon />
                  </Button>
                </Menu>
              </div>
            </span>
  {/* -------------------------------------------------------------stack-- */}

            <div css={mcss.navBarItem}>
              <Button component={Link} to="/" css={mcss.HomeIcon}><Home /></Button>

              <Button css={mcss.allBtn} component={Link} to="/student">{t('Student')}</Button>

              <Button
                endIcon={<ArrowDropDownIcon />}
                color="inherit"
                onClick={handleMainMenuClick}
              >
                {t('Curriculum')}
              </Button>

              <Button css={mcss.allBtn} component={Link} to="/ideaeducation">{t('IdeaIsEducation')}</Button>
              <Button css={mcss.allBtn} component={Link} to="/Quick-access-courses">{t('QuickAccessCourses')}</Button>


              {(userMe && (userMe.role === 'admin' || userMe.role === 'worker')) && (
                <Button css={mcss.allBtn} component={Link} to="/data-entry/slides/search">{t("FindASlide")}</Button>
              )}

              {/* <Button css={mcss.allBtn} component={Link} to="/contactus">{t('ContactUs')}</Button> */}
            </div>
            {/* -------------------------------------------------------------stack-- */}

            {(userMe && (userMe.role === 'admin' || userMe.role === 'worker')) && (
              <Button css={mcss.allBtn} component={Link} to="/show-special-exam-result">{t('Competition')}</Button>
            )}

            {/* قائمة العلوم الطبيعية */}
            <Menu
              id="main-menu"
              anchorEl={anchorElMain}
              open={Boolean(anchorElMain)}
              onClose={handleCloseAll}
              onMouseLeave={handleCloseAll}
            >
              <MenuItem><BgiLink to="/collections?curriculum=Ye">{t('Yemen')}</BgiLink></MenuItem>
              <MenuItem><BgiLink to="/collections?curriculum=Om">{t('Oman')}</BgiLink></MenuItem>
              <MenuItem onMouseEnter={handleSciencesMouseEnter}>{t('Natural_sciences')}</MenuItem>
            </Menu>

            {/* قائمة العلوم الفيزيائية */}
            <Menu
              id="sciences-submenu"
              anchorEl={anchorElSciences}
              open={Boolean(anchorElSciences)}
              onClose={handleCloseAll}
              onMouseLeave={handleCloseAll}
            // slotProps={{ paper: { style: { left: '1.5rem', right: 100 } } }}

            >
              <MenuItem onMouseEnter={handlePhysicsMouseEnter}>{t('Nanoscience')}</MenuItem>
            </Menu>


            {/* قائمة علم النانو */}
            <Menu
              id="physics-submenu"
              anchorEl={anchorElPhysics}
              open={Boolean(anchorElPhysics)}
              onClose={handleCloseAll}
              onMouseLeave={handleCloseAll}
            // slotProps={{ paper: { style: { left: '1.5rem', right: 200 } } }}
            >
              <MenuItem onClick={handleCloseAll}>
                <BgiLink to="/collections?curriculum=GEN">{t('Nanoscience')}</BgiLink>
              </MenuItem>
            </Menu>
            {/* </Stack> */}
          </Toolbar>


          <div css={mcss.adminNav}>
            <nav>
              <ul>
                {userMe?.role === 'admin' && (
                  <>
                    <li><BgiLink to="/data-entry/units">{t('Units')}</BgiLink></li>
                    <li><BgiLink to="/data-entry/files-lib">{t('Files_library')}</BgiLink></li>
                    <li><BgiLink to="/data-entry/exams">{t('Exams')}</BgiLink></li>
                    <li><BgiLink to="/data-entry/ads">{t('Ads')}</BgiLink></li>
                    <li><BgiLink to="/data-entry/users">{t('Users')}</BgiLink></li>
                    {/* <li><BgiLink to="/data-entry/user-avatars">{('User Avatars')}</BgiLink></li> */}
                    <li><BgiLink to="/data-entry/feedbacks">{t('Feedback')} #<span css={mcss.feedbackNumber}> {FeedBackNumber}</span>
                    </BgiLink></li>
                    <li><BgiLink to="/data-entry/announcements">Announcements</BgiLink></li>
                  </>
                )}
              </ul>
            </nav>
          </div>
        </AppBar>
      </div>
    </Box >
  )
})





const mcss = {
  navBox: css({
    direction: 'rtl',
    // maxWidth: '100%',
    // width: '100%',
    whiteSpace: 'nowrap',
    '.css-hyum1k-MuiToolbar-root': {
      maxWidth: '10rem',
      minHight: '2rem',
    },
    '.css-phzgj1-MuiButtonBase-root-MuiButton-root': {
      margin: '-1.5rem',
    },
  }),
  toolbarBgi: css({
    display: 'flex-inline',
    flexDirection: 'row',
    flexShrink: 0,
    alignItems: 'center',
    gap: '0.5rem',
    width: '100%',
    padding: '0 0.2rem',
    justifyContent: 'start',
    '@media (max-width: 700px)': {
      justifyContent: 'start',
      gap: '0.2rem',
      flex: '0 0 auto',
    },
  }),
  register: css({
    display: 'flex',
    width: '50%',
    justifyContent: 'space-around',
    marginTop: '0.2rem',
    marginRight: '5rem',
    gap: '0.5rem',
    'MuiButtonBase-root-MuiButton-root': {
      fontSize: '7rem',
    },
    '@media (max-width: 700px)': {
      width: '98%',
      marginRight: '0.2rem',
      '.css-nm4uu1-MuiButtonBase-root-MuiButton-root': {
        fontSize: '0.8rem',
      },
    },
  }),
  withAdminNav: css({
    display: 'flex'
  }),
  navBarItem: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '0.2rem',
    '@media (max-width: 700px)': {
      gap: '0.1rem',
    },
  }),
  BgiIcon: css({
    width: '3.5rem',
    height: '3.5rem',
    border: '2px solid  #ffff',
    borderRadius: '50%',
    backgroundColor: 'white',
    // margin: '0 2rem 0 0.5rem',
    '@media (max-width: 700px)': {
      width: '2.5rem',
      height: '2.5rem',
    }
  }),
  HomeIcon: css({
    fontSize: '2rem',
    color: 'white',
    '@media (max-width: 700px)': {
      fontSize: '1.5rem',
    },
  }),

  LogoIcon: css({
    display: 'flex',
    alignItems: 'center',
    '.MuiButtonBase-root': {
      margin: '0 -0.5rem 0 -0.5rem',
    },
    '@media (max-width: 700px)': {
      '.MuiButtonBase-root': {
        margin: '0 -1rem',
        padding: '0.2rem 0.8rem 0 0.5rem',
      },
    },
  }),


  allBtn: css({
    display: 'flex',
    color: 'white',

  }),

  // ------------------------------------
  adminNav: css({
    display: 'block',
    'nav': {
      'ul': {
        listStyle: 'none',
        display: 'flex',
        gap: '1rem',
      },
      'a': {
        color: 'black',
      },
      '@media (max-width: 700px)': {
        display: 'flex',
        fontSize: '0.9rem',
        margin: '0.5rem 1rem 0 0',
        'ul': {
          display: 'flex',
          gap: '0.5rem',
        },
        'li': {
          whiteSpace: 'nowrap',
          width: '100%'
        }
      }
    }
  }),

  feedbackNumber: css({
    color: 'whitesmoke',
    // background: 'blue'
  }),

  displayItem: css({
    display: 'none',
  }),
}
export default Header







// -------------اقتراح من الذاكاء الصناعي------------------------

// const Header = memo(() => {
//   const { t } = useTranslation(['Header'])
//   usePageTracking()

//   const userMe = useAppSelector((state) => state.auth.userMe)
//   const navigate = useNavigate()
//   const dispatch = useDispatch()

//   const handleLogout = () => {
//     dispatch(logout())
//     navigate('/')
//   }

//   const { data: FeedbackData } = useGetFeedbacksQuery()
//   const FeedBackNumber = FeedbackData && (FeedbackData.length)

//   const [anchorElMain, setAnchorElMain] = useState<null | HTMLElement>(null)
//   const [anchorElSciences, setAnchorElSciences] = useState<null | HTMLElement>(null)
//   const [anchorElPhysics, setAnchorElPhysics] = useState<null | HTMLElement>(null)
//   const [anchorElNano, setAnchorElNano] = useState<null | HTMLElement>(null)
//   const [anchorLifeFoundation, setAnchorLifeFoundation] = useState<null | HTMLElement>(null)

//   const handleMainMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorElMain(event.currentTarget)
//   }

//   const handleSciencesMouseEnter: React.MouseEventHandler<HTMLLIElement> = (event) => {
//     setAnchorElSciences(event.currentTarget)
//   }

//   const handlePhysicsMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorElPhysics(event.currentTarget)
//   }

//   const handleNanoMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorElNano(event.currentTarget)
//   }

//   const handleLifeFoundationMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorLifeFoundation(event.currentTarget)
//   }

//   const handleCloseAll = () => {
//     setTimeout(() => {
//       setAnchorElMain(null)
//       setAnchorElSciences(null)
//       setAnchorElPhysics(null)
//       setAnchorElNano(null)
//       setAnchorLifeFoundation(null)
//     }, 1500)
//   }

//   const { dynamicLanguageStyle } = UseDynamicLanguageStyle()

//   return (
//     <Box css={[mcss.navBox, dynamicLanguageStyle]}>
//       <div css={mcss.register}>
//         <Button
//           component={Link}
//           to="/signup"
//           variant="contained" color="error"
//           css={!userMe ? undefined : mcss.displayItem}
//         >
//           {t('Register')}
//         </Button>
//         <Button
//           component={Link}
//           to="/login"
//           css={userMe ? mcss.displayItem : undefined}
//           variant="contained" color="error"
//         >
//           {t('Login')}
//         </Button>

//         <SwitchLanguage />

//         <Button
//           onClick={handleLogout}
//           variant="contained" color="info"
//           css={userMe ? undefined : mcss.displayItem}
//         >
//           {t('LogOut')} <LogoutIcon />
//         </Button>
//       </div>

//       <div css={mcss.withAdminNav}>
//         <AppBar position="static">
//           <Toolbar css={mcss.toolbarBgi} sx={{ backgroundColor: 'primary.main' }}>
//             <IconButton size="small" edge="start" color="inherit" aria-label="menu" sx={{ mr: 0.05 }}>
//               <div css={mcss.LogoIcon}>
//                 <Button
//                   endIcon={<ArrowDropDownIcon />}
//                   color="inherit"
//                   onClick={handleLifeFoundationMenuClick}
//                 >
//                   <MenuItem>
//                     <img src="/bgiIcon192.jpg" css={mcss.BgiIcon} alt="Logo" />
//                   </MenuItem>
//                 </Button>

//                 <Menu
//                   id="life-foundation"
//                   anchorEl={anchorLifeFoundation}
//                   open={Boolean(anchorLifeFoundation)}
//                   onClose={handleCloseAll}
//                   onMouseLeave={handleCloseAll}
//                 >
//                   <MenuItem onClick={handleCloseAll}>
//                     <BgiLink to="/collections?curriculum=LF">{t('LifeFoundation')}</BgiLink>
//                   </MenuItem>
//                 </Menu>
//               </div>
//             </IconButton>

//             <Button component={Link} to="/" css={mcss.HomeIcon}><Home /></Button>

//             <Stack direction="row" spacing={0.2}>
//               <div>
//                 <div>
//                   <Button css={mcss.allBtn} component={Link} to="/student">{t('Student')}</Button>
//                   <Button
//                     endIcon={<ArrowDropDownIcon />}
//                     color="inherit"
//                     onClick={handleMainMenuClick}
//                   >
//                     {t('Curriculum')}
//                   </Button>
//                   <Button css={mcss.allBtn} component={Link} to="/ideaeducation">{t('IdeaIsEducation')}</Button>
//                   {(userMe && (userMe.role === 'admin' || userMe.role === 'worker')) && (
//                     <Button css={mcss.allBtn} component={Link} to="/data-entry/slides/search">{t("FindASlide")}</Button>
//                   )}
//                   <Button css={mcss.allBtn} component={Link} to="/contactus">{t('ContactUs')}</Button>
//                   {(userMe && (userMe.role === 'admin' || userMe.role === 'worker')) && (
//                     <Button css={mcss.allBtn} component={Link} to="/show-special-exam-result">{t('Competition')}</Button>
//                   )}
//                 </div>

//                 <Menu
//                   id="main-menu"
//                   anchorEl={anchorElMain}
//                   open={Boolean(anchorElMain)}
//                   onClose={handleCloseAll}
//                   onMouseLeave={handleCloseAll}
//                 >
//                   <MenuItem><BgiLink to="/collections?curriculum=Ye">{t('Yemen')}</BgiLink></MenuItem>
//                   <MenuItem><BgiLink to="/collections?curriculum=Om">{t('Oman')}</BgiLink></MenuItem>
//                   <MenuItem onMouseEnter={handleSciencesMouseEnter}>{t('Natural_sciences')}</MenuItem>
//                 </Menu>

//                 <Menu
//                   id="sciences-submenu"
//                   anchorEl={anchorElSciences}
//                   open={Boolean(anchorElSciences)}
//                   onClose={handleCloseAll}
//                   onMouseLeave={handleCloseAll}
//                 >
//                   {/* <MenuItem onMouseEnter={handlePhysicsMouseEnter}>{t('Nanoscience')}</MenuItem> */}
//                 </Menu>

//                 <Menu
//                   id="physics-submenu"
//                   anchorEl={anchorElPhysics}
//                   open={Boolean(anchorElPhysics)}
//                   onClose={handleCloseAll}
//                   onMouseLeave={handleCloseAll}
//                 >
//                   <MenuItem onClick={handleCloseAll}>
//                     <BgiLink to="/collections?curriculum=GEN">{t('Nanoscience')}</BgiLink>
//                   </MenuItem>
//                 </Menu>
//               </div>
//             </Stack>
//           </Toolbar>

//           <div css={mcss.adminNav}>
//             <nav>
//               <ul>
//                 {userMe?.role === 'admin' && (
//                   <>
//                     <li><BgiLink to="/data-entry/units">{t('Units')}</BgiLink></li>
//                     <li><BgiLink to="/data-entry/files-lib">{t('Files_library')}</BgiLink></li>
//                     <li><BgiLink to="/data-entry/exams">{t('Exams')}</BgiLink></li>
//                     <li><BgiLink to="/data-entry/ads">{t('Ads')}</BgiLink></li>
//                     <li><BgiLink to="/data-entry/users">{t('Users')}</BgiLink></li>
//                     <li><BgiLink to="/data-entry/feedbacks">{t('Feedback')} #<span css={mcss.feedbackNumber}>{FeedBackNumber}</span></BgiLink></li>
//                   </>
//                 )}
//               </ul>
//             </nav>
//           </div>
//         </AppBar>
//       </div>
//     </Box>
//   )
// })


// // تحديث الأنماط
// const mcss = {
//   navBox: css({
//     direction: 'rtl',
//     maxWidth: '100%',
//     whiteSpace: 'nowrap',
//     width: '100%',
//     boxSizing: 'border-box',
//     '.css-hyum1k-MuiToolbar-root': {
//       marginRight: '0.2rem',
//       marginTop: '-0.5rem',
//       height: '1rem',
//     },
//   }),
//   toolbarBgi: css({
//     display: 'grid',
//     gridTemplateColumns: 'repeat(10, auto)',
//     width: '100%',
//     padding: '0 0.2rem',
//     '@media (max-width: 700px)': {
//       gridTemplateColumns: 'repeat(5, auto)',
//       padding: '0 0.2rem',
//     },
//   }),
//   register: css({
//     display: 'flex',
//     width: '35%',
//     justifyContent: 'space-around',
//     marginTop: '0.2rem',
//     marginRight: '5rem',
//     'MuiButtonBase-root-MuiButton-root': {
//       fontSize: '7rem',
//     },
//     '@media (max-width: 700px)': {
//       width: '70%',
//       marginRight: '0.2rem',
//       '.css-nm4uu1-MuiButtonBase-root-MuiButton-root': {
//         fontSize: '0.8rem',
//       },
//     },
//   }),
//   withAdminNav: css({
//     display: 'flex',
//   }),
//   BgiIcon: css({
//     width: '3.5rem',
//     height: '3.5rem',
//     border: '2px solid #fff',
//     borderRadius: '50%',
//     backgroundColor: 'white',
//     margin: '0 0.5rem 0 0.5rem',
//     '@media (max-width: 700px)': {
//       width: '2.5rem',
//       height: '2.5rem',
//     }
//   }),
//   HomeIcon: css({
//     fontSize: '2rem',
//     color: 'white',
//     '@media (max-width: 700px)': {
//       fontSize: '1.5rem',
//       marginTop: '0.2rem',
//     },
//   }),
//   LogoIcon: css({
//     display: 'flex',
//     alignItems: 'center',
//     '.MuiButtonBase-root': {
//       margin: '0 -0.5rem 0 -0.5rem',
//     },
//     '@media (max-width: 700px)': {
//       '.MuiButtonBase-root': {
//         margin: '0 -1rem',
//         padding: '0.2rem 0.8rem 0 0.5rem',
//       },
//     },
//   }),
//   nav: css({
//     display: 'grid',
//     gridTemplateColumns: '1fr',
//     gridTemplateRows: 'auto auto',
//     '@media (max-width: 700px)': {
//       marginRight: '-1rem',
//       fontSize: '0.9rem',
//       'ul': {
//         display: 'flex',
//         marginRight: '-0.1rem',
//         gap: '0.4rem',
//       },
//       'li': {
//         whiteSpace: 'nowrap',
//         width: '100%',
//       },
//     },
//   }),
//   allBtn: css({
//     color: 'white',
//   }),
//   adminNav: css({
//     display: 'block',
//     'nav': {
//       'ul': {
//         listStyle: 'none',
//         display: 'flex',
//         gap: '1rem',
//       },
//       'a': {
//         color: 'black',
//       },
//       '@media (max-width: 700px)': {
//         display: 'flex',
//         fontSize: '0.9rem',
//         margin: '0.5rem 1rem 0 0',
//         'ul': {
//           display: 'flex',
//           gap: '0.5rem',
//         },
//         'li': {
//           whiteSpace: 'nowrap',
//           width: '100%',
//         },
//       },
//     },
//   }),
//   feedbackNumber: css({
//     color: 'whitesmoke',
//   }),
//   displayItem: css({
//     display: 'none',
//   }),
// }

// export default Header