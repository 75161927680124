import { Container } from '@mui/material'
import { FilesLib } from './FilesLib'
import { BgiLink } from '../../UI/BgiLink'
import PageHeader from '../../UI/PageHeader'

const FilesLibPage = () => {
  return (
    <Container maxWidth="lg">
      <BgiLink to="/">Back to the units page</BgiLink>

      <div style={{ width: '100%', height: 30 }} />

      <PageHeader style={{ marginBottom: 24 }} title="Files Library" />

      <FilesLib pageImagesLimit={25} fileKind='all' />

      <div style={{ width: '100%', height: 30 }} />
    </Container>
  )
}

export default FilesLibPage
