import { useEffect, useId, useMemo, useRef } from 'react'
import { css } from '@emotion/css'
import { SlideView } from '../../../types/SlideView'
import { QuestionMultiInputsSlide } from '../../../types/slide'
import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL'
import { useFormContext, useWatch } from 'react-hook-form'
import TextWithMath from '../../TextWithMath'
import { useAppSelector } from '../../../store/hooks'
import { TextField } from '@mui/material'

export const questionMultiInputsDefaultAnswer = []

export const checkSlideQuestionMultiInputsAnswer = (
  { answers }: QuestionMultiInputsSlide,
  userAnswer: string[]
) => {
  const correctInputAnswers: string[] = []

  answers.forEach(answer => {
    if (answer.type === 'inputAnswer' && typeof answer.correctAnswer === 'string') {
      correctInputAnswers.push(answer.correctAnswer.trim().replace(/\s+/g, ''))
    }
  })

  const userAnswerTrimmed = userAnswer
    .filter(answerPart => typeof answerPart === 'string')
    .map(answerPart => answerPart.trim().replace(/\s+/g, ''))

  return correctInputAnswers.join('#') === userAnswerTrimmed.join('#')
}

const SlideViewQuestionMultiInputs: SlideView<QuestionMultiInputsSlide> = ({
  slide,
  filesHashes,
}) => {
  const audiosRef = useRef<(HTMLAudioElement | null)[]>([])

  useEffect(() => {
    return () => {
      audiosRef.current.forEach((audio) => {
        if (audio) audio.pause()
      })
    }
  }, [])

  const componentId = useId()

  const imageUrl = useMemo(() => {
    const imageFileHash = filesHashes.find(
      (fileHash) => fileHash.id === slide.imageFileId
    )?.fileHash

    return imageFileHash ? `${FILES_BASE_URL}/${imageFileHash}` : null
  }, [])

  const submittedAnswer = useAppSelector(
    (state) => state.slides.userAnswers[slide.type][slide.id]
  )

  const { control, setValue } = useFormContext<{ userAnswer: string[] }>()

  const userAnswer = useWatch({ control, name: 'userAnswer' })

  const inputAnswer = (answerIdx: number, newAnswerPart: string) => {
    const updatedUserAnswer = [...userAnswer]
    updatedUserAnswer[answerIdx] = newAnswerPart
    setValue('userAnswer', updatedUserAnswer)
  }

  const playAudio = (answerIdx: number) => {
    const audio = audiosRef.current[answerIdx]

    if (!audio) return

    audiosRef.current.forEach((iterateAudio, iterateI) => {
      if (
        iterateAudio &&
        iterateI !== answerIdx &&
        !iterateAudio.paused
      ) iterateAudio.pause()
    })

    if (!audio.paused) {
      audio.currentTime = 0
    } else {
      audio.play()
    }
  }

  const firstInputAnswerIdx = slide.answers.findIndex(answer => answer.type === 'inputAnswer')

  return (
    <>
      <h2 className={styles.questionTitle} data-for-screen="desktop">
        <TextWithMath value={slide.question} />
      </h2>

      <div className={styles.grid(!!imageUrl)}>
        <div>
          <p className={styles.answerBeginning}>{slide.answerBeginning}</p>

          <div className={styles.answers}>
            {slide.answers.map((answer, answerIdx) => {
              if (answer.type === 'label') {
                return (
                  <div key={answerIdx} className={styles.answerBox} onClick={() => playAudio(answerIdx)}>
                    {answer.audioFile && (
                      <audio className={styles.answerAudio} ref={el => audiosRef.current[answerIdx] = el}>
                        <source
                          src={`${FILES_BASE_URL}/${answer.audioFile.fileHash}`}
                          type={answer.audioFile.fileExt === '.ogg' ? 'audio/ogg' : 'audio/mpeg'}
                        />
                      </audio>
                    )}
    
                    <div
                      className={styles.answerLabel}
                    >
                      {answer.imageFile && (
                        <img className={styles.answerImg} src={`${FILES_BASE_URL}/${answer.imageFile.fileHash}`} />
                      )}
                      <span>{answer.text}</span>
                    </div>
                  </div>
                );
              } else {
                const isIncorrect = submittedAnswer && typeof submittedAnswer.value[answerIdx] === 'string' &&
                  answer.correctAnswer.trim().replace(/\s+/g, '') !== submittedAnswer.value[answerIdx].trim().replace(/\s+/g, '')
                
                return (
                  <TextField
                    key={answerIdx}
                    className={styles.textField}
                    label={answer.text}
                    autoFocus={firstInputAnswerIdx === answerIdx}
                    size="small"
                    autoComplete="off"
                    value={userAnswer[answerIdx] || ''}
                    onChange={(e) => inputAnswer(answerIdx, e.target.value)}
                    data-incorrect-value={isIncorrect}
                    disabled={!!submittedAnswer}
                    error={isIncorrect}
                    helperText={
                      isIncorrect
                        ? 'الاجابة الصحيحة:' + ' ' + answer.correctAnswer
                        : undefined
                    }
                  />
                )
              }
            })}
          </div>
        </div>

        {imageUrl && <img className={styles.img} src={imageUrl} alt="" />}
      </div>
    </>
  )
}

const styles = {
  grid: (hasImage: boolean) =>
    css({
      display: 'grid',
      width: '100%',
      gridTemplateColumns: hasImage ? '1fr 1fr' : '1fr',
      gap: '1rem',
      '@media screen and (max-width: 676px)': {
        gridTemplateColumns: '1fr',
      },
    }),
  questionTitle: css({
    fontSize: 22,
    fontWeight: 700,
    marginBottom: '1.5rem',
    display: 'block'
  }),
  answerBeginning: css({
    fontSize: 14,
    marginBottom: '0.5rem',
  }),
  img: css({
    maxWidth: '100%',
    margin: '0 auto',
    display: 'block',
    borderRadius: '0.4rem',
    '@media screen and (max-width: 676px)': {
      gridRow: '1 / 2',
    },
  }),
  answers: css({
    display: 'flex',
    flexWrap: 'wrap'
  }),
  answerBox: css({
    position: 'relative',
    marginLeft: 16,
    marginBottom: 16,
  }),
  answerAudio: css({
    width: 0,
    height: 0,
    position: 'absolute',
    top: 0,
    visibility: 'hidden',
  }),
  answerInput: css({
    width: 0,
    height: 0,
    position: 'absolute',
    top: 0,
    visibility: 'hidden',
    '& + label': {
      background: '#a4c2e6',
      border: '3px solid transparent',
      cursor: 'pointer',
    },
    '&:checked + label': {
      border: '3px solid var(--clrBg)',
    },
    '& + label[data-submitted-result="correct"]': {
      background: '#43a047',
      color: '#fff',
      cursor: 'default'
    },
    '& + label[data-submitted-result="incorrect"]': {
      background: '#EF5350',
      color: '#fff',
      cursor: 'default'
    },
  }),
  answerLabel: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 100,
    minHeight: 50,
    userSelect: 'none',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px'
  }),
  answerImg: css({
    maxWidth: 100,
    marginBottom: 4
  }),
  textField: css({
    width: 200,
    maxWidth: '100%',
    marginLeft: 16,
    marginBottom: 16,
    '&[data-incorrect-value="true"]': {
      '& fieldset': {
        borderColor: '#d32f2f !important',
      },
    },
  }),
}

export default SlideViewQuestionMultiInputs
