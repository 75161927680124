import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Subcollection, SubcollectionI } from '../types/subcollection'
import { apiConfig } from './api'

export const subcollectionsApi = createApi({
  reducerPath: 'subcollectionsApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/subcollections`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getSubcollections: builder.query<Subcollection[], string>({
      query: (collectionId) => `?collectionId=${collectionId}`,
    }),
    getSubcollection: builder.query<Subcollection | null, string>({
      query: (subcollectionId) => `/${subcollectionId}`,
    }),
    createSubcollection: builder.mutation<Subcollection, SubcollectionI>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),
    updateSubcollection: builder.mutation<Subcollection, Partial<SubcollectionI> & Pick<Subcollection, 'id'>>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
    }),
    deleteSubcollection: builder.mutation<void, string>({
      query: (subcollectionId) => ({ url: `/${subcollectionId}`, method: 'DELETE' }),
    }),
  }),
})

export const {
  useGetSubcollectionsQuery,
  useGetSubcollectionQuery,
  useLazyGetSubcollectionQuery,
  useCreateSubcollectionMutation,
  useUpdateSubcollectionMutation,
  useDeleteSubcollectionMutation,
} = subcollectionsApi
