
/** @jsxImportSource @emotion/react */

import { Alert, Button, Container } from '@mui/material'
import { Link } from 'react-router-dom'
import { useFetch } from '../../../hooks/useFetch'
import { Ad } from '../../../types/ad'
import { BgiLink } from '../../../UI/BgiLink'
import PageHeader from '../../../UI/PageHeader'
import LoadingPage from '../../LoadingPage'
import { useTranslation } from 'react-i18next'
import Layout from '../../page/Layout'
import UseDynamicLanguageStyle from '../../i18n/UseDynamicLanguageStyle'
import { css } from '@emotion/react'


const AdsPage = () => {
  const { t } = useTranslation(['Ads', 'common'])
  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()

  const [loading, ads, error] = useFetch<Ad[]>(`/ads`)
  if (loading) return <LoadingPage />


  return (
    <div css={dynamicLanguageStyle}>
    <Layout>
      <Container maxWidth="md">
        <div style={{ width: '100%', height: 30 }} />

        <PageHeader
          style={{ marginBottom: 24 }}
          title={t('Ads')}
          actions={
            <Button
              component={Link}
              variant="contained"
              to={`/data-entry/ads/create`}
            >
              {t('Create_ad')}
            </Button>
          }
        />

        {error || !ads ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            {ads.length > 0 ? (
              <ul style={{ paddingLeft: 24 }}>
                {ads.map((ad) => (
                  <li key={ad.id}>
                    <BgiLink
                      to={`/data-entry/ads/${ad.id}/update`}
                      style={{ color: ad.isDeactivated ? '#9e9e9e' : undefined }}
                    >
                      {ad.title} ({ad.type})
                    </BgiLink>
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('There_is_no_ads_yet')}</p>
            )}
          </>
        )}

        <div style={{ width: '100%', height: 30 }} />
      </Container>
    </Layout>
    </div>
      )
}

export default AdsPage
