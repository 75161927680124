import { FormProvider, useForm } from 'react-hook-form'
import { SubcollectionI } from '../../../types/subcollection'
import { SubcollectionForm } from './SubcollectionForm'
import { Container } from '@mui/material'

export const CreateSubcollection = () => {
  const formContext = useForm<SubcollectionI>({
    defaultValues: {},
  })

  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <SubcollectionForm />
      </FormProvider>
    </Container>
  )
}
