import { Alert, Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Subcollection } from '../../../types/subcollection'
import { SubcollectionForm } from './SubcollectionForm'
import { useGetSubcollectionQuery } from '../../../store/subcollectionsApi'
import LoadingPage from '../../LoadingPage'

export const UpdateSubcollection = () => {
  const { subcollectionId } = useParams<{ subcollectionId: string }>()

  // Ensure subcollectionId is available
  if (!subcollectionId) {
    throw new Error('Subcollection ID not provided.')
  }

  const formContext = useForm<Subcollection>()
  const { data: subcollection } = useGetSubcollectionQuery(subcollectionId)

  if (subcollection) {
    Object.keys(subcollection).forEach((untypedSubcollectionKey) => {
      const subcollectionKey = untypedSubcollectionKey as keyof Subcollection
      formContext.setValue(subcollectionKey, subcollection[subcollectionKey])
    })
  }

  if (!subcollection) return <LoadingPage />

  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <SubcollectionForm />
      </FormProvider>
    </Container>
  )
}
