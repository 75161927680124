import { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import UserFormPage from './UserForm'
import { useGetUserQuery } from '../../../store/usersApi'
import { Container } from '@mui/material'
import LoadingPage from '../../LoadingPage'
import { User, UserI } from '../../../types/user'

export const UpdateUserPage = () => {
  const formContext = useForm<UserI>()
  const { userId } = useParams() as { [k: string]: string }
  const [loading, setLoading] = useState(true)

  const { data: user } = useGetUserQuery(userId)

  useEffect(() => {
    if (user) {
      Object.keys(user).map((untypedUserKey) => {
        const userKey = untypedUserKey as keyof User
        formContext.setValue(userKey, user[userKey])
      })
      setLoading(false)
    }
  }, [user])

  if (loading) return <LoadingPage />

  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <UserFormPage />
      </FormProvider>
    </Container>
  )
}
