/** @jsxImportSource @emotion/react */

import { Alert, Button, CircularProgress, Container, Paper, Stack, Typography,} from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { BgiTextField } from '../../UI/BgiTextField'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { LoginPayload, loginAsync } from '../../store/auth'
import { useTranslation } from 'react-i18next'
import Layout from '../page/Layout'
import { BgiLink } from '../../UI/BgiLink'
import { css } from '@emotion/react'


interface Input {
  email: string
  password: string
}

export default function Login() {
  const formContext = useForm<Input>()
  const error = useAppSelector((state) => state.auth.error)
  const isLoading = useAppSelector((state) => state.auth.isLoading)
  const dispatch = useAppDispatch()
  
  const { handleSubmit } = formContext

  const { t } = useTranslation('Signup_login')

  const onSubmit: SubmitHandler<LoginPayload> = async (data) =>
    dispatch(loginAsync(data) as any)

  return (

    <Layout>
      <Container maxWidth="sm">
      <div style={{ height: 30 }} />

      <Paper style={{ padding: '1rem' }} elevation={3}>
        <Typography
          style={{
            textAlign: 'center',
            marginBottom: '1rem',
            fontWeight: 700,
          }}
          variant="h5"
        >
          Login
        </Typography>

        <FormProvider {...formContext}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              {error === 'USER_NOT_EXISTS' && (
                <Alert variant="outlined" severity="error">
                  {t('Incorrect_email_or_password')}
                </Alert>
              )}

              <BgiTextField label={t('Email')} name="email" required type='email'/>

              <BgiTextField
                label={t('Password')}
                type="password"
                name="password"
                required
                minLength={6}
              />

              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                startIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('Login')}
              </Button>
              {
                error === 'USER_NOT_EXISTS' &&(
                  <BgiLink css={styles.linkForgot} to={'/auth/begin-reset-password'}>{t('Forgot_password')}</BgiLink>
                  )
                }
            </Stack>
          </form>
        </FormProvider>
      </Paper>

      <div style={{ height: '20rem' }} />
    </Container>
    </Layout>
  )
}


const styles = {
  linkForgot: css({
    textDecoration: 'none',
    fontSize: '1rem',
  })
}