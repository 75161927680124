/** @jsxImportSource @emotion/react */

import { useState, useMemo } from 'react'
import {
  useDeleteFeedbackMutation,
  useGetFeedbacksQuery,
} from '../../store/feedbacksApi'
import LoadingPage from '../LoadingPage'
import { Button, Checkbox, Container, FormControlLabel, Paper, Stack, LinearProgress } from '@mui/material'
import { BgiLink } from '../../UI/BgiLink'
import Feedback from './Feedback'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import UseDynamicLanguageStyle from '../i18n/UseDynamicLanguageStyle'
import Layout from '../page/Layout'


const FeedbackPage = () => {
  const { isLoading: isGetting, isFetching, error, data: beforeMemoFeedbacks, } = useGetFeedbacksQuery()

  const { t } = useTranslation(['Feedback', 'common'])
  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()

  const [deleteFeedback, { isLoading: isDeleting }] = useDeleteFeedbackMutation()

  const [deletedIds, setDeletedIds] = useState<string[]>([])

  // TODO: delete feedback from redux createApi
  const feedbacks = useMemo(
    () =>
      beforeMemoFeedbacks?.filter(
        (feedback) => !deletedIds.includes(feedback.id)
      ),
    [beforeMemoFeedbacks, deletedIds]
  )

  const handleDelete = (feedbackId: string) => {
    deleteFeedback(feedbackId)
    setDeletedIds((prev) => [...prev, feedbackId])
  }

  if (isGetting) return <LoadingPage />

  // ----------------------------------------------------------------
  return (
    <Layout>
      <div css={dynamicLanguageStyle}>
        <Container maxWidth="md">
          <BgiLink to={'/'}>{t('common:Back_to_the_home_page')}</BgiLink>

          <div style={{ width: '100%', height: 30 }} />

          {error || !feedbacks ? (
            <p>Unknown Error occurred</p>
          ) : (
            <Stack spacing={3}>
              {feedbacks?.map((feedback) => (
                <Feedback key={feedback.id} feedback={feedback} isDeleting={isDeleting} onDelete={() => handleDelete(feedback.id)} />
              ))}
            </Stack>
          )}

          {isFetching && (

            <LinearProgress style={{
              position: 'fixed',
              top: 0,
              left: 0,
              height: 4,
              width: '100%',
              zIndex: 5
            }} />

          )}

          <div style={{ width: '100%', height: 30 }} />
        </Container>
      </div>
    </Layout>
  )
}

export default FeedbackPage
