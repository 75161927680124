import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import { FC, useId } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useFieldRules } from '../hooks/useFieldRules'
import parseFieldErrorText from '../utils/parseFieldErrorText'

export interface BgiRadioGroupProps {
  name: string
  label?: string
  required?: boolean
  options: {
    label: string
    value: string
  }[]
}

const BgiRadioGroup: FC<BgiRadioGroupProps> = ({
  name,
  label,
  options,
  required,
}) => {
  const { control } = useFormContext()

  const rules = useFieldRules({ required })

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      rules={rules}
      render={({ field: { ref, value, onChange }, fieldState: { error } }) => {
        const errorText = parseFieldErrorText(error)

        return (
          <FormControl error={!!errorText} fullWidth>
            <FormLabel>{label + (required ? ' *' : '')}</FormLabel>
            <RadioGroup
              value={value}
              onChange={(e, value) => onChange(value)}
              name={name}
            >
              {options.map((option, idx) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio inputRef={idx === 0 ? ref : undefined} size='small' />}
                  label={option.label}
                  
                />
              ))}
            </RadioGroup>

            {errorText && <FormHelperText>{errorText}</FormHelperText>}
          </FormControl>
        )
      }}
    />
  )
}

export default BgiRadioGroup
