import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiConfig } from './api'
import { CreateRemT, RemT } from '../types/remedialTeaching'


export const remsApi = createApi({
  reducerPath: 'remsApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/rems`,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['Rem'],
  endpoints: (builder) => ({
    getAllRem: builder.query<RemT[], void>({
      query: () => '',
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Rem' as const, id })), 'Rem']
          : ['Rem'],
    }),
    createRem: builder.mutation<RemT, CreateRemT>({
      query: (body) => ({url: `/`, method: 'POST', body})
    }), 
    deleteRem: builder.mutation<void, string>({
      query: (remId) => ({url: `/${remId}`, method: 'DELETE'}),
      invalidatesTags: ['Rem']
    }),
  }),
})

export const {
  useGetAllRemQuery,
  useCreateRemMutation,
  useDeleteRemMutation,
} = remsApi