import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiConfig } from './api'
import { CreateChallengerT, ChallengerT } from '../types/challenger'

export const challengersApi = createApi({
  reducerPath: 'challengersApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/challengers`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getChallengers: builder.query<ChallengerT, void>({ query: () => '' }),

    getChallenger: builder.query<ChallengerT, void>({
      query: (challengerId) => `/${challengerId}`
    }),

    createChallenger: builder.mutation<ChallengerT, Omit<ChallengerT, 'id'>>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),

    updateChallenger: builder.mutation<ChallengerT, Partial<CreateChallengerT> & Pick<ChallengerT, 'id'>>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
    }),

    deleteChallenger: builder.mutation<void, string>({
      query: (challengerId) => ({ url: `/${challengerId}`, method: 'DELETE' }),
    }),

  })
})

export const {
  useGetChallengersQuery,
  useGetChallengerQuery,
  useCreateChallengerMutation,
  useUpdateChallengerMutation,
  useDeleteChallengerMutation,
} = challengersApi