/** @jsxImportSource @emotion/react */
import { useMemo, FC, useRef } from 'react'
import { FILES_BASE_URL } from '../../constants/FILES_BASE_URL'
import { css } from '@emotion/react'
import { Ad } from '../../types/ad'
import genId from '../../utils/genId'

interface SlideStickyAdProps {
  ad: Ad
}

const SlideStickyAd: FC<SlideStickyAdProps> = ({ ad }) => {
  const imageUrl = useMemo(() => {
    const imageFileHash = ad.imageFile.fileHash

    return `${FILES_BASE_URL}/${imageFileHash}`
  }, [ad])


  const img = <img css={styles.img} src={imageUrl} alt="" />

  return ad.url ? (
    <a css={styles.root} href={ad.url} target="_blank">
      {img}
    </a>
  ) : (
    <div css={styles.root}>{img}</div>
  )
}

const styles = {
  root: css({
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '@media (max-width: 564px)': {
      marginBottom: 8,
    },
  }),
  img: css({
    width: '100%',
    maxWidth: 300,
    borderTopLeftRadius: '0.7rem',
    // borderBottomRightRadius: '0.4rem',
  }),
}

export default SlideStickyAd
