const inverseObj = <T extends Record<string, string | string[]>>(
  obj: T
): Record<string, string> => {
  const result: Record<string, string> = {}

  Object.entries(obj).forEach(([propKey, propVal]) => {
    if (typeof propVal === 'string') {
      result[propVal] = propKey
    } else {
      propVal.forEach((propValPiece) => {
        result[propValPiece] = propKey
      })
    }
  })

  return result
}

export default inverseObj
