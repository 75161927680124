import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiConfig } from './api'
import { CreateContactT, ContactT, UpdateContactT } from '../types/contact'

export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/contactUs`,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['contact'],
  endpoints: (builder) => ({
    getContacts: builder.query<ContactT[], void>({
      query: () => '',
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'contact' as const, id })), 'contact']
          : ['contact'],
    }),

    createContact: builder.mutation<ContactT, CreateContactT>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),


    deleteContact: builder.mutation<void, string>({
      query: (contactUsId) => ({ url: `/${contactUsId}`, method: 'DELETE' }),
    }),



    updateContact: builder.mutation<ContactT, UpdateContactT>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
      invalidatesTags: ['contact'],
    })

  }),
})

export const {
  useGetContactsQuery,
  useCreateContactMutation,
  useDeleteContactMutation,
  useUpdateContactMutation  
} = contactApi