/** @jsxImportSource @emotion/react */

import { useLazySearchSlidesQuery } from '../../store/slidesApi'
import { CircularProgress, InputAdornment, TextField } from '@mui/material'
import { useDebouncedCallback } from 'use-debounce'
import { BgiLink } from '../../UI/BgiLink'
import Layout from '../page/Layout'
import { useTranslation } from 'react-i18next'
import UseDynamicLanguageStyle from '../i18n/UseDynamicLanguageStyle'
// import { css } from '@emotion/react'


const SearchSlidesPage = () => {
  const [searchSlides, { data: slides, isFetching }] = useLazySearchSlidesQuery()
  const { t } = useTranslation('SearchSlide')
  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()

  const debounced = useDebouncedCallback(
    // function
    (value: string) => {
      searchSlides(value)
    },
    // delay in ms
    300
  )



  return (
    <Layout>
      <div css={dynamicLanguageStyle}>


        <h2>{t('Search_slides_by_text_or_slide_number')}</h2>
        <TextField
          label={t('Search_slides')}
          name="searchSlides"
          type="text"
          style={{ marginBottom: 16 }}
          fullWidth
          onChange={(e) => debounced(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start" style={{ width: 24, height: 24 }}>
                {isFetching && <CircularProgress size={24} />}
              </InputAdornment>
            ),
          }}
        />

        {slides?.map(slide => (
          <div key={slide.id}>
            <BgiLink to={`/data-entry/slides/${slide.id}/update`}>{'question' in slide ? slide.question : slide.title}</BgiLink>
          </div>
        ))}

        <div style={{ marginBottom: "20rem" }} />
      </div>
    </Layout>
  )
}

export default SearchSlidesPage
