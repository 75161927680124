import { Container, Typography } from '@mui/material'

const NotFound = () => {
  return (
    <Container>
      <div style={{ height: 30 }}></div>

      <Typography variant="h5" style={{ textAlign: 'center' }}>
        Page Not Found 404
      </Typography>

      <div style={{ height: 30 }}></div>
    </Container>
  )
}

export default NotFound
