/** @jsxImportSource @emotion/react */

import { Button, Paper, Stack } from '@mui/material'
import {
  FormProvider,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { BgiTextField } from '../../../UI/BgiTextField'
import { AdFv } from '../../../types/ad'
import { useNavigate } from 'react-router-dom'
import { BgiLink } from '../../../UI/BgiLink'
import {
  useCreateAdMutation,
  useDeleteAdMutation,
  useUpdateAdMutation,
} from '../../../store/adsApi'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'
import { useSnackbar } from 'notistack'
import { AssetField } from '../../gallery/AssetField'
import { BgiSelect } from '../../../UI/BgiSelect'
import { BgiCheckbox } from '../../../UI/BgiCheckbox'
import { CoursesAdd } from '../../CoursesAdd'
import Layout from '../../page/Layout'
import { useTranslation } from 'react-i18next'
import UseDynamicLanguageStyle from '../../i18n/UseDynamicLanguageStyle'
import { css } from '@emotion/react'


// ---Comp---------------------------------------------------------------------------
// ------------------------------------------------------------------------------
// ------------------------------------------------------------------------------
export const AdForm = () => {
  const formContext = useFormContext<AdFv>()
  const { control, handleSubmit, reset, clearErrors } = formContext

  const { t } = useTranslation(['Ads', 'common'])
  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()

  const coursesField = useFieldArray({
    control,
    name: 'courses',
    keyName: 'localId',
  })

  const navigate = useNavigate()

  const adId = useWatch({ control, name: 'id' })
  const adType = useWatch({ control, name: 'type' })
  const isCreateForm = !adId
  const { enqueueSnackbar } = useSnackbar()

  const [createAd, { isLoading: isCreating }] = useCreateAdMutation()

  const [updateAd, { isLoading: isUpdating }] = useUpdateAdMutation()

  const [deleteAd, { isLoading: isDeleting }] = useDeleteAdMutation()


  // ---Message----------------------------------------------------------------------
  // --------------------------------------------------------------------------------
  const SUCCESS_MSGS = {
    create: (adId: string) => (
      <p>
        {t('Ad_has_been_created_successfully_with')}{' '}
        <BgiLink color="#fff" to={`/data-entry/ads/${adId}/update`}>
          #{adId}
        </BgiLink>
      </p>
    ),
    update: t('The_ad_has_been_updated_successfully'),
  }

  const getErrMsg = buildGetErrMsg()

  const AD_TYPES = [
    { label: 'Banner', value: 'banner' },
    { label: 'Sticky', value: 'sticky' },
  ]
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------


  const onSubmit = async (ad: AdFv) => {
    const { id, imageFile, courses, ...restPayload } = ad

    const payload = {
      ...restPayload,
      imageFileId: imageFile.id,
      coursesIds: courses.map(course => course.id)
    }

    if (!id) {
      const result = await createAd(payload)

      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['create'](result.data.id),
          variant: 'success',
        })
        reset()
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    } else {
      const result = await updateAd({ id, ...payload })

      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['update'],
          variant: 'success',
        })
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    }
  }

  const onDelete = async () => {
    if (!adId) return

    const confirmationMsg = t('Are_you_sure_you_want_to_delete_this_ad')

    if (!window.confirm(confirmationMsg)) return

    const result = await deleteAd(adId)

    if ('data' in result) {
      enqueueSnackbar({
        message: t('Ad_Has_been_deleted_successfully'),
        variant: 'success',
      })

      navigate(`/data-entry/ads`)
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  }

  // ---return-------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  return (
    <div css={dynamicLanguageStyle}>
      <Layout>
        <FormProvider {...formContext}>

          <div style={{ width: '100%', height: 30 }} />

          <Paper
            style={{
              padding: '1rem',
              marginBottom: '2rem',
            }}
          >
            <h2 style={{ marginBottom: 16 }}>
              {isCreateForm ? t('common:Create') : t('common:Update')} {t('Ad')}
            </h2>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <div>
                  <BgiCheckbox name="isDeactivated" label={t('Deactivate')} />
                </div>

                <BgiTextField
                  label={t('Title')}
                  name="title"
                  fullWidth
                  size="small"
                  required
                  maxLength={255}
                />

                <BgiSelect
                  name="type"
                  options={AD_TYPES}
                  label={t('Type')}
                  fullWidth
                  size="small"
                  required
                  noOptionText="Chose a slide type"
                  onChange={() => clearErrors()}
                />

                <div>
                  <p style={{ fontSize: 14 }}>
                    <b>{t('Be_careful')}</b>{' '}
                    <span>
                      {t('App_will_not_validate_the_image_size_so_its_your')} <br />
                      {t('Responsibility_to_make_sure_the_ads_image_size_follow_the')}{' '}
                      {t('Same_below_sizes')}
                    </span>
                  </p>

                  <ul style={{ marginBottom: 8, paddingLeft: 20, fontSize: 14 }}>
                    <li>{t('Banner')}: 804px * 500px</li>
                    <li>{t('Sticky')}: 300px * 38px</li>
                  </ul>

                  <AssetField name="imageFile" fileKind="image" required />
                </div>

                <BgiTextField
                  label="Url"
                  name="url"
                  fullWidth
                  size="small"
                  maxLength={1024}
                />

                <CoursesAdd coursesField={coursesField} />

                <Stack direction="row" justifyContent="space-between">
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isCreating || isUpdating}
                  >
                    {isCreateForm ? t('common:Create') : t('common:Update')}
                  </Button>

                  {!isCreateForm && (
                    <Button
                      color="error"
                      variant="outlined"
                      onClick={onDelete}
                      disabled={isDeleting}
                    >
                      {t('common:Delete')}
                    </Button>
                  )}
                </Stack>
              </Stack>
            </form>
          </Paper>

          <div style={{ width: '100%', height: 30 }} />
        </FormProvider>
      </Layout>
    </div>
  )
}
