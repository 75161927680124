import { Alert, Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../../hooks/useFetch'
import { FetchedSingleSlide } from '../../../types/slide'
import { SlideForm } from './SlideForm'
import LoadingPage from '../../LoadingPage'
import { SlideFormValues } from '../../../utils/getSlidePayload'
import {
  useGetCategoriesQuery,
  useGetIncompletesQuery,
} from '../../../store/slidesApi'
import FieldValue from '../../FieldValue'

const DEFAULT_ANSWERS = [...new Array(1)].fill({ isCorrect: false, text: '' })

// --comp---------------------------------------------
// ---------------------------------------------------
export const UpdateSlide = () => {
  const { slideId } = useParams()
  const formContext = useForm<{ slide: SlideFormValues }>()

  const { data: categories } = useGetCategoriesQuery()
  const { data: incompletes } = useGetIncompletesQuery()

  const [loading, slide, error] = useFetch<FetchedSingleSlide>(
    `/slides/${slideId}`,
    (slide) => {
      if (slide) {
        let shouldSetAnswers = true
        const resultSlide: any = {};

        Object.keys(slide).map((untypedAttrKey) => {
          const attrKey = untypedAttrKey as keyof FetchedSingleSlide

          if (attrKey !== 'unitId') {
            resultSlide[attrKey] = slide[attrKey];
          }

          if ((attrKey as any) === 'answers') {
            const answers = slide[attrKey]
            if (answers instanceof Array && answers.length > 0) shouldSetAnswers = false
          }
        })

        if (shouldSetAnswers) resultSlide.answers = DEFAULT_ANSWERS;

        formContext.setValue('slide', resultSlide as SlideFormValues);
      }
    }
  )

  if (loading || !categories || !incompletes) return <LoadingPage />

  return (
    <>
      {error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <FormProvider {...formContext}>
          <SlideForm categories={categories} incompletes={incompletes} />
        </FormProvider>
      )}
    </>
  )
}
