import { useMemo } from 'react'
import { SlideView } from '../../../types/SlideView'
import { BannerAdAutoGenSlide } from '../../../types/slide'
import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL'
import { css } from '@emotion/css'

const AuoGenSlideViewBannerAd: SlideView<BannerAdAutoGenSlide> = ({
  slide,
}) => {
  const imageUrl = useMemo(() => {
    const imageFileHash = slide.imageFile.fileHash

    return `${FILES_BASE_URL}/${imageFileHash}`
  }, [])

  const img = <img className={styles.img} src={imageUrl} alt="" />

  return slide.url ? (
    <a href={slide.url} target="_blank">
      {img}
    </a>
  ) : (
    img
  )
}

const styles = {
  img: css({
    margin: '0 auto',
    maxWidth: '100%',
    display: 'block',
    borderRadius: '0.4rem',
  }),
}

export default AuoGenSlideViewBannerAd
