import { FC, useState, MouseEvent } from 'react'
import { BasicChapter } from '../../types/chapter'
import { css } from '@emotion/css'
import { List, ListItemButton, ListItemIcon, Popover } from '@mui/material'
import ChapterIcon from '@mui/icons-material/Book'
import { Link as RouterLink } from 'react-router-dom'
import { Course } from '../../types/course'
import { DirT } from '../../types/dir'

interface NextChapterProps {
  course: Course
  courseChapters: BasicChapter[]
  curChapterId?: string
  isCurExam?: boolean
  dir: DirT
}

const NextChapter: FC<NextChapterProps> = ({
  course,
  courseChapters,
  curChapterId,
  isCurExam,
  dir
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <button
        style={{ width: 34, height: 27 }}
        className={styles.toggleButton}
        onClick={handleClick}
      >
        <img style={{ width: 34, height: 27 }} src="/bgi-icon.png" alt="" />
      </button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: dir === 'rtl' ? 'right' : 'left',
        }}
        transformOrigin={{ vertical: 'top', horizontal: dir === 'rtl' ? 'right' : 'left' }}
        PaperProps={{
          style: { width: 'calc(100% - 32px)', maxWidth: 300 },
        }}
      >
        <div style={{ direction: 'rtl', width: '100%' }}>
          <div style={{ padding: '8px 16px', textAlign: 'center' }}>
            {course.title}
          </div>

          <List>
            {courseChapters.map((chapter) => (
              <ListItemButton
                key={chapter.id}
                component={RouterLink}
                to={`/chapters/${chapter.id}/slides`}
                style={{
                  background:
                    chapter.id === curChapterId
                      ? 'rgba(25, 118, 210, 0.2)'
                      : undefined,
                }}
              >
                <ListItemIcon style={{ minWidth: 32 }}>
                  <ChapterIcon style={{ fontSize: 20 }} />
                </ListItemIcon>

                <span style={{ fontSize: 13 }}>{chapter.title}</span>
              </ListItemButton>
            ))}

            <ListItemButton
              component={RouterLink}
              to={`/courses/${course.id}/exam`}
              style={{
                background: isCurExam ? 'rgba(25, 118, 210, 0.2)' : undefined,
              }}
            >
              <span style={{ fontSize: 13 }}>اختبار عام للكرس</span>
            </ListItemButton>
          </List>
        </div>
      </Popover>
    </>
  )
}

const styles = {
  toggleButton: css({
    display: 'inline-flex',
    border: 'none',
    background: 'transparent',
    padding: 0,
    cursor: 'pointer',
  }),
}

export default NextChapter
