import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname
    });
  }, [location]);
}


// ---------------------------------------------

export const useEventTracking = () => {
  const trackEvent = (category: string, action: string, label?: string) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };

  return trackEvent;
}

