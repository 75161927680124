import { Alert, Button, Container } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { BgiLink } from '../../../UI/BgiLink'
import { useEffect, useMemo, useState } from 'react'
import { useLazyGetSlidesQuery, useUpdateSlideIndexMutation } from '../../../store/slidesApi'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'
import { useGetUnitQuery } from '../../../store/unitsApi'
import LoadingPage from '../../LoadingPage'
import PageHeader from '../../../UI/PageHeader'
import { useSnackbar } from 'notistack'
import { Slide } from '../../../types/slide'
import EnterTextField from '../../../UI/EnterTextField'




const getErrMsg = buildGetErrMsg()

// ---Comp------------------------------------------------------------------
// -------------------------------------------------------------------------
const SlidesListPage = () => {
  const { unitId } = useParams() as { unitId: string }

  const { data: unit, isLoading: isUnitLoading, error: unitErr, } = useGetUnitQuery(unitId)

  // ----oder1-----------------------------------------------------------------
  // -------------------------------------------------------------------------
  const [slides, setSlides] = useState<Slide[]>([])

  const [getSlides, { isSuccess: isSlidesFetched, error: slidesErr }] = useLazyGetSlidesQuery()

  useEffect(() => {
    (async () => {
      const result = await getSlides(unitId)
      if ('data' in result && result.data) {

        setSlides(result.data)
      }
    })()
  }, [unitId])

  const { enqueueSnackbar } = useSnackbar()

  const [updateSlideIndex] = useUpdateSlideIndexMutation()

  const randomText = useMemo(() => Date.now().toString() + Math.random(), [])

  const [reorderItem, setReorderItem] = useState<{
    slideId: string
    newIndex: number
  } | null>(null)

  const updateSlideOrder = async (slideId: string, newIndex: number) => {
    if (!slides) return;

    setReorderItem({ slideId, newIndex })

    const result = await updateSlideIndex({
      id: slideId,
      index: newIndex,
    })



    if ('data' in result) {
      const slideIndexes: Record<string, number> = {}


      slides.forEach(
        (slide, index) => (slideIndexes[slide.id] = index))

      const newSlides = result.data.map(
        (slideId) => slides[slideIndexes[slideId]])
        

      setSlides(newSlides)
      enqueueSnackbar({ message: 'تم إعادة الترتيب بنجاح', variant: 'success' })
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }

    setReorderItem(null)
  }

  const handleReorderEnter = (slideId: string) => (newValue: string) => {
    const newIndex = Number(newValue) - 1
    if (!slides || newIndex + 1 > slides.length) return
    updateSlideOrder(slideId, newIndex)
  }
  // ----------------------------------------------------------------oder-----
  // -------------------------------------------------------------------------



  const isLoading = isUnitLoading || !isSlidesFetched
  const error = unitErr || slidesErr

  if (isLoading) return <LoadingPage />






  // ---return----------------------------------------------------------------
  // -------------------------------------------------------------------------
  return (
    <Container maxWidth="md">
      <BgiLink to="/data-entry/units">Back to the units page</BgiLink>

      <div style={{ width: '100%', height: 30 }} />

      {error || !slides || !unit ? (
        <Alert severity="error">{getErrMsg(error)}</Alert>
      ) : (
        <>
          <PageHeader
            style={{ marginBottom: 24 }}
            title={unit.briefTitle}
            actions={
              <Button
                component={Link}
                variant="contained"
                to={`/data-entry/slides/create?unitId=${unitId}`}
              >
                Create Slide
              </Button>
            }
          />

          {slides.length > 0 ? (
            <ul style={{ paddingLeft: 24 }}>
              {slides.map((slide, slideIdx) => (
                <li key={slide.id}>
                  <EnterTextField
                    style={{ flexShrink: 1, width: 70 }}
                    value={
                      reorderItem?.slideId === slide.id
                        ? String(reorderItem.newIndex + 1)
                        : String(slideIdx + 1)
                    }
                    autoComplete="off"
                    name={randomText + slideIdx}
                    size="small"
                    variant="outlined"
                    regex={/^([1-9][0-9]*)?$/}
                    disabled={!!reorderItem}
                    onEnter={handleReorderEnter(slide.id)}
                  />

                  <BgiLink to={`/data-entry/slides/${slide.id}/update`}>
                    {slide.title}
                  </BgiLink>
                </li>
              ))}
            </ul>
          ) : (
            <p>There is no slides for this unit yet</p>
          )}
        </>
      )}

      <div style={{ width: '100%', height: 30 }} />
    </Container>
  )
}

export default SlidesListPage
