import { Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../../hooks/useFetch'
import { Ad, AdFv } from '../../../types/ad'
import { AdForm } from './AdForm'

export const UpdateAd = () => {
  const { adId } = useParams()
  const formContext = useForm<AdFv>()

  const [loading, ad, error] = useFetch<Ad>(`/ads/${adId}`, (ad) => {
    if (ad) {
      Object.keys(ad).map((untypedAdKey) => {
        const adKey = untypedAdKey as keyof Ad
        formContext.setValue(adKey, ad[adKey])
      })
    }
  })

  return (
    <Container maxWidth="md">
      {error || !ad ? (
        error
      ) : (
        <FormProvider {...formContext}>
          <AdForm />
        </FormProvider>
      )}
    </Container>
  )
}
