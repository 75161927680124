/** @jsxImportSource @emotion/react */

import { useTranslation } from 'react-i18next'
import UseDynamicLanguageStyle from '../i18n/UseDynamicLanguageStyle'
import { css } from '@emotion/react'
import { Container, Typography } from '@mui/material'
import Layout from './Layout'
import { SocialMediaLinksPage } from './SocialMediaLinksPage'


const HomePage = () => {
  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()
  const { t } = useTranslation("HomePage")


  return (
    <Layout>
      <div css={dynamicLanguageStyle}>
        <div css={[styles.allPage]}>
          <Container>
            <div css={styles.bannerBgi}>
              <img src="BannerBGi.jpg" />
            </div>
            <div css={styles.smld}>
              <SocialMediaLinksPage />
            </div>


            <div css={styles.ourWay}>
              <h1>{t('Our_way')}</h1>
              <div css={styles.philosophy}>
                <div>
                  <h3>{t('We_work_with_passion')}</h3>
                  <p>{t('Home_page_10')}</p>
                  <p>{t('Home_page_20')}</p>
                </div>
                <div>
                  <h3>{t('Home_page_30')}</h3>
                  <p>{t('Home_page_40')}</p>
                  <p>{t('Home_page_50')}</p>
                </div>
              </div>
            </div>

            <div css={styles.HomeImg}>
              <Typography css={styles.hText}
                variant="h5" gutterBottom
              >
                <p>{t('Home_page_60')}</p>
              </Typography>
              <img src="lamda2001.jpg" />
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  )
}


// -------------------------------------
const styles = {
  allPage: css({
    backgroundColor: 'whitesmoke',
    '@media (max-width: 700px)': {
      margin: '0.8rem',
    }
  }),
  smld: css({
    // direction: 'rtl',
  }),
  bannerBgi: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    'img': {
      width: '100%',
      height: 'auto',
      borderRadius: '0.8rem',
    },
  }),
  ourWay: css({
    marginTop: '1.5rem',
    'h1': { textAlign: 'center' },
    'div, p': {
      marginTop: '1.5rem',
    },
  }),
  philosophy: css({
    // direction: 'rtl',
    marginTop: '1.5rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '2rem',
    'p, h3': {
      textAlign: 'center',
      fontSize: '1.5rem',
    },
    '@media (max-width: 700px)': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      '& p, & h3': {
        textAlign: 'center',
        fontSize: '1rem',
      },
    },
  }),
  hText: css({
    textAlign: 'center',
    backgroundColor: '#fffce961',
  }),
  HomeImg: css({
    marginTop: '20px',
    'img': {
      width: '100%',
      height: 'auto',
      borderRadius: '0.8rem',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
    },
  }),

}

export default HomePage

