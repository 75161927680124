import { LinearProgress } from '@mui/material'

export const TopLinearProgress = () => {
  return (
    <LinearProgress
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: 4,
        width: '100%',
        zIndex: 5,
      }}
    />
  )
}
