/** @jsxImportSource @emotion/react */

import { FormProvider, useForm } from 'react-hook-form'
import { Alert, Button, Container, Paper } from '@mui/material'
import { BgiTextField } from '../../UI/BgiTextField'
import { buildGetErrMsg } from '../../utils/buildGetErrMsg';
import { useSnackbar } from 'notistack';
import { useBeginResetPasswordMutation } from '../../store/authApi';
import { BgiLink } from '../../UI/BgiLink';
import { BeginResetPasswordT } from '../../types/auth';
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next';


const getErrMsg = buildGetErrMsg({
  'NOT_FOUND': (
    <>
      <span>Email not registered you can</span> <BgiLink to="/signup" style={{ color: "white" }}>sign up here</BgiLink>
    </>
  )
})
// ----------------------------------------------------------
// ----------------------------------------------------------

const BeginResetPassword = () => {
  const formContext = useForm<BeginResetPasswordT>()
  const { handleSubmit, getValues } = formContext
  const [beginResetPassword, { isLoading, isSuccess }] = useBeginResetPasswordMutation()

  const { t } = useTranslation('Signup_login')
  const { enqueueSnackbar } = useSnackbar()


  const onSubmit = handleSubmit(async ({ email }) => {
    const result = await beginResetPassword({ email })

    if (!('data' in result)) {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' });
    }
  })

  return (
    <Container maxWidth="sm" dir="rtl"  >
      <Paper elevation={7} >
        <div css={styles.putYourEmail}>
          {isSuccess ? (
            <Alert severity='success' dir="rtl">

              {t('A_password_reset_request_has_been_sent_to_your_email')}              
              <br />
              {getValues().email}
              <br />
              {t('Go_to_your_email_and_click_on_the_confirmation_link_to_change_your_password')}
              {t('If_you_do_not_find_the_email_in_your_inbox_search_for_it_in_the_spam_under_the_name')}
              <br /> {t('BGi_reset_your_accounts_password_at_BGI')}
            </Alert>
          ) : (
            <FormProvider {...formContext}>
              <form onSubmit={onSubmit} >
                <h4 style={{ margin: '1rem', textAlign: 'center' }}>{t('Enter_your_email')}
                <br />
                {(t('And_a_message_containing_a_link_to_set_a_new_password_will_be_sent_to_your_email'))} .

                </h4>
                <BgiTextField
                  name="email"
                  label={t('Enter_your_email')}
                  required
                  style={{ margin: '1rem 1rem 1rem 1rem', width: '95%' }}
                  dir='ltr'

                />
                <Button
                  type="submit"
                  disabled={isLoading}
                  variant="contained"
                  style={{ margin: '1rem' }}
                >
                  {t('Submit')}
                </Button>
              </form>
            </FormProvider>
          )}
        </div>
      </Paper>
    </Container>
  )
}




const styles = {
  putYourEmail: css({
    marginTop: '10rem',
  }),
  // 'putYourEmail > *': {
  //   margin: '1rem',
  // }
}

export default BeginResetPassword


