/** @jsxImportSource @emotion/react */
import LoadingPage from '../../LoadingPage'
import {Button, Container, Paper, Stack } from '@mui/material'
import { BgiLink } from '../../../UI/BgiLink'
import { useGetAnnouncementsQuery } from '../../../store/announcementsApi'
import Layout from '../../page/Layout'
import PageHeader from '../../../UI/PageHeader'
import { Link } from 'react-router-dom'


const AnnouncementsPage = () => {
  const { isFetching, error, data: announcements } = useGetAnnouncementsQuery()

  if (isFetching) return <LoadingPage />

  // ----------------------------------------------------------------
  return (
    <Layout>
      <div>
        <Container maxWidth="md">
          <BgiLink to={'/'}>Back to home page</BgiLink>

          <PageHeader
            style={{ marginBottom: 24 }}
            title="Announcements"
            actions={
              <Button
                component={Link}
                variant="contained"
                to="/data-entry/announcements/create"
              >
                Create Announcement
              </Button>
            }
          />

          <div style={{ width: '100%', height: 30 }} />

          {error || !announcements ? (
            <p>Unknown Error occurred</p>
          ) : (
            <Stack spacing={3}>
              {announcements?.map((announcement) => (
                <Paper key={announcement.id}>
                  <BgiLink to={`/data-entry/announcements/${announcement.id}/update`}>
                    {announcement.title}
                  </BgiLink>
                  <p>
                    {announcement.description}
                  </p>
                </Paper>
              ))}
            </Stack>
          )}


          <div style={{ width: '100%', height: 30 }} />
        </Container>
      </div>
    </Layout>
  )
}

export default AnnouncementsPage
