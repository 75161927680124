import { useEffect, useRef } from 'react';

export const useClickOutside = (onClickOutside: () => void) => {
  const domNode = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleClick = (event: any) => {
      const domElem = domNode.current!;


      if (!domElem.contains(event.target)) {
        onClickOutside();
      }
    };

    document.addEventListener('click', handleClick, true);
    return () => document.removeEventListener('click', handleClick, true);
  }, []);

  return domNode;
};


// لأننا لا يمكن أن نتسخدم 
// onblur
// لوجود الديف ، فإننا نجد أنفسنا مضطرين إلى عمل مكتبة صغيرة تقوم بهذا العمل.
