import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Category, Incomplete, Slide, UpdateSlideIndexInput } from '../types/slide'
import { apiConfig } from './api'

export const slidesApi = createApi({
  reducerPath: 'slidesApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/slides`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getSlides: builder.query<Slide[], string>({
      query: (unitId: string) => `?unitId=${unitId}`,
    }),
    searchSlides: builder.query<Slide[], string>({
      query: (search: string) => `/search?q=${search}`,
    }),
    getSlide: builder.query<Slide | null, string>({
      query: (slideId) => `/${slideId}`,
    }),
    createSlide: builder.mutation<Slide, Omit<Slide, 'id'>>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),
    updateSlide: builder.mutation<Slide, Partial<Slide> & Pick<Slide, 'id'>>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PUT', body }),
    }),
    deleteSlide: builder.mutation<void, string>({
      query: (slideId) => ({ url: `/${slideId}`, method: 'DELETE' }),
    }),
    getCategories: builder.query<Category[], void>({
      query: () => `/categories`,
    }),
    getIncompletes: builder.query<Incomplete[], void>({
      query: () => `/incompletes`,
    }),
    getSlidesPoints: builder.query<Record<number, number>, string[]>({
      query: (coursesIds) => {
        const query = new URLSearchParams()
        coursesIds.forEach((courseId) => query.append('courseId', courseId))
        return `/stats/slidesPoints?${query}`
      },
    }),
    // ------------------------------------------------------------------------------------
    updateSlideIndex: builder.mutation<string[], UpdateSlideIndexInput>({
      query: ({ id, ...body }) => ({
        url: `/${id}/updateIndex`,
        method: 'PATCH',
        body,
      })
    }),
  }),
})

export const {
  useGetSlidesQuery,
  useLazyGetSlidesQuery,
  useLazySearchSlidesQuery,
  useGetSlideQuery,
  useCreateSlideMutation,
  useUpdateSlideMutation,
  useDeleteSlideMutation,
  useGetCategoriesQuery,
  useGetIncompletesQuery,
  useLazyGetSlidesPointsQuery,
  useUpdateSlideIndexMutation
} = slidesApi
