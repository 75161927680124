import { FC, useMemo } from 'react'
import { Subcollection } from '../../types/subcollection'
import { Button, Card, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { FILES_BASE_URL } from '../../constants/FILES_BASE_URL'
import { useAppSelector } from '../../store/hooks'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

interface SubcollectionsGridProps {
  subcollections: Subcollection[]
  to: (subcollectionId: string) => string
}

const SubcollectionsGrid: FC<SubcollectionsGridProps> = ({ subcollections, to }) => {
  const userMe = useAppSelector((state) => state.auth.userMe)

  const urls: string[] = useMemo(() => subcollections.map(({ id }) => to(id)), [subcollections])

  const { t } = useTranslation(['Collections', 'common'])

  if (subcollections.length === 0) {
    return <p>{t('There_is_no_subcollections_yet')}</p>
  }

  return (
    <Grid container spacing={2} alignItems="stretch" style={{ direction: 'rtl' }}>
      {subcollections.map((subcollection, idx) => (
        <Grid item xs={12} sm={6} md={6} lg={4} key={subcollection.id}>
          <div>
            <Card
              sx={{
                width: '100%',
                height: '100%',
                display: 'block',
                color: 'inherit',
                textDecoration: 'none',
                paddingTop: 2,
                paddingBottom: 2,
              }}
              component={Link}
              to={`/subcollections/${subcollection.id}/courses`}
            >
              <Stack spacing={2}>
                <Typography sx={{ padding: '0 16px' }} variant="h6" component="div">
                  {subcollection.title}
                </Typography>

                {subcollection.imageFile && (
                  <CardMedia
                    style={{
                      width: '100%',
                      height: 200,
                      objectFit: 'cover',
                    }}
                    image={`${FILES_BASE_URL}/${subcollection.imageFile.fileHash}`}
                  />
                )}

                {subcollection.description.trim().length > 0 && (
                  <Typography variant="body2" color="text.secondary" component="div" sx={{ padding: '0 16px' }}>
                    {subcollection.description}
                  </Typography>
                )}
              </Stack>
            </Card>

            {userMe?.role === 'admin' && (
              <Button component={Link} to={urls[idx]} variant="outlined">
                {t('common:Update')}
              </Button>
            )}
          </div>
        </Grid>
      ))}
    </Grid>
  )
}

export default SubcollectionsGrid
