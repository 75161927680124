import { Button, Checkbox, FormControlLabel, Paper } from '@mui/material'
import { FC } from 'react'
import { BgiLink } from '../../UI/BgiLink'
import { FeedbackT } from '../../types/feedback'
import { useUpdateFeedbackMutation } from '../../store/feedbacksApi'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

interface FeedbackProps {
  feedback: FeedbackT
  onDelete: () => void
  isDeleting: boolean
}

const Feedback: FC<FeedbackProps> = ({ feedback, isDeleting, onDelete }) => {

  const [updateFeedback, { isLoading: isUpdating }] = useUpdateFeedbackMutation();
  const { t } = useTranslation(['Feedback', 'common'])

  return (
    <Paper key={feedback.id} style={{ backgroundColor: feedback.isArchived ? '#ECECEC' : '#FFF2D0' }}>
      <BgiLink to={`/data-entry/slides/${feedback.slideId}/update`}>
       {t('Slide_number:')} {feedback.slide.slideNum}  #{t('Slide')}: {feedback.slide.title}
      </BgiLink>
      <h3>{feedback.title}</h3>
      <h5>
        {feedback.message}
      </h5>

      <BgiLink to={`/data-entry/users/${feedback.userId}/update`}>
        {t('User:')}: {feedback.user.lastName}
      </BgiLink>

      <p>{dayjs(feedback.createdAt).format('YYYY-MM-DD dddd HH:mm')}</p>

      <Button
        disabled={isDeleting}
        onClick={onDelete}
        color="error"
        variant="contained"
      >
        {t('common:Delete')}
      </Button>

      <FormControlLabel
        control={
          <Checkbox
            checked={feedback.isArchived}
            onChange={(e, newValue) => updateFeedback({
              id: feedback.id,
              isArchived: newValue
            })}
            disabled={isUpdating}
          />
        }
        label={t('IsArchived')}
      />
    </Paper>
  )
}

export default Feedback
