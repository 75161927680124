import { FormProvider, useForm } from 'react-hook-form';
import { CourseI } from '../../../types/course';
import { CourseForm } from './CourseForm';
import { Container } from '@mui/material';
import { useGetSubcollectionQuery } from '../../../store/subcollectionsApi';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Subcollection } from '../../../types/subcollection';
import LoadingPage from '../../LoadingPage';

export const CreateCourse = () => {
  const [searchParams] = useSearchParams();
  const subcollectionId = searchParams.get('subcollectionId') as string;
  const [isInitialSet, setIsInitialSet] = useState(false)

  const formContext = useForm<CourseI & { subcollection: Subcollection }>({
    defaultValues: {
      isButtonCommentsVisible: true,
      isPointsVisible: true,
      isDownloadVisible: true,

    },
  });

  const { setValue } = formContext

  console.log('subcollectionId',subcollectionId)


  const { data: subcollection } = useGetSubcollectionQuery(subcollectionId)

  useEffect(() => {
    if (subcollection && !isInitialSet) {
      setValue('subcollection', subcollection)
      setIsInitialSet(true);
    }
  }, [subcollection])

  if (!subcollectionId) return <p>please specify ?subcollectionId= in the URL (e.g. ?subcollectionId=10)</p>
  if (!isInitialSet) return <LoadingPage />

  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <CourseForm />
      </FormProvider>
    </Container>
  );
};
