import { FC, useState, FormEventHandler, Fragment, useMemo } from 'react'
import { TextField, Button, Paper, Alert } from '@mui/material'
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { Add as AddIcon } from '@mui/icons-material'
import { axiosInstance } from '../../../utils/axiosInstance'
import { Chapter } from '../../../types/chapter'
import { useFetch } from '../../../hooks/useFetch'
import { BgiCheckbox } from '../../../UI/BgiCheckbox'
import FieldValue from '../../FieldValue'
import { BgiLink } from '../../../UI/BgiLink'
import { AxiosError } from 'axios'
import { css } from '@emotion/css'
import { BgiTextField } from '../../../UI/BgiTextField'
import { ChapterUnitsForm } from './ChapterUnitsForm'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'
import { ResErr } from '../../../utils/parseResErr'
import { useSnackbar } from 'notistack'
import EnterTextField from '../../../UI/EnterTextField'
import { useUpdateChapterIndexMutation } from '../../../store/chaptersApi'
import Layout from '../../page/Layout'

interface CourseChaptersForm {
  courseId: string
}

interface ChapterFormData {
  chapter: Chapter
  actionMetadata: {
    isUpdating: boolean
    updateError: string | null
    isDeleting: boolean
    deleteError: string | null
  }
}

interface ChaptersForm {
  chapters: ChapterFormData[]
}

const formatChapter = (chapter: Chapter) => {
  return {
    chapter,
    actionMetadata: {
      isUpdating: false,
      updateError: null,
      isDeleting: false,
      deleteError: null,
    },
  }
}

const getErrMsg = buildGetErrMsg({
  CHAPTER_IS_REFERENCED: "please remove chapter's units first",
})


// ---Comp------------------------------------------------------------------
// -------------------------------------------------------------------------
export const CourseChaptersForm: FC<CourseChaptersForm> = ({ courseId }) => {
  // for one item: boolean // true === stateValue
  // for multiple items (one opened at time): string (id) / null // chapterItemId === stateValue
  // for multiple items (multiple can be opened at the same time): string(id)[] / [] // stateValue.includes(chapterItemId)

  const [openedChapters, setOpenedChapters] = useState<string[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const chaptersForm = useForm<ChaptersForm>()
  const createChapterForm = useForm<{ title: string }>({
    defaultValues: {
      title: '',
    },
  })
  const { control, setValue, getValues } = chaptersForm
  const {
    fields: chapters,
    append,
    remove,
  } = useFieldArray({ control, name: 'chapters', keyName: 'localId' })

  const [updateChapterIndex] = useUpdateChapterIndexMutation()
  const randomText = useMemo(() => Date.now().toString() + Math.random(), [])

  const [loading] = useFetch<Chapter[]>(
    `/chapters?courseId=${courseId}`,
    (data) => {
      if (data) {
        setValue('chapters', data.map(formatChapter))
      }
    }
  )

  const snackErr = (e: any) => {
    const errMsg = getErrMsg(
      e.response
        ? { status: e.response.status, data: e.response.data }
        : new Error('Unknown')
    )

    enqueueSnackbar({ message: errMsg, variant: 'error' })
  }

  const handleCreate: FormEventHandler<HTMLFormElement> =
    createChapterForm.handleSubmit(async ({ title }) => {
      if (!title) return alert('Input title')

      try {
        const res = await axiosInstance.post(`/chapters`, {
          courseId,
          title,
        })

        append(formatChapter(res.data))

        createChapterForm.reset()

        enqueueSnackbar({
          message: 'The chapter has been created successfully',
          variant: 'success',
        })
      } catch (e: any) {
        snackErr(e)
      }
    })

  const handleUpdate = async (chapterIdx: number) => {
    setValue(`chapters.${chapterIdx}.actionMetadata.isUpdating`, true)
    setValue(`chapters.${chapterIdx}.actionMetadata.updateError`, null)

    const chapter = getValues().chapters[chapterIdx]
    const { id: chapterId, title } = chapter.chapter

    try {
      await axiosInstance.patch(`/chapters/${chapterId}`, { title })
      enqueueSnackbar({
        message: 'The chapter has been updated successfully',
        variant: 'success',
      })
    } catch (e: any) {
      snackErr(e)
    } finally {
      setValue(`chapters.${chapterIdx}.actionMetadata.isUpdating`, false)
    }
  }
  // ---str-5---------------------------------------------------------------------
  const handleDelete = async (chapterIdx: number) => {
    const confirmationMsg =
      'Are you sure you want to delete this chapter from this course'

    if (!window.confirm(confirmationMsg)) return

    setValue(`chapters.${chapterIdx}.actionMetadata.isDeleting`, true)
    setValue(`chapters.${chapterIdx}.actionMetadata.deleteError`, null)

    const chapter = getValues().chapters[chapterIdx]
    const { id: chapterId } = chapter.chapter

    try {
      await axiosInstance.delete(`/chapters/${chapterId}`)
      remove(chapterIdx)
      enqueueSnackbar({
        message: 'The chapter has been deleted successfully',
        variant: 'success',
      })
    } catch (e) {
      snackErr(e)
      setValue(`chapters.${chapterIdx}.actionMetadata.isDeleting`, false)
    }
  }
  // ---end-5---------------------------------------------------------------------

  const toggleChapter = (chapterId: string) => {
    setOpenedChapters((prevVal) =>
      prevVal.includes(chapterId)
        ? prevVal.filter((prevChapterId) => prevChapterId !== chapterId)
        : [...prevVal, chapterId]
    )
  }
  // -------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  const [reorderItem, setReorderItem] = useState<{
    chapterId: string
    newIndex: number
  } | null>(null)

  const updateChapterOrder = async (chapterId: string, newIndex: number) => {
    setReorderItem({ chapterId, newIndex })

    const result = await updateChapterIndex({
      id: chapterId,
      index: newIndex,
    })

    if ('data' in result) {
      const chapterIndexes: Record<string, number> = {}

      const chaptersFv = getValues().chapters

      chaptersFv.forEach(
        (chapter, index) => (chapterIndexes[chapter.chapter.id] = index)
      )

      const newChaptersFv = result.data.map(
        (chapterId) => chaptersFv[chapterIndexes[chapterId]]
      )

      setValue('chapters', newChaptersFv)
      enqueueSnackbar({ message: 'تم إعادة الترتيب بنجاح', variant: 'success' })
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }

    setReorderItem(null)
  }

  const handleReorderEnter = (chapterId: string) => (newValue: string) => {
    const newIndex = Number(newValue) - 1
    if (newIndex + 1 > getValues().chapters.length) return
    updateChapterOrder(chapterId, newIndex)
  }

  return (
    <div>
      {loading && <p>Loading...</p>}

      <h2 style={{ marginBottom: 16 }}>Chapters</h2>

      <FormProvider {...chaptersForm}>
        {chapters.map((chapter, chapterIdx) => (
          <div key={chapter.chapter.id} style={{ marginBottom: 16 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <EnterTextField
                style={{ flexShrink: 1, width: 70 }}
                value={
                  reorderItem?.chapterId === chapter.chapter.id
                    ? String(reorderItem.newIndex + 1)
                    : String(chapterIdx + 1)
                }
                autoComplete="off"
                name={randomText + chapterIdx}
                size="small"
                variant="outlined"
                regex={/^([1-9][0-9]*)?$/}
                disabled={!!reorderItem}
                onEnter={handleReorderEnter(chapter.chapter.id)}
              />

              <div
                className={styles.chapterHeading}
                style={{ flexGrow: 1 }}
                onClick={() => {
                  toggleChapter(chapter.chapter.id)
                }}
              >
                {openedChapters.includes(chapter.chapter.id) ? (
                  <ArrowDownIcon />
                ) : (
                  <ArrowRightIcon />
                )}
                <span>
                  <FieldValue name={`chapters.${chapterIdx}.chapter.title`}>
                    {(title: string) => title}
                  </FieldValue>
                </span>
              </div>
            </div>

            {openedChapters.includes(chapter.chapter.id) && (
              <Paper elevation={2} className={styles.chapterBody}>
                <form
                  className={styles.chapterForm}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <BgiTextField
                    label="Title"
                    name={`chapters.${chapterIdx}.chapter.title`}
                    size="small"
                    fullWidth
                  />

                  {/* // TODO: explain */}
                  <FieldValue name={`chapters.${chapterIdx}.actionMetadata`}>
                    {(actionMetadata: ChapterFormData['actionMetadata']) => (
                      <>
                        {(actionMetadata.isUpdating ||
                          actionMetadata.isDeleting) && (
                          <div className={styles.chapterCover} />
                        )}

                        <div className={styles.chapterActions}>
                          <Button
                            variant="contained"
                            disabled={actionMetadata.isUpdating}
                            onClick={() => handleUpdate(chapterIdx)}
                          >
                            Update
                          </Button>

                          <Button
                            variant="outlined"
                            color="error"
                            disabled={actionMetadata.isDeleting}
                            onClick={() => handleDelete(chapterIdx)}
                          >
                            Remove
                          </Button>
                        </div>

                        {/* // TODO: replace alert with snackbar */}

                        {actionMetadata.updateError && (
                          <Alert
                            style={{ marginTop: '16px' }}
                            severity="success"
                          >
                            {actionMetadata.updateError}
                          </Alert>
                        )}

                        {actionMetadata.deleteError && (
                          <Alert style={{ marginTop: '16px' }} severity="error">
                            {actionMetadata.deleteError}
                          </Alert>
                        )}
                      </>
                    )}
                  </FieldValue>
                </form>

                <ChapterUnitsForm chapterId={chapter.chapter.id} />
              </Paper>
            )}
          </div>
        ))}
      </FormProvider>

      <form style={{ marginTop: 24 }} onSubmit={handleCreate}>
        <FormProvider {...createChapterForm}>
          <BgiTextField
            label="Chapter Title"
            name={`title`}
            size="small"
            fullWidth
          />
        </FormProvider>

        <Button type="submit" startIcon={<AddIcon />} variant="outlined">
          Add Chapter
        </Button>
      </form>

      <div style={{ padding: 15 }} />
    </div>
  )
}

const styles = {
  chapterBody: css({
    position: 'relative',
    padding: 16,
    marginTop: 8,
    marginBottom: 32,
    border: '1px solid #9e9e9e',
  }),
  chapterHeading: css({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  }),
  chapterCover: css({
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'transparent',
    zIndex: 10,
    top: 0,
    left: 0,
    cursor: 'not-allowed',
  }),
  chapterForm: css({
    marginBottom: 32,
  }),
  chapterActions: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
  }),
  chapterNum: css({
    fontSize: 12,
  }),
  title: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
}
