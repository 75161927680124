import { UseFieldArrayReturn } from 'react-hook-form'
import { AsyncSelectSearch } from '../UI/AsyncSelectSearch'
import FieldValue from './FieldValue'
import { Course } from '../types/course'
import { css } from '@emotion/css'
import DeleteIcon from '@mui/icons-material/Close'
import { FC } from 'react'

interface CoursesAddProps {
  coursesField: UseFieldArrayReturn<any, 'courses', 'localId'>
}

export const CoursesAdd: FC<CoursesAddProps> = ({
  coursesField: untypedCoursesField,
}) => {
  const coursesField = untypedCoursesField as UseFieldArrayReturn<
    { courses: Course[] },
    'courses',
    'localId'
  >
  
  return (
    <div>
      <h6 className={styles.subtitle}>Courses</h6>

      <AsyncSelectSearch
        style={{ flexGrow: 1 }}
        value={null}
        onChange={(newCourse) => {
          if (newCourse) {
            const isExists = coursesField.fields.some(
              (course) => course.id === newCourse.id
            )

            if (!isExists) coursesField.append(newCourse)
          }
        }}
        requestPathname="/courses"
        mainInputParam="title"
        noOptionsMessage="No courses match the search"
        processOption={(course: Course) => ({
          label: course.title,
          value: course.id,
        })}
        placeholder="Search Course"
      />

      <ul style={{ paddingLeft: 22, marginTop: 12 }}>
        <FieldValue name="courses">
          {(courses: Course[]) =>
            courses.map((course, courseIdx) => (
              <li key={course.id}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 8 }}>{course.title}</span>

                  <button
                    className={styles.deleteBtn}
                    onClick={() => coursesField.remove(courseIdx)}
                    type="button"
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </li>
            ))
          }
        </FieldValue>
      </ul>
    </div>
  )
}

const styles = {
  subtitle: css({
    fontWeight: 700,
    fontSize: 14,
    margin: '8px 0',
  }),
  deleteBtn: css({
    display: 'inline-flex',
    border: 'none',
    background: 'transparent',
    padding: 0,
    cursor: 'pointer',
    color: '#d32f2f',
  }),
}
