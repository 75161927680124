import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { themeOptions } from "./themeOptions";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';



const theme = createTheme(themeOptions)

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <CacheProvider value={cacheRtl}> */}
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
            <App />
        </SnackbarProvider>
      </ThemeProvider>
      {/* </CacheProvider> */}
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
