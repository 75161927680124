import React, { FC } from 'react'
import { smld } from '../../data/SocialMediaLinksData'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// interface socialDataProp {
//   title: string
//   description: string
// }

export const SocialMediaLinksPage: FC = () => {
  const socialData = smld.map((data, index) => {
    return (
      <Accordion key={index}>
        <AccordionSummary expandIcon={<ArrowDownwardIcon />} aria-controls="panel1-content" id="panel1-header">
          <Typography>{data.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {data.description.split('\n').map((line, index) => {
              if (line.includes('http')) {
                return (
                  <React.Fragment key={index}>
                    <a href={line} target="_blank" rel="noopener noreferrer">{line}</a>
                    <br />
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              );
            })}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  });

  return <div>{socialData}</div>;
};




