import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CreateContactT } from '../../types/contact'
import { Container } from '@mui/material';
import ContactUsForm from './ContactUsForm';
import { useTranslation } from 'react-i18next';



export const CreateContact = () => {
  
  const { t } = useTranslation(["ContactUs", "common"])


  const formContext = useForm<CreateContactT>({
    defaultValues: {
          message: t('Messages_cannot_be_sent')
    },

  })
  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <ContactUsForm />
      </FormProvider>
    </Container>
  );
};

