import { Alert, Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { AnnouncementT } from '../../../types/announcement'
import AnnouncementForm from './AnnouncementForm'
import { useGetAnnouncementQuery } from '../../../store/announcementsApi'
import LoadingPage from '../../LoadingPage'
import { useEffect } from 'react'

export const UpdateAnnouncement = () => {
  const { announcementId } = useParams<{ announcementId: string }>()

  const formContext = useForm<AnnouncementT>()
  const { data: announcement } = useGetAnnouncementQuery(announcementId!)


  useEffect(() => {
    if (announcement) {
      Object.keys(announcement).forEach((untypedAnnouncementKey) => {
        const announcementKey = untypedAnnouncementKey as keyof AnnouncementT
        formContext.setValue(announcementKey, announcement[announcementKey])
      })
    }
  }, [announcement])

  if (!announcement) return <LoadingPage />

  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <AnnouncementForm />
      </FormProvider>
    </Container>
  )
}
