import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from './auth'
import slidesOld from './slides_old'
import { unitsApi } from './unitsApi'
import { coursesApi } from './coursesApi'
import { slidesApi } from './slidesApi'
import { chaptersApi } from './chaptersApi'
import { slidesReducer } from './slides'
import { examsApi } from './examsApi'
import { feedbacksApi } from './feedbacksApi'
import { adsApi } from './adsApi'
import { usersApi } from './usersApi'
import { pendingUsersApi } from './pendingUsersApi'
import { collectionsApi } from './collectionsApi'
import { subcollectionsApi } from './subcollectionsApi'
import { remsApi } from './remedialTeaching'
import { contactApi } from './contactApi'
import { challengersApi } from './challengersApi'
import { authApi } from './authApi'
import { clicksApi } from './clicksApi'
import { specialExamResultApi } from './specialExamResultApi'
import  languageSlice  from './languageSlice'
import { announcementsApi } from './announcementsApi'

const store = configureStore({
  reducer: {
    slidesOld: slidesOld.reducer,
    auth: authReducer,
    slides: slidesReducer,
    language: languageSlice,
    [authApi.reducerPath]: authApi.reducer,
    [slidesApi.reducerPath]: slidesApi.reducer,
    [unitsApi.reducerPath]: unitsApi.reducer,
    [coursesApi.reducerPath]: coursesApi.reducer,
    [chaptersApi.reducerPath]: chaptersApi.reducer,
    [examsApi.reducerPath]: examsApi.reducer,
    [feedbacksApi.reducerPath]: feedbacksApi.reducer,
    [adsApi.reducerPath]: adsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [pendingUsersApi.reducerPath]: pendingUsersApi.reducer,
    [collectionsApi.reducerPath]: collectionsApi.reducer,
    [subcollectionsApi.reducerPath]: subcollectionsApi.reducer,
    [remsApi.reducerPath]: remsApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [challengersApi.reducerPath]: challengersApi.reducer,
    [clicksApi.reducerPath]: clicksApi.reducer,
    [specialExamResultApi.reducerPath]: specialExamResultApi.reducer,
    [announcementsApi.reducerPath]: announcementsApi.reducer,
  },



  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(slidesApi.middleware)
      .concat(unitsApi.middleware)
      .concat(coursesApi.middleware)
      .concat(chaptersApi.middleware)
      .concat(examsApi.middleware)
      .concat(feedbacksApi.middleware)
      .concat(adsApi.middleware)
      .concat(pendingUsersApi.middleware)
      .concat(usersApi.middleware)
      .concat(collectionsApi.middleware)
      .concat(subcollectionsApi.middleware)
      .concat(remsApi.middleware)
      .concat(contactApi.middleware)
      .concat(challengersApi.middleware)
      .concat(clicksApi.middleware)
      .concat(specialExamResultApi.middleware)
      .concat(announcementsApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store