import { Container } from '@mui/system'
import { Link } from 'react-router-dom'
import { css } from '@emotion/css'
import { Button, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useGetUsersQuery } from '../../store/usersApi'
import { BgiLink } from '../../UI/BgiLink'
import PageHeader from '../../UI/PageHeader'
import LoadingPage from '../LoadingPage'
import Layout from './Layout'
import { useActivateUserMutation, useGetPendingUsersQuery } from '../../store/pendingUsersApi'
import PendingUserRow from '../pending-user-row/PendingUserRow'

const PendingUsersPage = () => {
  const { isLoading, error, isFetching, data: pendingUsers } = useGetPendingUsersQuery()

  if (isLoading) return <LoadingPage />

  if (error || !pendingUsers) return <p>Unknown Error occurred</p>;


  return (
    <Layout>
      <Container>

        <div style={{ width: '100%', height: 30 }} />

        <PageHeader
          style={{ marginBottom: 24 }}
          title="Users"
          actions={
            <Button
              component={Link}
              to="/data-entry/users/create"
              type="button"
              variant="contained"
            >
              Create User
            </Button>
          }
        />

        {isFetching && (

          <LinearProgress style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: 4,
            width: '100%',
            zIndex: 5
          }} />

        )}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>name</TableCell>
                <TableCell>lastName</TableCell>
                <TableCell>classLevel</TableCell>
                <TableCell>email</TableCell>
                <TableCell>school</TableCell>
                <TableCell>city</TableCell>
                <TableCell>mobile</TableCell>
                <TableCell>whatsapp</TableCell>
                <TableCell>country</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Activate</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingUsers.map((pendingUser, index) => (
                <PendingUserRow key={pendingUser.id} pendingUser={pendingUser} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ width: '100%', height: 30 }} />
      </Container>
    </Layout>
  )
}

export default PendingUsersPage
