import { FC, Fragment, ReactNode, useEffect, useState } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { BgiTextField } from './BgiTextField'
import { Button, IconButton, Stack } from '@mui/material'
import { BgiCheckbox } from './BgiCheckbox'
import { css } from '@emotion/css'
import FieldValue from '../components/FieldValue'
import TextWithMath from '../components/TextWithMath'
import { AssetField } from '../components/gallery/AssetField'
import FormAlignFormatButton from './FormAlignFormatButton'

interface BgiInputsListProps {
  name: string
  label: string
  checkbox?: boolean
  supportMath?: boolean
  // supportFormatting?: boolean
  minLength?: number
  addBtnContent?: ReactNode
  isQuestionCards?: boolean;
  questionType?: 'question-checkboxes' | 'question-cards'
  | 'question-multi-inputs';
}

const BgiInputsList: FC<BgiInputsListProps> = ({
  name,
  label,
  checkbox,
  supportMath,
  // supportFormatting,
  minLength = 0,
  addBtnContent = 'Add',
  questionType = 'question-checkboxes',
}) => {
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({ name, control })


  console.log('Rendering BgiInputsList:', fields); // تأكد من أن المكون يتم تحميله

  // ---checkedBackgroundInput-------------------------------------------------


  // ----------------------------------------------checkedBackgroundInput------



  const addEntry = () => {
    if (questionType === 'question-cards'
    ) {
      append({
        type: 'cardAnswer',
        isCorrect: false,
        text: '',
      })
    } else if (questionType === 'question-multi-inputs') {
      append({
        type: 'inputAnswer',
        text: '',
        lang: 'en'

      })
    } else {
      append({
        type: 'checkboxAnswer',
        isCorrect: false,
        text: '',
      })
    }
  }

  const addLabelEntry = () => {
    append({
      type: 'label',
      text: '',
    })
  }


  return (
    <div className={mc.allanswer}>
      <h4 className={mc.heading}>{label}</h4>

      <Stack spacing={2}>
        {fields.map((field: any, idx) => (
          <Fragment key={field.id}>
            <div>
              <div className={mc.answer}>
                {(checkbox && questionType !== 'question-multi-inputs') &&
                  <BgiCheckbox
                    name={`${name}.${idx}.isCorrect`}
                  />
                }


                <BgiTextField
                  style={{ flexGrow: 1 }}
                  label={(field.type === 'label' ? 'Label' : questionType === 'question-multi-inputs' ? 'Input Label' : 'Answer') + ' ' + (idx + 1)}
                  type="text"
                  name={`${name}.${idx}.text`}
                  required={questionType !== 'question-multi-inputs'}
                  backgroundColor={checkbox ? (field.isCorrect ? '#ffebcdeb' : 'transparent') : undefined}

                // i stopped it because maybe it will be needed. i can put the text in left or right or center
                // InputProps={{
                //   endAdornment: supportFormatting ? <FormAlignFormatButton name={`${name}.${idx}.formatting.align`} /> : null,
                // }}
                />

                {(questionType === 'question-cards'
                  || field.type === 'label') && (
                    <AssetField
                      fileKind="image"
                      name={`${name}.${idx}.imageFile`}
                      assetWrapperStyle={{ width: 100, height: 100 }}
                    />
                  )}

                {(questionType === 'question-cards'
                  || field.type === 'label') && (
                    <AssetField
                      fileKind="audio"
                      name={`${name}.${idx}.audioFile`}
                      assetWrapperStyle={{ width: 100, height: 100 }}
                    />
                  )}

                {(questionType === 'question-cards'
                  || questionType === 'question-multi-inputs') && field.type !== 'label' && (
                    <BgiTextField
                      label="Correct Answer"
                      type="text"
                      style={{ width: 150, flexShrink: 0 }}
                      name={`${name}.${idx}.correctAnswer`}
                    />
                  )}

                <IconButton
                  color="error"
                  onClick={() => remove(idx)}
                  className={mc.remove}
                  tabIndex={-1}
                  disabled={minLength > fields.length - 1}
                >
                  <DeleteIcon />
                </IconButton>
              </div>

              {supportMath && (
                <FieldValue name={`${name}.${idx}.text`}>
                  {(value) => <TextWithMath value={value} />}
                </FieldValue>
              )}
            </div>
          </Fragment>
        ))}
      </Stack>

      <Button onClick={addEntry}>{addBtnContent}</Button>

      {questionType === 'question-multi-inputs' && (
        <Button onClick={addLabelEntry}>Add Label</Button>
      )}
    </div>
  )
}

export default BgiInputsList

const mc = {
  allanswer: css({
    width: '100%',
    // maxWidth: 500,
  }),
  answer: css({
    display: 'flex',
    width: '100%',
    gap: 4,
    alignItems: 'start',
  }),
  remove: css({
    background: 'none',
    border: 'none',
  }),
  'remove:nth-child(1)': css({
    color: 'rgb(86, 0, 247)',
    width: '2em',
    height: '2em',
  }),
  'answer button': css({
    width: '10px',
  }),
  heading: css({
    marginBottom: 8,
    fontWeight: 400,
    fontSize: 16,
  }),
}
