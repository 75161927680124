import { FormProvider, useForm } from 'react-hook-form'
import { Alert, Button, Container } from '@mui/material'
import { BgiTextField } from '../../UI/BgiTextField'
import { buildGetErrMsg } from '../../utils/buildGetErrMsg'
import { useSnackbar } from 'notistack'
import { useResetPasswordMutation } from '../../store/authApi'
import { BgiLink } from '../../UI/BgiLink'
import { ResetPasswordT } from '../../types/auth'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { logout } from '../../store/auth'
import { useTranslation } from 'react-i18next'

const getErrMsg = buildGetErrMsg({
  TOKEN_EXPIRED: (
    <span>
      This reset link has been used or expired. you can{' '}
      <BgiLink to="/begin-reset-password" color="inherit">create a new one here</BgiLink>
    </span>
  ),
})
// --------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------

const ResetPassword = () => {
  const formContext = useForm<ResetPasswordT & { newPasswordRepeat: string }>()
  const { handleSubmit } = formContext
  const [resetPassword] = useResetPasswordMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const params = useParams()
  const resetToken = params.resetToken as string

  const { t } = useTranslation('Signup_login')

  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = handleSubmit(async ({ newPassword, newPasswordRepeat }) => {
    if (newPassword !== newPasswordRepeat) {
      enqueueSnackbar({ message: t('The_passwords_do_not_match'), variant: 'error' })
      return
    }

    setIsSubmitting(true)

    const result = await resetPassword({ resetToken, newPassword })

    if ('data' in result) {
      enqueueSnackbar({ message: t('The_password_have_been_reset_successfully'), variant: 'success' })
      logout()
      navigate('/login')
    } else {
      setIsSubmitting(false)
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  })

  return (
    <Container maxWidth="sm">
      <FormProvider {...formContext}>
        <form onSubmit={onSubmit}>
          <h4>{t('Enter_the_email_you_registered_with_on_the_site')}</h4>
          <BgiTextField name="newPassword" label={t('New_password')} required type="password" />
          <BgiTextField name="newPasswordRepeat" label={t('Repeat_new_password')} required type="password" />
          <Button type="submit" disabled={isSubmitting}>
            {t('Submit')}
          </Button>
        </form>
      </FormProvider>
    </Container>
  )
}

export default ResetPassword
