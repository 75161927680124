/** @jsxImportSource @emotion/react */
import { Button, Container, Paper, Stack, TextField } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useCopyCourseMutation, useCreateCourseMutation, useDeleteCourseMutation, useUpdateCourseMutation } from '../../../store/coursesApi'
import { BgiTextField } from '../../../UI/BgiTextField'
import { CourseI } from '../../../types/course'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BgiLink } from '../../../UI/BgiLink'
import { CourseChaptersForm } from './CourseChaptersForm'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'
import { useSnackbar } from 'notistack'
import { AssetField } from '../../gallery/AssetField'
import { FileData } from '../../gallery/FileForm'
import { BgiCheckbox } from '../../../UI/BgiCheckbox'
import { BgiSelect } from '../../../UI/BgiSelect'
import { css } from '@emotion/react'
import { useGetClicksCountPerCourseQuery } from '../../../store/clicksApi'
import { DirT } from '../../../types/dir'
import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UseDynamicLanguageStyle from '../../i18n/UseDynamicLanguageStyle'
import Layout from '../../page/Layout'
import { determineTextDirection } from '../../../utils/determineTextDirection'
import { SubcollectionsSearch } from '../subcollection/subcollectionsSearch'
import { Subcollection } from '../../../types/subcollection'


const LANGUAGES = [
  { label: 'Arabic', value: 'ar' },
  { label: 'English', value: 'en' },
  { label: 'German', value: 'de' },
]

const SUCCESS_MSGS = {
  create: (courseId: string, subcollectionId: string) => (
    <p>
      Course has been created successfully with{' '}
      <BgiLink color="#fff" to={`/data-entry/courses/${courseId}/update?subcollectionId=${subcollectionId}`}>
        #{courseId}
      </BgiLink>
    </p>
  ),
  update: 'تم تحديث الكورس بنجاح',
}

type CourseFormData = Omit<CourseI, 'imageFileId' | 'subcollectionId'> & {
  id?: string
  imageFile?: FileData
  subcollection: Subcollection
}

const getErrMsg = buildGetErrMsg({
  DUP_COURSE_CODE: 'كود الدورة مكرر. ادخل كود آخر',
  COURSE_IS_REFERENCED: "Please delete course's chapters first",
})


// --Comp---------------------------------------------------------------------------
// ---------------------------------------------------------------------------------
export const CourseForm = memo(() => {
  const { control, handleSubmit, reset } = useFormContext<CourseFormData>()
  const { t } = useTranslation(['Courses', 'common']);


  const selectLanguage = useWatch({ control, name: 'language' })
  const dir = selectLanguage === 'ar' ? 'rtl' : 'ltr'

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const subcollectionId = searchParams.get('subcollectionId')

  const courseId = useWatch({ control, name: 'id' })
  const isCreateForm = !courseId
  const { enqueueSnackbar } = useSnackbar()

  const [createCourse, { isLoading: isCreating }] = useCreateCourseMutation()
  const [updateCourse, { isLoading: isUpdating }] = useUpdateCourseMutation()
  const [deleteCourse, { isLoading: isDeleting }] = useDeleteCourseMutation()
  const [copyCourse, { isLoading: isCopying }] = useCopyCourseMutation()


  // ---copyCourse-01-------------------------------------------------------------
  const [copyPrefixes, setCopyPrefixes] = useState({
    courseCode: '',
    unitNum: '',
    slideNum: ''
  })


  const updateCopyPrefixes = (key: keyof typeof copyPrefixes, value: string) => {
    setCopyPrefixes(prev => ({ ...prev, [key]: value }))
  }

  const isAnyFieldCopyCourseEmpty = !copyPrefixes.courseCode || !copyPrefixes.unitNum || !copyPrefixes.slideNum;

  // -------------------------------------------------------------copyCourse-01---

  const { isFetching, data: courseLoadCount, error } = useGetClicksCountPerCourseQuery(courseId!);


  if (typeof subcollectionId !== 'string') {
    return <p>Page Not Found 404</p>
  }

  // -2--------------------------------------------------------------------------------
  const onSubmit = async (course: CourseFormData) => {
    const { id, imageFile, subcollection, ...restPayload } = course

    const payload = {
      ...restPayload,
      imageFileId: imageFile?.id ?? null,
      subcollectionId: subcollection.id,
      publishedVersion: Number(restPayload.publishedVersion)
    }

    if (!id) {
      const result = await createCourse({ ...payload, subcollectionId })

      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['create'](result.data.id, subcollectionId),
          variant: 'success',
        })
        reset()
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    } else {
      const result = await updateCourse({ id, ...payload })

      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['update'],
          variant: 'success',
        })
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    }
  }
  // -3-------------------------------------------------------------------------------2-

  const onDelete = async () => {
    if (!courseId) return

    const confirmationMsg = 'Are you sure you want to delete this course'

    if (!window.confirm(confirmationMsg)) return

    const result = await deleteCourse(courseId)

    if ('data' in result) {
      enqueueSnackbar({
        message: 'Course has been deleted successfully',
        variant: 'success',
      })

      navigate(`/subcollections/${subcollectionId}/courses`)
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  }
  // --------------------------------------------------------------------------------3-


  const onCopy = async () => {
    if (!courseId) return

    const result = await copyCourse({
      id: courseId,
      prefixes: copyPrefixes
    })

    if ('data' in result) {
      enqueueSnackbar({
        message: 'Course has been copied successfully',
        variant: 'success',
      })

      navigate(`/subcollections/${subcollectionId}/courses`)
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  }

  // const courseLoadCount = courseLoadCounts?.find(c => c.courseId === courseId)?.clickCount;

  // const { dynamicLanguageStyle } = UseDynamicLanguageStyle()

  // ---return--------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------
  return (
    <Layout>
      <BgiLink to={`/subcollections/${subcollectionId}/courses`}>Back to Courses page</BgiLink>

      <div style={{ width: '100%', height: 30 }} />

      <Paper
        style={{
          padding: '1rem',
          marginBottom: '2rem',
        }}
      >
        <h2 style={{ marginBottom: 16 }}>{isCreateForm ? t('common:Create') : t('common:Update')} {t('Course')}</h2>




        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <BgiCheckbox name="isPrivate" label={t('Courses:Private')} />

            <BgiTextField label={t('Courses:Code')} name="code" fullWidth size="small" required maxLength={64} />

            
            <SubcollectionsSearch name="subcollection" required />

            <BgiSelect
              name="language"
              options={LANGUAGES}
              label={t('Courses:Language')}
              fullWidth
              size="small"
              required
              noOptionText="Chose a language"
            />

            <BgiTextField label={t('Courses:Title')} name="title" fullWidth size="small" required maxLength={255} />

            <div css={styles.imgAndCheckbox}>
              <div>
                <AssetField name="imageFile" fileKind="image" />
              </div>
              <div >

                <h3>{t('Number_of_course_loads')}: {courseLoadCount !== undefined ? courseLoadCount : (isFetching ? t('Loading...') : t('Data_not_available'))}</h3>

                <BgiCheckbox name="isPointsVisible" label={t('Show_points')} />
                <br />
                <BgiCheckbox name="isButtonCommentsVisible" label={t('Show_comment_button')} />
                <br />
                <BgiCheckbox name="isDownloadVisible" label={t('Show_download_button')} />
              </div>
            </div>

            <BgiTextField
              label={t('Description')}
              name="description"
              fullWidth
              size="small"
              multiline
              minRows={2}
              maxRows={4}
              dir={dir}
            />

            <BgiTextField
              label={t('References')}
              name="references"
              fullWidth
              size="small"
              multiline
              maxLength={1024}
              minRows={2}
              maxRows={4}
              dir={dir}
            />

            <BgiTextField label={t('Published_version')} name="publishedVersion" fullWidth size="small" required />
            
            <Paper elevation={5} style={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <h3>Copy Course</h3>
              <TextField placeholder='courseCode' onChange={(e) => updateCopyPrefixes('courseCode', e.target.value)} size='small' />
              <TextField placeholder='slideNum' onChange={(e) => updateCopyPrefixes('slideNum', e.target.value)} size='small' />
              <TextField placeholder='unitNum' onChange={(e) => updateCopyPrefixes('unitNum', e.target.value)} size='small' />

            </Paper>

            <Stack direction="row" justifyContent="space-between">
              <Button variant="contained" type="submit" disabled={isCreating || isUpdating}>
                {isCreateForm ? 'Create' : 'Update'}
              </Button>

              {!isCreateForm && (
                <>
                  <Button variant="outlined" onClick={onCopy} disabled={isCopying || isAnyFieldCopyCourseEmpty}>
                    Copy
                  </Button>


                  <Button color="error" variant="outlined" onClick={onDelete} disabled={isDeleting}>
                    Delete
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
        </form>
      </Paper>

      {!isCreateForm && <CourseChaptersForm courseId={courseId} />}

      <div style={{ width: '100%', height: 30 }} />
    </Layout>
  )
})


const styles = {
  imgAndCheckbox: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'

  })
}