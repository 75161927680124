import React, { memo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../../store/languageSlice';
import i18n from './i18n';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Button, Stack } from '@mui/material';
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';

const SwitchLanguage = memo(() => {
  const initialLanguageName = {
    'ar': 'العربية',
    'en': 'English',
    'de': 'Deutsch'
  }[i18n.language] || 'English';

  const [anchorEl, setAnchorEl] = useState(null);
  const [languageName, setLanguageName] = useState(initialLanguageName);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleLanguageChange = (lang: 'ar' | 'en' | 'de') => {
    const languageNames = { ar: 'العربية', en: 'English', de: 'Deutsch' };

    setLanguageName(languageNames[lang]);

    setAnchorEl(null); // Close menu after selection
    i18n.changeLanguage(lang).then(() => {
      dispatch(setLanguage(lang));
    }).catch((error: string) => {
      console.error("Failed to change language:", error);
    });
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" style={{ width: '7rem', height: '3rem', background: '#0288D1', placeItems: "center" }}>
      <Toolbar style={{ height: '0.2rem' }}>
        <Stack direction="row" spacing={0.2}>
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="language"
            aria-controls="language-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <span style={{ fontSize: '1.2rem', margin: '0.5rem' }}>{languageName}</span><LanguageSharpIcon  />
          </IconButton>
          <Menu
            id="language-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleLanguageChange('ar')}>Arabic</MenuItem>
            <MenuItem onClick={() => handleLanguageChange('en')}>English</MenuItem>
            <MenuItem onClick={() => handleLanguageChange('de')}>German</MenuItem>
          </Menu>
        </Stack>
      </Toolbar>
    </AppBar>
  );
});

export default SwitchLanguage;
