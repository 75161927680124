import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AddPrivateCourseUser, CopyCourseI, Course, CourseI, RemovePrivateCourseUser, UpdateCourseIndexInput } from '../types/course'
import { apiConfig } from './api'
import { User } from '../types/user'

export const coursesApi = createApi({
  reducerPath: 'coursesApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/courses`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getCourses: builder.query<Course[], string>({
      query: (subcollectionId) => `?subcollectionId=${subcollectionId}`,
    }),
    getCourse: builder.query<Course | null, string>({
      query: (courseId) => `/${courseId}`,
    }),
    createCourse: builder.mutation<Course, CourseI>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),
    updateCourse: builder.mutation<Course, Partial<CourseI> & Pick<Course, 'id'>>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
    }),
    copyCourse: builder.mutation<{ id: string }, CopyCourseI>({
      query: ({ id, ...body }) => ({ url: `/${id}/copy`, method: 'POST', body }),
    }),
    deleteCourse: builder.mutation<void, string>({
      query: (courseId) => ({ url: `/${courseId}`, method: 'DELETE' }),
    }),



    getPrivateCourseUsers: builder.query<User[], string>({
      query: (courseId) => `/private/${courseId}/users`,
    }),
    addPrivateCourseUser: builder.mutation<User, AddPrivateCourseUser>({
      query: ({ courseId, ...body }) => ({ url: `/private/${courseId}/users`, method: 'POST', body }),
      async onQueryStarted({ userId, courseId }, { dispatch, queryFulfilled }) {
        try {
          const { data: addedUser } = await queryFulfilled

          dispatch(
            coursesApi.util.updateQueryData('getPrivateCourseUsers', courseId, (draft) => {
              draft.unshift(addedUser)
            })
          )
        } catch { }
      },
    }),
    removePrivateCourseUser: builder.mutation<void, RemovePrivateCourseUser>({
      query: ({ courseId, userId }) => ({ url: `/private/${courseId}/users/${userId}`, method: 'DELETE' }),
      async onQueryStarted({ userId, courseId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            coursesApi.util.updateQueryData('getPrivateCourseUsers', courseId, (draft) => {
              draft.forEach((user, idx) => {
                if (user.id === userId) draft.splice(idx, 1)
              })
            })
          )
        } catch { }
      },
    }),

// ------------------------------------------------------------------------------------
    updateCourseIndex: builder.mutation<string[], UpdateCourseIndexInput>({
      query: ({ id, ...body }) => ({
        url: `/${id}/updateIndex`,
        method: 'PATCH',
        body,
      })
    }),

  }),
})

export const {
  useGetCoursesQuery,
  useLazyGetCoursesQuery,
  useGetCourseQuery,
  useCreateCourseMutation,
  useUpdateCourseMutation,
  useCopyCourseMutation,
  useDeleteCourseMutation,
  useGetPrivateCourseUsersQuery,
  useAddPrivateCourseUserMutation,
  useRemovePrivateCourseUserMutation,
  useUpdateCourseIndexMutation,
} = coursesApi
