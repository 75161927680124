import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiConfig } from './api'
import { BeginResetPasswordT, ResetPasswordT } from '../types/auth'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/auth`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    beginResetPassword: builder.mutation<void, BeginResetPasswordT>({
      query: (body) => ({ url: `/begin-reset-password`, method: 'POST', body }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordT>({
      query: (body) => ({ url: `/reset-password`, method: 'POST', body }),
    }),
  }),
})

export const {
  useBeginResetPasswordMutation,
  useResetPasswordMutation
} = authApi
