import {
  FormControl,
  FormHelperText,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material'
import React, { FC } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useFieldRules } from '../hooks/useFieldRules'
import parseFieldErrorText from '../utils/parseFieldErrorText'

export interface BgiToggleButtonGroupProps {
  name: string
  options: {
    label: string
    value: string
    disabled?: boolean
  }[]
  defaultValue?: string | null
  label?: string
  required?: boolean
}

export const BgiToggleButtonGroup: FC<BgiToggleButtonGroupProps> = ({
  name,
  options,
  label,
  defaultValue,
  required,
}) => {
  const { control, setValue } = useFormContext()

  const value = useWatch({ control, name })

  const rules = useFieldRules({ required })

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { ref, value, onChange }, fieldState: { error } }) => {
        const errorText = parseFieldErrorText(error)

        return (
          <FormControl error={!!errorText} fullWidth>
            <FormLabel>{label + (required ? ' *' : '')}</FormLabel>
            <ToggleButtonGroup
              color="primary"
              value={value}
              exclusive
              onChange={(e, newValue) => onChange(newValue)}
              fullWidth
            >
              {options.map((option, idx) => (
                <ToggleButton
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                  ref={idx === 0 ? ref : undefined}
                >
                  {option.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            {errorText && <FormHelperText>{errorText}</FormHelperText>}
          </FormControl>
        )
      }}
    />
  )
}
